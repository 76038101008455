import { LocatorPersonType } from '../../services/LocatorService';

export type MaskInput = string | null | undefined;

export function cpfMask(value: MaskInput) {
  if (!value) return '';
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export function phoneMask(value: MaskInput) {
  if (!value) return '';
  if (value.length >= 11) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1)$2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d/, '$1');
  }
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1)$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d/, '$1');
}

export function cepMask(value: MaskInput) {
  if (!value) return '';
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d/, '$1');
}

export function cnpjMask(value: MaskInput) {
  if (!value) return '';
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d/, '$1');
}

export function personDocumentMask(
  document: string,
  personType: LocatorPersonType
) {
  return personType === LocatorPersonType.Physical
    ? cpfMask(document)
    : cnpjMask(document);
}

export function stateRegistrationMask(value: MaskInput) {
  if (!value) return '';
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})\d/, '$1');
}
export function cleanNumberMask(value: MaskInput) {
  if (!value) return '';
  return value.replace(/\D/g, '');
}

/**
 * Used to initialize a value for money mask. This is not meant to be used as an input mask.
 */
export function convertToMonetaryValue(value: number) {
  return value ? moneyMask(value.toFixed(2)) : 'R$ 0,00';
}

/**
 * Applies a money mask to a string value. This can be used for incomplete values (from inputs, for example).
 * @param value
 * @returns
 */
export function moneyMask(value: MaskInput) {
  if (!value) return '';
  return (
    'R$ ' +
    value
      .replace(/\D/g, '')
      .replace(/(\d{2})(?=(\d))*$/, ',$1')
      .replace(/(\d)(?=(\d{3})+(,(\d){0,2})*$)/g, '$1.')
  );
}

export function clearMoneyMask(value: string) {
  if (!value) return '';
  const cleanValue = value.replace(/\D/g, '');
  const formatedValue = stringToNumber(cleanValue);
  return formatedValue ?? '';
}

export function replaceCommaByDot(number: string): string {
  return number.replace(',', '.'); // substitui virgula por ponto
}

function stringToNumber(value: string): number | null {
  const numberValue: number = parseFloat(value) / 100;
  return numberValue;
}
