import { FaSms } from 'react-icons/fa';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import {
  PaginationComponent,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { Flex, Stack, useToast } from '@chakra-ui/react';
import AvatarLabelComponent from '../../UI/atoms/AvatarLabelComponent/AvatarLabelComponent';
import React, { useState } from 'react';
import SearchSelectComponent from '../../UI/molecules/SearchSelectComponent/SearchSelectComponent';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import TagComponent from '../../UI/atoms/TagComponent/TagComponent';
import {
  AttendanceResp,
  AttendanceStatus,
  getAttendanceReport,
  useAttendace,
} from '../../../services/Attendance';
import { parseDateAndHour } from '../../../utils/functions/utility';
import { useNavigate } from 'react-router-dom';
import { TabsFilterComponent } from '../../UI/organisms/TabsFilterComponent/TabsFilterComponent';
import { SortDirection } from '../../../services/PhysicalPersonService';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import ModalStructureComponent from '../../UI/molecules/ModalStructureComponent/ModalStructureComponent';
import InputComponent from '../../UI/atoms/InputComponent/InputComponent';
import {
  Attendance,
  postAttendanceSubject,
  useAttendanceSubject,
} from '../../../services/AttendanceService';
import { usePerson } from '../../../services/PersonService';
import { SelectAutoCompleteComponent } from '../../UI/atoms/InputSelectComponent/InputSelectComponent';
import DropzoneModel from '../../UI/organisms/uploadModel/DropZoneModel';
import TextComponent from '../../UI/atoms/TextComponent/TextComponent';
import { showToast } from '../../../utils/showToast';
import CreatePersonModalComponent from '../../UI/organisms/CreateLocatorModalComponent/CreateLocatorModalComponent';
import { useDateQueryState, useDebouncedQuerySearch } from '../../../utils/functions/useDebouncedQuerySearch';
const defaultForm = {
  title: '',
  description: '',
  subjectId: '',
  requesterId: '',
  attachmentIds: [],
};
export default function AttendanceTemplateComponent() {
 
  const toast = useToast();
  const {
    search,
    searchField,
    searchInput,
    setSearch,
    setSearchField,
    pagination: { pageSize, selectedPage, setSelectedPage },
  } = useDebouncedQuerySearch('subject');
  const [createdAt] = useDateQueryState(
    'createdAt'
  );
  const [lastUpdateAt] = useDateQueryState(
    'lastUpdateAt'
  );
  const [closedAt] = useDateQueryState(
    'closedAt'
  );
  const [status, setStatus] = React.useState<AttendanceStatus | null>(null);
  const [myAttendances, setMyAttendances] = React.useState(false);
  const [newAttendeceShowModal, setNewAttendeceShowModal] = useState(false);
  const [sortDirection, setSortDirection] =
    React.useState<SortDirection>('desc');
  const [sort, setSort] = React.useState<string>('createdAt');

  const listAttendance = useAttendace(
    selectedPage,
    pageSize,
    search,
    searchField,
    sort,
    sortDirection,
    status,
    myAttendances,
    createdAt,
    lastUpdateAt,
    closedAt

  );
  const [form, setForm] = React.useState<Attendance>(defaultForm);
  const [subjecOptions, setSubjecOptions] = useState('');
  const [requesterOption, setRequesterOption] = useState('');
  const [requesterShowModal, setRequesterShowModal] = useState(false);

  const [file, setFile] = React.useState('');
  const attendanceSubjectList = useAttendanceSubject();
  const [saveIsLoading, setSavaIsLoading] = useState(false);
  const attendanceRequesterList = usePerson(1, 1000, '', 'name', 'asc');
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const navigate = useNavigate();

  const tabName = [
    {
      title: 'Todos',
      onClick: () => {
        setStatus(null);
        setMyAttendances(false);
      },
    },
    {
      title: 'Em aberto',
      onClick: () => {
        setStatus(AttendanceStatus.open);
        setMyAttendances(false);
      },
    },
    {
      title: 'Atendendo',
      onClick: () => {
        setStatus(AttendanceStatus.attending);
        setMyAttendances(false);
      },
    },
    {
      title: 'Finalizado',
      onClick: () => {
        setStatus(AttendanceStatus.finished);
        setMyAttendances(false);
      },
    },
    {
      title: 'Cancelado',
      onClick: () => {
        setStatus(AttendanceStatus.canceled);
        setMyAttendances(false);
      },
    },

    {
      title: 'Meus Atendimentos',
      onClick: () => {
        setMyAttendances(true);
        setStatus(null);
      },
    },
  ];
  function saveAttendance() {
    setSavaIsLoading(true);
    postAttendanceSubject({
      attachmentIds: file.length > 0 ? [file] : [],
      description: form.description,
      requesterId: requesterOption.toString(),
      subjectId: subjecOptions.toString(),
      title: form.title,
    })
      .then(() => {
        listAttendance.refetch();
        showToast({
          toast,
          status: 'success',
          title: 'Sucesso',
          description: 'Atendimento cadastrado com sucesso',
        });
        setNewAttendeceShowModal(false);
        console.log('atendimento cadastrado com sucesso');
      })
      .catch(() => {
        showToast({
          toast,
          status: 'error',
          title: 'Erro',
          description: 'Erro ao cadastrar atendimento',
        });
        console.log('erro ao cadastrar atendimento');
      })
      .finally(() => setSavaIsLoading(false));
  }

  return (
    <>
      <Stack paddingBottom={5} justifyContent={'space-between'}>
        <HeaderTextComponent goBack>Atendimento</HeaderTextComponent>
      </Stack>
      <Stack
        direction={'row'}
        paddingBottom={5}
        justifyContent={'space-between'}
        alignItems={'flex-end'}
      >
        <TabsFilterComponent tabList={tabName} />

        <Flex flexDir={'column'}>
          <Flex mb={2} justifyContent={'flex-end'}>
            <ButtonComponent
              margin={'0 10px 0 0'}
              onSubmit={() => {
                setNewAttendeceShowModal(true);
                setForm(defaultForm);
                setSubjecOptions('');
                setRequesterOption('');
              }}
            >
              Novo
            </ButtonComponent>
            <ButtonComponent
              margin={'0 0 0 0'}
              onSubmit={() =>
                getAttendanceReport(
                  search,
                  searchField,
                  sort,
                  sortDirection,
                  status,
                  myAttendances
                )
              }
            >
              Exportar
            </ButtonComponent>
          </Flex>

          <SearchSelectComponent
            onChangeText={(input) => setSearch(input.target.value)}
            inputValue={searchInput}
            selectDefaultValue={searchField}
            onClean={() => setSearch('')}
            onChange={(item) => {
              setSearch('');
              setSearchField(item.target.value);
            }}
            options={[
              { id: 'subject', name: 'Assunto' },
              { id: 'requesterName', name: 'Solicitante' },
              { id: 'responsibleName', name: 'Atendente' },
              { id: 'serialNumber', name: 'Ticket' },
              { id: 'createdAt', name: 'Data de abertura', type: 'date' },
              {
                id: 'lastUpdateAt',
                name: 'Data da última atualização',
                type: 'date',
              },
              { id: 'closedAt', name: 'Data de conclusão', type: 'date' },
            ]}
          />
        </Flex>
      </Stack>
      <TableComponent
        ItemsHeader={[
          {
            item: 'Solicitante',
            sort: 'requestername',
          },
          {
            item: 'Ticket',
            sort: 'serialNumber',
          },
          {
            item: 'Assunto',
            sort: 'subject',
          },
          {
            item: 'Abertura',
            sort: 'createdAt',
          },
          {
            item: 'Atendente',
            sort: 'responsiblename',
          },
          {
            item: 'Status',
            sort: 'status',
          },
          { item: 'Ação' },
        ]}
        isLoading={listAttendance.isLoading}
        centered={true}
        emptyState={listAttendance.data?.metadata.dataSize === 0}
        sorting={{ sortColumn: sort, direction: sortDirection }}
        onSortingChanged={(sort, dir) => {
          setSort(sort);
          setSortDirection(dir);
        }}
        data={
          listAttendance.data?.data.map((e: AttendanceResp, i: number) => ({
            items: [
              <AvatarLabelComponent key={i} label={e.requesterName} />,
              e.serialNumber,
              e.subject,
              parseDateAndHour(e.date),
              e.responsibleName ? e.responsibleName : '-',
              getStatusColorByTypeAttendance(e.attendanceStatus),
              <>
                <IconButtonComponent
                  icon={<FaSms />}
                  ariaLabel="info"
                  marginX={2}
                  toolTipText="Chat"
                  colorScheme={'green'}
                  onSubmit={() => {
                    navigate(`/chat/${e.id}`);
                  }}
                />
              </>,
            ],
          })) || []
        }
      />

      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listAttendance.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <ModalStructureComponent
        title="Cadastrar"
        isOpen={newAttendeceShowModal}
        onClose={() => setNewAttendeceShowModal(false)}
        size={'4xl'}
        children={
          <>
            <Flex justifyContent={'space-between'}>
              <InputComponent
                margin={2}
                label="Título"
                placeholder="Digite o titulo"
                value={form.title}
                onChange={(input) => {
                  setForm({ ...form, title: input.target.value });
                }}
              />
              <SelectAutoCompleteComponent
                single
                m={2}
                defaultValue={''}
                values={subjecOptions}
                options={attendanceSubjectList.data?.map((e) => ({
                  id: e.id,
                  name: e.subject,
                }))}
                onChange={(e) => setSubjecOptions(e)}
                label="Assunto"
                placeholder="Selecione o assunto"
              />
            </Flex>

            <Flex>
              <Flex mr={6} flexDir={'column'}>
                <SelectAutoCompleteComponent
                  single
                  m={2}
                  defaultValue={''}
                  values={requesterOption}
                  options={attendanceRequesterList.data?.data.map((e) => ({
                    id: e.personId,
                    name: e.name,
                  }))}
                  onChange={(e) => {
                    setRequesterOption(e);
                  }}
                  label="Solicitante"
                  placeholder="Selecione o solicitante"
                />

                <Flex m={2} justifyContent={'flex-end'}>
                  <ButtonComponent
                    size={'sm'}
                    variant={'link'}
                    onSubmit={() => {
                      setRequesterShowModal(true);
                    }}
                  >
                    {
                      'não encontrou o solicitante? clique aqui e cadastre o mesmo'
                    }
                  </ButtonComponent>
                </Flex>
              </Flex>

              <InputComponent
                type="textArea"
                margin={2}
                label="Descrição"
                placeholder="Digite uma descrição"
                value={form.description}
                onChange={(input) => {
                  setForm({ ...form, description: input.target.value });
                }}
              />
            </Flex>

            <Flex m={2} flexDir={'column'}>
              <TextComponent style={{ marginBottom: 8 }} fontWeight={'medium'}>
                Arquivo
              </TextComponent>
              <DropzoneModel
                accept={{ 'text/csv': ['.csv'] }}
                onUploadComplete={(input) => {
                  setFile(input[0].id);
                }}
                type="archive"
              />
            </Flex>
          </>
        }
        footer={
          <Flex m={4} justifyContent={'flex-end'}>
            <ButtonComponent
              variant={'outline'}
              margin={'0 10px 0 0'}
              onSubmit={() => {
                setNewAttendeceShowModal(false);
              }}
            >
              Cancelar
            </ButtonComponent>
            <ButtonComponent
              isLoading={saveIsLoading}
              disabled={saveIsLoading}
              margin={'0 10px 0 0'}
              onSubmit={() => {
                saveAttendance();
              }}
            >
              Cadastrar
            </ButtonComponent>
          </Flex>
        }
      />
      <CreatePersonModalComponent
        showModal={requesterShowModal}
        setShowModal={() => setRequesterShowModal(false)}
        onSuccess={async (response) => {
          console.log({ response });
          setRequesterOption(response.personId);
        }}
        reloadData={() => attendanceRequesterList.refetch()}
      />
    </>
  );
}

export function getStatusColorByTypeAttendance(type: AttendanceStatus) {
  switch (type) {
    case AttendanceStatus.open:
      return <TagComponent size={'md'} colorScheme={'blue'} text="Aberto" />;
    case AttendanceStatus.canceled:
      return <TagComponent size={'md'} colorScheme={'red'} text="Cancelado" />;
    case AttendanceStatus.attending:
      return (
        <TagComponent size={'md'} colorScheme={'yellow'} text="Atendendo" />
      );
    default:
      return (
        <TagComponent size={'md'} colorScheme={'green'} text="Finalizado" />
      );
  }
}
