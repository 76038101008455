import { Collapse, Flex, Stack, Text, Tooltip } from '@chakra-ui/react';
import {
  FaAngleDown,
  FaAngleRight,
  FaCropAlt,
  FaEdit,
  FaFileAlt,
} from 'react-icons/fa';
import addNotes from '../../../../assets/images/addNotes.png';
import ImageComponent from '../../atoms/ImageComponent/ImageComponent';
import IconButtonComponent from '../../atoms/ButtonComponent/IconButton';
import { CloseIcon } from '@chakra-ui/icons';
import React from 'react';

type DocumentComponentProps = {
  documentTypeDescription: string;
  documentType?: number;
  DocumentName: string;
  onClick: () => void;
  isContract?: boolean;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  onReplaceClick?: () => void;

  children?: React.ReactNode;
};

export function DocumentComponent(props: DocumentComponentProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <Stack
        width={'100%'}
        borderBottom={'1px'}
        borderColor={'white'}
        direction={'row'}
        alignItems="center"
        justifyContent={'space-between'}
        paddingBottom={1}
        marginTop={3}
      >
        <Stack direction={'row'} alignItems="center" cursor={'pointer'}>
          {props.isContract && (
            <IconButtonComponent
              ariaLabel="Mostrar Locadores"
              variant={'guost'}
              textColor={'white'}
              icon={isOpen ? <FaAngleDown /> : <FaAngleRight />}
              onSubmit={() => {
                setIsOpen(!isOpen);
              }}
            />
          )}
          <Tooltip label={'Baixar'}>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              onClick={props.onClick}
            >
              <FaFileAlt size={23} color="white" />
              <Text color={'white'} fontSize={'14px'}>
                {props.DocumentName}
              </Text>
              <Text ml={4} color={'white'} fontSize={'18px'} fontWeight={'700'}>
                {props.documentTypeDescription}
              </Text>
            </Flex>
          </Tooltip>
        </Stack>
        {props.isContract && (
          <Stack direction={'row'}>
            <IconButtonComponent
              disabled={props.documentType === 5}
              variant={'outline'}
              icon={<FaEdit />}
              ariaLabel="Edit"
              toolTipText={'Editar'}
              backgroundColor={'white'}
              onSubmit={() => {
                props.onEditClick?.();
              }}
            />
            <IconButtonComponent
              marginX={2}
              toolTipText="Substituir documento"
              variant={'outline'}
              icon={<FaCropAlt />}
              ariaLabel="Substituir"
              colorScheme={'cyan'}
              backgroundColor={'white'}
              onSubmit={() => {
                props.onReplaceClick?.();
              }}
            />
            <IconButtonComponent
              toolTipText="Desabilitar documento"
              variant={'outline'}
              icon={<CloseIcon />}
              ariaLabel="Remover"
              colorScheme={'red'}
              backgroundColor={'white'}
              onSubmit={() => {
                props.onDeleteClick?.();
              }}
            />
          </Stack>
        )}
      </Stack>
      {props.isContract && (
        <Collapse in={isOpen} animateOpacity>
          {props.children}
        </Collapse>
      )}
    </>
  );
}

export function EmptyStateDocumentComponent() {
  return (
    <Flex
      marginY={15}
      padding={4}
      backgroundColor={'white'}
      borderRadius={8}
      flexDirection={'column'}
      alignItems={'center'}
    >
      <ImageComponent width="50%" src={addNotes} />
      <Text fontSize={'18px'} fontWeight={'700'} as="a">
        Documentos são exibidos aqui
      </Text>
    </Flex>
  );
}
