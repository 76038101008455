import {
  Box,
  Flex,
  GridItem,
  Spinner,
  Stack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import {
  FaChartLine,
  FaCheck,
  FaEdit,
  FaExchangeAlt,
  FaPeopleArrows,
  FaWindowClose,
} from 'react-icons/fa';
import {
  ContractResp,
  putContractDisableDocument,
  StatusContract,
} from '../../../../services/ContractService';
import {
  addSpreadText,
  getFirstIfAny,
} from '../../../../utils/functions/utility';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import ScrollableFeedComponent from '../../atoms/ScrollableFeedComponent/ScrollableFeedComponent';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import {
  DocumentComponent,
  EmptyStateDocumentComponent,
} from '../../molecules/DocumentComponent/DocumentComponent';
import { TextInformation } from '../../molecules/ResponsibleDetails/ResponsibleDetails';
import DashboardMap from '../DashboardMap/DashboardMap';
import AvatarLabelComponent from '../../atoms/AvatarLabelComponent/AvatarLabelComponent';
import TagComponent from '../../atoms/TagComponent/TagComponent';
import { useUserData } from '../../../../services/LoginService';
import { showToast } from '../../../../utils/showToast';
import { cepMask } from '../../../../utils/functions/masks';
import { notInfo } from '../../../../pages/MyProfilePage';

type ContractDetailsActionsComponentProps = {
  contract?: ContractResp;
  onSubmitAddButton?: () => void;
  onSubmitTerminateButton?: () => void;
  onSubmitRenovateButton?: () => void;
  onSubmitChangeStatus?: () => void;
  onSubmitAdjustmentButton?: () => void;
  onSubmitEditDocumentButton?: (documentId: string) => void;
  onSubmitReplaceDocumentButton: (documentId: string) => void;
  reloadData?: () => void;
};

export function ContractDetailsActionsComponent(
  props: ContractDetailsActionsComponentProps
) {
  const toast = useToast();
  const user = useUserData();
  const colSpanStepper = useBreakpointValue({ base: 12, sm: 4 });
  const buttonWidth = '200px';

  return (
    <GridItem colSpan={colSpanStepper}>
      <Flex
        direction={'column'}
        height={{ base: '100%', sm: '100%' }}
        backgroundColor={'brand.500'}
        padding={4}
        paddingBottom={'30%'}
        justifyContent={!props.contract ? 'center' : undefined}
        alignItems={!props.contract ? 'center' : undefined}
      >
        {!props.contract ? (
          <Spinner size={'xl'} color="white"></Spinner>
        ) : (
          <>
            <Box marginBottom={15}>
              <TextComponent as={'b'} color={'white'} fontSize={'2xl'}>
                Site
              </TextComponent>
              <Flex background={'white'} borderRadius={8} padding={5}>
                <Box width={'150%'}>
                  <Stack marginBottom={3}>
                    <TextInformation
                      text={'Estado:'}
                      description={
                        getFirstIfAny(props.contract.locations)?.stateName
                      }
                    />
                    <TextInformation
                      text={'Município:'}
                      tooltip={
                        getFirstIfAny(props.contract.locations)?.cityName
                      }
                      description={
                        getFirstIfAny(props.contract.locations)?.cityName
                      }
                    />
                    <TextInformation
                      tooltip={
                        getFirstIfAny(props.contract.locations)?.district
                      }
                      text={'Bairro:'}
                      description={notInfo(
                        getFirstIfAny(props.contract.locations)?.district
                      )}
                    />
                    <TextInformation
                      tooltip={getFirstIfAny(props.contract.locations)?.street}
                      text={'Logradouro:'}
                      description={notInfo(
                        getFirstIfAny(props.contract.locations)?.street
                      )}
                    />
                    <TextInformation
                      text={'Número:'}
                      description={cepMask(
                        getFirstIfAny(props.contract.locations)?.number
                      )}
                    />
                    <TextInformation
                      text={'Complemento:'}
                      tooltip={
                        getFirstIfAny(props.contract.locations)?.complement
                      }
                      description={notInfo(
                        getFirstIfAny(props.contract.locations)?.complement
                      )}
                    />
                    <TextInformation
                      text={'CEP:'}
                      description={cepMask(
                        getFirstIfAny(props.contract.locations)?.zipcode
                      )}
                    />
                  </Stack>
                </Box>
                <Box zIndex={1}>
                  <DashboardMap
                    width={200}
                    height={220}
                    latitude={Number(
                      getFirstIfAny(props?.contract?.locations)?.coordinates
                        ?.latitude
                    )}
                    longitude={Number(
                      getFirstIfAny(props?.contract?.locations)?.coordinates
                        ?.longitude
                    )}
                  />
                </Box>
              </Flex>
            </Box>
            <Box>
              <TextComponent as={'b'} color={'white'} fontSize={'2xl'}>
                Ações
              </TextComponent>
              <Stack direction={'row'} justifyContent={'center'} marginY={15}>
                <ButtonComponent
                  disabled={props.contract.termination != null}
                  width={buttonWidth}
                  colorScheme={'whiteAlpha'}
                  backgroundColor="#fff"
                  textColor={'#3182CE'}
                  onSubmit={() => props.onSubmitAddButton?.()}
                  leftIcon={
                    props.contract.contractStatus ===
                    StatusContract.Assinado ? (
                      <FaPeopleArrows />
                    ) : (
                      <FaEdit />
                    )
                  }
                >
                  {props.contract.contractStatus === StatusContract.Assinado
                    ? ' Aditivo'
                    : 'Editar contrato'}
                </ButtonComponent>
                <ButtonComponent
                  disabled={props.contract.termination != null}
                  width={buttonWidth}
                  colorScheme={'whiteAlpha'}
                  backgroundColor="#fff"
                  textColor={'red'}
                  onSubmit={() => props.onSubmitTerminateButton?.()}
                  leftIcon={<FaWindowClose />}
                >
                  Distrato
                </ButtonComponent>
              </Stack>
              <Stack direction={'row'} justifyContent={'center'} marginY={15}>
                <ButtonComponent
                  disabled={props.contract.termination != null}
                  width={buttonWidth}
                  colorScheme={'whiteAlpha'}
                  backgroundColor="#fff"
                  textColor={'#3182CE'}
                  onSubmit={() => props.onSubmitRenovateButton?.()}
                  leftIcon={<FaCheck />}
                >
                  Renovação
                </ButtonComponent>
                <ButtonComponent
                  disabled={props.contract.termination != null}
                  width={buttonWidth}
                  colorScheme={'whiteAlpha'}
                  backgroundColor="#fff"
                  textColor={'#3182CE'}
                  onSubmit={() => props.onSubmitAdjustmentButton?.()}
                  leftIcon={<FaChartLine />}
                >
                  Reajuste
                </ButtonComponent>
              </Stack>
              <Stack direction={'row'} justifyContent={'center'}>
                <ButtonComponent
                  width={buttonWidth}
                  disabled={props.contract.termination != null}
                  colorScheme={'whiteAlpha'}
                  backgroundColor="#fff"
                  textColor={'#3182CE'}
                  onSubmit={() => props.onSubmitChangeStatus?.()}
                  leftIcon={<FaExchangeAlt />}
                >
                  Alterar status
                </ButtonComponent>
              </Stack>
            </Box>
            <Box>
              <>
                <TextComponent as={'b'} color={'white'} fontSize={'2xl'}>
                  Documentos
                </TextComponent>
                <Flex direction={'column'} height={350}>
                  <ScrollableFeedComponent>
                    {props.contract?.documents?.length ? (
                      props.contract?.documents?.map((e, i) => (
                        <DocumentComponent
                          key={i}
                          onClick={() => window.open(`${e.url}`, '_blank')}
                          isContract={true}
                          onReplaceClick={() => {
                            props.onSubmitReplaceDocumentButton?.(e.id);
                          }}
                          onEditClick={() => {
                            props.onSubmitEditDocumentButton?.(e.id);
                          }}
                          onDeleteClick={() => {
                            putContractDisableDocument(e.id).then((res) => {
                              showToast({
                                toast,
                                status: 'success',
                                title: 'Sucesso',
                                description:
                                  'Documento desabilitado com sucesso',
                              });
                              props.reloadData?.();
                            });
                          }}
                          DocumentName={addSpreadText(e.displayName, 20)}
                          documentTypeDescription={e.typeDescription}
                          documentType={e.type}
                        >
                          <Flex
                            width={'100%'}
                            marginTop={'10px'}
                            direction={'column'}
                          >
                            <Flex alignItems={'flex-start'} mb={'10px'}>
                              <AvatarLabelComponent
                                label={user?.entity?.name}
                                subLabel={'Locatário'}
                                src={user?.entity?.entityImage}
                                textColor="white"
                              />
                              {props.contract?.signers?.some(
                                (item) =>
                                  item.name === user?.entity?.name &&
                                  item.hasSigned
                              ) ? (
                                <TagComponent
                                  styleContent={{ paddingLeft: 15 }}
                                  size="md"
                                  text="Assinado"
                                  colorScheme="green"
                                />
                              ) : (
                                <TagComponent
                                  styleContent={{ paddingLeft: 15 }}
                                  size="md"
                                  text="Pendente"
                                  colorScheme="yellow"
                                />
                              )}
                            </Flex>
                            {props.contract?.locators.map((locator, i) => (
                              <Flex
                                key={i}
                                alignItems={'flex-start'}
                                mb={'10px'}
                              >
                                <AvatarLabelComponent
                                  key={locator.locatorId}
                                  label={locator.name}
                                  subLabel={'Locador'}
                                  src={locator.profilePicUrl}
                                  textColor="white"
                                />
                                {e?.signers?.some(
                                  (item) =>
                                    item.name === locator.name && item.hasSigned
                                ) ? (
                                  <TagComponent
                                    styleContent={{ paddingLeft: 15 }}
                                    size="md"
                                    text="Assinado"
                                    colorScheme="green"
                                  />
                                ) : (
                                  <TagComponent
                                    styleContent={{ paddingLeft: 15 }}
                                    size="md"
                                    text="Pendente"
                                    colorScheme="yellow"
                                  />
                                )}
                              </Flex>
                            ))}
                          </Flex>
                        </DocumentComponent>
                      ))
                    ) : (
                      <EmptyStateDocumentComponent />
                    )}
                  </ScrollableFeedComponent>
                </Flex>
              </>
            </Box>
          </>
        )}
      </Flex>
    </GridItem>
  );
}
