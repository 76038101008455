import { Flex, FormLabel, Text, useToast } from '@chakra-ui/react';
import React, { Dispatch, SetStateAction } from 'react';
import {
  LocationResp,
  deleteLocation,
  useLocationSelectLocator,
} from '../../../../services/LocationService';
import { LocatorResp } from '../../../../services/LocatorService';
import { cepMask } from '../../../../utils/functions/masks';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import ContainerStepComponent from '../../atoms/ContainerStepComponent/ContainerStepComponent';
import InputCheckComponent from '../../atoms/InputCheckComponent/InputCheckComponent';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import TableComponent from '../../molecules/TableComponent/TableComponent';
import { CreateLocationModalComponent } from '../CreateLocationModalComponent/CreateLocationModalComponent';
import { SelectReceiverModalComponent } from '../SelectIntermediaryModalComponent/SelectIntermediaryModalComponent';
import AvatarLabelComponent from '../../atoms/AvatarLabelComponent/AvatarLabelComponent';
import IconButtonComponent from '../../atoms/ButtonComponent/IconButton';
import { FaEdit, FaInfo, FaTrash } from 'react-icons/fa';
import { showToast } from '../../../../utils/showToast';
import DetailsModalComponent from '../../molecules/DetailsModalComponent/DetailsModalComponent';
import { notInfo } from '../../../../pages/MyProfilePage';

type ContractStepperLocationComponentProps = {
  contractId: string;
  selectedLocators: LocatorResp[];
  selectedLocations: string[];
  setSelectedLocations: Dispatch<SetStateAction<string[]>>;
  selectedIntermediary: LocatorResp | undefined;
  setSelectedIntermediary: Dispatch<SetStateAction<LocatorResp | undefined>>;
};

export default function ContractStepperLocationComponent(
  props: ContractStepperLocationComponentProps
) {
  const listLocation = useLocationSelectLocator(
    props.selectedLocators.map((item) => item.locatorId)
  );
  const toast = useToast();
  const [showModalLocation, setShowModalLocation] = React.useState(false);
  const [showModalMiddleman, setShowModalMiddleman] = React.useState(false);
  const [selectedLocation, setSelectedLocation] = React.useState<
    LocationResp | undefined
  >();
  const [detailsDialog, setDetailsDialog] = React.useState(false);
  const modalCardInfo = [
    { item: 'Locador', description: notInfo(selectedLocation?.locator?.name) },
    { item: 'Tipo', description: notInfo(selectedLocation?.typeDescription) },
    {
      item: 'Metragem',
      description: notInfo(selectedLocation?.footage + 'm²'),
    },
    { item: 'CEP', description: notInfo(cepMask(selectedLocation?.zipcode)) },
    { item: 'Local', description: notInfo(selectedLocation?.street) },
    { item: 'Número', description: notInfo(selectedLocation?.number) },
    { item: 'Bairro', description: notInfo(selectedLocation?.district) },
    { item: 'Cidade', description: notInfo(selectedLocation?.cityName) },
    { item: 'Estado', description: notInfo(selectedLocation?.stateName) },
    { item: 'País', description: notInfo(selectedLocation?.countryName) },
    { item: 'Complemento', description: notInfo(selectedLocation?.complement) },
  ];

  return (
    <ContainerStepComponent title="Selecione o local que será alocado">
      <Flex mb={8} alignItems={'flex-top'}>
        <Flex direction={'column'} mr={7}>
          <FormLabel mb={2}>Origem</FormLabel>
          <TextComponent textAlign={'center'} fontSize={'large'}>
            Vivo LTDA
          </TextComponent>
        </Flex>
        <Flex direction="column">
          <TextComponent fontWeight={'bold'}>Intermediário:</TextComponent>
          {props.selectedIntermediary ? (
            <AvatarLabelComponent
              label={props.selectedIntermediary.name}
              subLabel={'Intermediário'}
              src={props.selectedIntermediary.profilePicUrl}
            />
          ) : (
            <TextComponent>Intermediário não selecionado</TextComponent>
          )}
        </Flex>
        <ButtonComponent
          margin={'0 20px'}
          onSubmit={() => setShowModalMiddleman(true)}
        >
          Selecione o intermediário
        </ButtonComponent>
      </Flex>
      <Flex justifyContent={'space-between'} mb={8}>
        <Text textAlign={'center'} fontSize={'large'}>
          Locais Vinculados
        </Text>
        <ButtonComponent
          onSubmit={() => {
            setShowModalLocation(true);
            setSelectedLocation(undefined);
          }}
        >
          Novo Local
        </ButtonComponent>
      </Flex>
      <TableComponent
        isLoading={listLocation.isLoading}
        centered
        ItemsHeader={[
          { item: 'Selecionar' },
          { item: 'Contratos-vinculados' },
          { item: 'Logradouro' },
          { item: 'Bairro' },
          { item: 'Cidade' },
          { item: 'Estado' },
          { item: 'Complemento' },
          { item: 'Ações' },
        ]}
        data={
          listLocation.data?.map((e: LocationResp, i: number) => ({
            items: [
              <InputCheckComponent
                key={i}
                isChecked={props.selectedLocations.some(
                  (item) => item === e.id
                )}
                onChange={() => {
                  if (!props.selectedLocations.some((item) => item === e.id))
                    props.setSelectedLocations([
                      ...props.selectedLocations,
                      e.id,
                    ]);
                  else {
                    props.setSelectedLocations(
                      props.selectedLocations.filter((item) => item !== e.id)
                    );
                  }
                }}
              ></InputCheckComponent>,
              e.allContractsCount,
              e.street,
              e.district,
              e.cityName,
              e.stateName,
              e.complement,
              <Flex
                justifyItems={'center'}
                alignItems={'center'}
                flexDir={'column'}
              >
                <Flex mb={2}>
                  <IconButtonComponent
                    icon={<FaInfo />}
                    toolTipText="Detalhes"
                    ariaLabel="info"
                    onSubmit={() => {
                      setSelectedLocation(e);
                      setDetailsDialog(true);
                    }}
                  />
                  <IconButtonComponent
                    icon={<FaEdit />}
                    ariaLabel="editar"
                    toolTipText={
                      e.canBeChanged
                        ? 'Editar'
                        : 'Não é possível editar esse local, pois existem contratos vinculados.'
                    }
                    disabled={e.canBeChanged ? false : true}
                    marginX={'8px'}
                    colorScheme={'green'}
                    onSubmit={() => {
                      setSelectedLocation(e);
                      setShowModalLocation(true);
                    }}
                  />
                </Flex>

                <IconButtonComponent
                  icon={<FaTrash />}
                  toolTipText={
                    e.canBeChanged
                      ? 'Deletar'
                      : 'Não é possível deletar esse local, pois existem contratos vinculados.'
                  }
                  ariaLabel="deletar"
                  disabled={e.canBeChanged ? false : true}
                  colorScheme={'red'}
                  onSubmit={() => {
                    deleteLocation(e.id, props.contractId).then((res) => {
                      showToast({
                        toast,
                        status: 'success',
                        title: 'Sucesso',
                        description: 'Local deletado com sucesso',
                      });
                      listLocation.refetch();
                    });
                  }}
                />
              </Flex>,
            ],
          })) || []
        }
      />
      <CreateLocationModalComponent
        showModal={showModalLocation}
        selectedLocation={selectedLocation}
        setShowModal={() => setShowModalLocation(false)}
        reloadData={() => listLocation.refetch()}
        customLocators={props.selectedLocators}
      />
      <SelectReceiverModalComponent
        showModal={showModalMiddleman}
        setShowModal={() => setShowModalMiddleman(false)}
        selectedIntermediaryReceiver={props.selectedIntermediary}
        setSelectedIntermediaryReceiver={(intermediary) =>
          props.setSelectedIntermediary(intermediary)
        }
      />
      <DetailsModalComponent
        isOpen={detailsDialog}
        title={'Local'}
        onClose={() => setDetailsDialog(false)}
        data={modalCardInfo}
      />
    </ContainerStepComponent>
  );
}
