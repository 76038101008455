import { Avatar, Flex } from '@chakra-ui/react';
import HeaderTextComponent from '../components/UI/atoms/HeaderTextComponent/HeaderTextComponent';
import TextComponent from '../components/UI/atoms/TextComponent/TextComponent';
import { useMyProfile } from '../services/PhysicalPersonService';
import { formatDate } from '../utils/functions/formatDate';

export default function MyProfilePage() {
  const profile = useMyProfile().data;

  return (
    <Flex flexDir={'column'}>
      <Flex flexDir={'column'} mb={4}>
        <HeaderTextComponent>Meu Perfil</HeaderTextComponent>
        <HeaderTextComponent subTitle>
          Você pode visualizar as informações do seu perfil
        </HeaderTextComponent>
      </Flex>
      <Flex mt={8} alignItems={'center'}>
        <Avatar
          size={'2xl'}
          name={profile?.displayName ?? ''}
          src={
            profile?.profilePicUrl ??
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXFxcX////CwsLGxsb7+/vT09PJycn19fXq6urb29ve3t7w8PDOzs7n5+f5+fnt7e30nlkBAAAFHUlEQVR4nO2dC5qqMAyFMTwUBdz/bq+VYYrKKJCkOfXmXwHna5uTpA+KwnEcx3Ecx3Ecx3Ecx3Ecx3Ecx3Ecx3Ecx3EcA2iO9cdIc5PUdO257y+BU39u66b4HplE3fk6VIcnqmNfl1+gksr6+iIucjl3WYukor7+re6Hoe1y1UhNO3zUd+fUFRmKpOa0Tt6dY5ubRCrOG/QFLk1WGmnt/JxzykcjdZ/jyxJDLlOV2l36AtcsJJb9boG3YcR3DuqODIE3ztYKPkDdmwRmpUToUaSaq++AvRgZMWbOpbQW8hdCAm8ZDugoikzREdCJ2okJPBx6azFLNOwoOgcxojJ98JkaTSJxMpklKrCAKhZGI0drTY/wU5lXoJYibannV9NYy4oozNEAkPHTjop+DTDxVGkIgYJNoyQQJtiIW+EMjGAjm649AjGIaqswcEFQKJ2QPlJbqytki6ZXAAZRJ52J2McaUowzAfs+uFzrYhnzaapphiPWdaJWShqxjqa6kTTQ205TVbsfMa6htL0iYOsXpJrQjHSmCkv1QGPtiHqlYcQ21Gj7fcDU8xOEUuNgSltPzexh+HqFlanCBHZ4OLhCV+gK/3OF6vWvucLv98MUOY2pwu/PS/+D2qJU7pYGbOvDFDW+bbON9p3o3oRxn0bfLgZTgSn6pSfrtr56qLHemtHPTK2319SzGvtjQ9qeb39WgS66Cm073nd0U1PzDdJCO3Gzn6TKpl9Zq7ujGWsQhlA3NwWIMwG9zM08Y/tBrR9VWeczv5CSQuuUNKIUTk23ZJ5RKfVhjnkXotfWIlgX2BSCDYbZR+QTcLhb3dKZDUY2M0d4KWItwhHRah/zsrOgKw4wycwjcgEVcgQDQo23CqSiWEJkFAfod2oE1uIFdA1OsCPqFXYNTjCfb8Ez+iX2x5sKLlVbhtqdDcar9ZevhnbZxoBUD35k23t0d304LYs1ELVbnfFaZ/REJJX9niP8Q19moZGo3m8XR/yBvOnjFfsXcI2c8ZuNo7WMP5HQh6yRGrlmFOJTnyTcT+zRlqPUBI2gTVWNUzUna1ERgecgF4GpNBQ38jGqxVLzQA1A31Rrhk6Yz9QEh/WND0GnuG9huhiTXJkxfAizTHLr6cbJKN6UCU6x/2DTRE1xEeEXi3O0ZUqBN4nJRzHhFB1JPlFTBZlI2kQ8zc3KJ1Le8DIRmFJiknuVS6RK4Ej/JtBfJErDSzOBiY4wJHX6Z1I4v1GUmdCPNirnLLeg3oJLcbX5PcpHNbRvOa1A956QmRPOUXVF+zkaUJynpkYR0bOMJH2nNej1pqyV/aKkz9jr5yj5vrXXz1F5SQLACiMapmierj2ikLyleKdlA/I/2oFxiglxx9B+mHwz0lf34IZQfhDRhlD6bhvgEAoPYooHkTczSIZTLC+cEExsoNKZiGBiY9cCfo/Y/SjIOBMQizWWTe73CMUasJx7jlD+DdKdWUKoY4PRYFtGpO0G1Lx4RaadgTtJhf4fiGqGIwKWCGuGIwKWqP+7IxYCzygjR9IAO5pC7Da9g70TBVpWRNgFBlgT8RV2WxHbKwJMv4BOaEaYaU2K16yZMN/qgV+G7IWIvwyZCxHeDQMsR8wg0DBDDXB5H2EV+hkEGmaoySHQsEJNFoGGFWrAq98JRhUMX1iMMMqLLEIpK5jCbd4vw9nSt/72lewXiN6jmdjfq8Hdknlk92ZwJnbIMMRM7JBhiFlUFoHd1UWaP1QKsPsHA5mkNB+Smn9JqV3wskatnQAAAABJRU5ErkJggg=='
          }
        />
      </Flex>
      <Flex>
        <ContainerDetails
          conatainer="left"
          title="Informações pessoais"
          childreen={
            <>
              <LabelInfoDetails
                label="Nome"
                details={notInfo(profile?.firstName) ?? ''}
              />
              <LabelInfoDetails
                label="Sobrenome"
                details={notInfo(profile?.lastName) ?? ''}
              />
              <LabelInfoDetails label="CPF" details={profile?.document ?? ''} />
              <LabelInfoDetails
                label="Apelido"
                details={notInfo(profile?.displayName) ?? ''}
              />
              <LabelInfoDetails
                label="Celular"
                details={notInfo(profile?.phones[0]?.number) ?? ''}
              />
              <LabelInfoDetails
                label="Data de Nasimento"
                details={notInfo(formatDate(profile?.date)) ?? ''}
              />
            </>
          }
        />
        <ContainerDetails
          conatainer="right"
          title="Informações de endereço"
          childreen={
            <>
              <LabelInfoDetails
                label="País"
                details={profile?.addresses[0]?.countryName ?? ''}
              />
              <LabelInfoDetails
                label="Estado"
                details={profile?.addresses[0]?.stateName ?? ''}
              />
              <LabelInfoDetails
                label="Cidade"
                details={profile?.addresses[0]?.cityName ?? ''}
              />
              <LabelInfoDetails
                label="Rua"
                details={profile?.addresses[0]?.street ?? ''}
              />
              <LabelInfoDetails
                label="CEP"
                details={profile?.addresses[0]?.zipcode ?? ''}
              />
              <LabelInfoDetails
                label="Complemento"
                details={profile?.addresses[0]?.complement ?? ''}
              />
            </>
          }
        />
      </Flex>
    </Flex>
  );
}
function ContainerDetails(props: {
  title: string;
  childreen: React.ReactNode;
  conatainer?: 'left' | 'right';
}) {
  return (
    <Flex flexDir={'column'}>
      <Flex
        mt={10}
        mb={4}
        mr={props.conatainer === 'left' ? 8 : 0}
        ml={props.conatainer === 'left' ? 0 : 8}
      >
        <TextComponent fontWeight={'light'} fontSize={22} color={'gray.500'}>
          {props.title}
        </TextComponent>
      </Flex>
      <Flex
        mr={props.conatainer === 'left' ? 8 : 0}
        ml={props.conatainer === 'left' ? 0 : 8}
        flexWrap={'wrap'}
        mt={4}
      >
        {props.childreen}
      </Flex>
    </Flex>
  );
}

export function LabelInfoDetails(props: {
  label: string | undefined;
  details?: string | undefined | null;
  children?: React.ReactNode;
  notMessage?: string;
}) {
  return (
    <Flex mb={12} w={300} flexDir={'column'}>
      <TextComponent fontSize={16} fontWeight={'bold'}>
        {props.label}
      </TextComponent>
      {props.children ? (
        <Flex>{props.children}</Flex>
      ) : (
        <TextComponent fontSize={16} color={'gray.500'}>
          {notInfo(props.details, props.notMessage)}
        </TextComponent>
      )}
    </Flex>
  );
}
export function notInfo(info: string | undefined | null, message?: string) {
  if (info === '' || info === null || info === undefined) {
    return message || 'Não informado';
  }
  return info;
}
