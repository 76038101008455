import { Flex, FormControl, useToast, Text, Stack } from '@chakra-ui/react';
import React, { Dispatch, SetStateAction } from 'react';
import { FaWindowClose } from 'react-icons/fa';
import { showToast } from '../../../../utils/showToast';
import IconButtonComponent from '../../atoms/ButtonComponent/IconButton';
import ContainerStepComponent from '../../atoms/ContainerStepComponent/ContainerStepComponent';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import {
  ConsiderationResp,
  useConsiderationSelect,
} from '../../../../services/ConsiderationService';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import { Witnesses } from '../../../../services/ContractService';
import { cpfMask } from '../../../../utils/functions/masks';

type ContractStepperConsiderationComponentProps = {
  selectedConsiderations: (ConsiderationResp | undefined)[];
  setSelectedConsiderations: Dispatch<(ConsiderationResp | undefined)[]>;
  formOne: Witnesses[];
  setFormOne: Dispatch<SetStateAction<Witnesses[]>>;
  formTwo: Witnesses[];
  setFormTwo: Dispatch<SetStateAction<Witnesses[]>>;
};

export default function ContractStepperConsiderationComponent(
  props: ContractStepperConsiderationComponentProps
) {
  const toast = useToast();
  const listConsideration = useConsiderationSelect();

  function validateWitnessDocument(
    document1: string | undefined,
    document2: string | undefined
  ) {
    if (
      document1 === undefined ||
      document2 === undefined ||
      document1 === '' ||
      document2 === ''
    ) {
      return '';
    }
    if (document1 === document2) {
      return 'CPF das testemunhas não pode ser igual';
    }
    return '';
  }
  return (
    <ContainerStepComponent title="Considerações">
      <Flex flexDirection={'row'}>
        <Flex w={'100%'} flexDirection={'column'}>
          <FormControl>
            <InputSelectComponent
              label=""
              placeholder="Selecione alguma para adicionar"
              value="null"
              options={listConsideration.data?.map((item) => ({
                id: item.id,
                name: item.content,
              }))}
              onChange={(input) => {
                if (
                  !props.selectedConsiderations.some(
                    (item) => item?.id === input.target.value
                  )
                ) {
                  props.setSelectedConsiderations([
                    ...props.selectedConsiderations,
                    listConsideration.data?.filter(
                      (item) => item.id === input.target.value
                    )[0],
                  ]);
                } else {
                  showToast({
                    toast,
                    status: 'info',
                    title: 'Atenção',
                    description: 'Essa consideração já foi adicionada',
                    position: 'top-left',
                  });
                }
              }}
            />
          </FormControl>
          {props.selectedConsiderations.map((item, i) => (
            <Flex
              key={i}
              direction={'row'}
              justifyContent={'space-between'}
              width={'100%'}
              borderBottom="1px solid black"
            >
              <Flex direction={'column'}>
                <TextComponent fontWeight={'400'}>
                  {item?.content}
                </TextComponent>
              </Flex>
              <IconButtonComponent
                icon={<FaWindowClose />}
                ariaLabel="Remover"
                variant={'clear'}
                textColor={'red'}
                onSubmit={() =>
                  props.setSelectedConsiderations([
                    ...props.selectedConsiderations.filter(
                      (selected) => selected?.id !== item?.id
                    ),
                  ])
                }
              />
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Text
        mt={'30px'}
        textAlign={'left'}
        mb={8}
        fontSize={'20px'}
        fontWeight={'semibold'}
      >
        Testemunhas
      </Text>
      <Text mb={5} textAlign={'left'} fontSize={'20px'} fontWeight={'light'}>
        Testemunha 1
      </Text>
      <Stack mb={5} direction={'row'} spacing={'2%'}>
        <InputComponent
          label="Nome"
          placeholder="Digite o nome"
          value={props.formOne[0] ? props.formOne[0].name : ''}
          onChange={(input) => {
            if (!props.formOne) {
              return;
            }
            props.setFormOne([
              {
                ...props.formOne[0],
                name: input.target.value,
              },
            ]);
          }}
        />
        <InputComponent
          label="CPF"
          placeholder="Digite o CPF"
          value={props.formOne[0] ? props.formOne[0].document : ''}
          onChange={(input) => {
            if (!props.formOne) {
              return;
            }
            props.setFormOne([
              {
                ...props.formOne[0],
                document: cpfMask(input.target.value),
              },
            ]);
          }}
        />
        <InputComponent
          label="Email"
          placeholder="Digite o email"
          value={props.formOne[0] ? props.formOne[0].email : ''}
          onChange={(input) => {
            if (!props.formOne) {
              return;
            }
            props.setFormOne([
              {
                ...props.formOne[0],
                email: input.target.value,
              },
            ]);
          }}
        />
      </Stack>
      <Text mb={5} textAlign={'left'} fontSize={'20px'} fontWeight={'light'}>
        Testemunha 2
      </Text>
      <Stack mb={5} direction={'row'} spacing={'2%'}>
        <InputComponent
          label="Nome"
          placeholder="Digite o nome"
          value={props.formTwo[0] ? props.formTwo[0].name : ''}
          onChange={(input) => {
            if (!props.formTwo) {
              return;
            }
            props.setFormTwo([
              {
                ...props.formTwo[0],
                name: input.target.value,
              },
            ]);
          }}
        />
        <InputComponent
          label="CPF"
          placeholder="Digite o CPF"
          value={props.formTwo[0] ? props.formTwo[0].document : ''}
          onChange={(input) => {
            if (!props.formTwo) {
              return;
            }
            props.setFormTwo([
              {
                ...props.formTwo[0],
                document: cpfMask(input.target.value),
              },
            ]);
          }}
          error={validateWitnessDocument(
            props.formOne[0]?.document,
            props.formTwo[0]?.document
          )}
        />
        <InputComponent
          label="Email"
          placeholder="Digite o email"
          value={props.formTwo[0] ? props.formTwo[0].email : ''}
          onChange={(input) => {
            if (!props.formTwo) {
              return;
            }
            props.setFormTwo([
              {
                ...props.formTwo[0],
                email: input.target.value,
              },
            ]);
          }}
        />
      </Stack>
    </ContainerStepComponent>
  );
}
