import { LocatorResp } from './LocatorService';
import { useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';
import { openFile } from '../utils/functions/utility';

export type LocationResp = {
  id: string;
  canBeChanged: boolean;
  street: string;
  district: string;
  number: string;
  complement: string;
  zipcode: string;
  type: LocationType;
  typeDescription: string;
  cityId: string;
  stateId: string;
  countryId: string;
  cityName: string;
  stateName: string;
  countryName: string;
  footage: number;
  locator?: LocatorResp;
  locatorId?: string;
  activeContractsCount?: number;
  allContractsCount?: number;
  coordinates: {
    id: string;
    latitude: string;
    longitude: string;
    description: string;
    localizationType: number;
  };
};

export type Location = {
  id?: string;
  owner?: string;
  ownerId?: string;
  street: string;
  district: string;
  number: string;
  complement: string;
  zipcode: string;
  type: LocationType;
  typeDescription?: string;
  cityId: string;
  cityName?: string;
  stateId: string;
  stateName?: string;
  countryName?: string;
  coordenateId?: string;
  footage: number;
  locatorId?: string;
  coordinates: {
    latitude: string | number;
    longitude: string | number;
    description: string;
    localizationType: CoordenatesLocationType;
  };
  locator?: {
    name: string;
  };
};

export enum LocationType {
  Nenhum = 0,
  Residencial = 1,
  Comercial = 2,
  Outros = 3,
}

export enum CoordenatesLocationType {
  Null = 0,
  Region = 1,
  Address = 2,
}

export function useLocation(
  page: number,
  pageSize: number | null,
  sort: string = 'title'
) {
  return useQuery([`LocationList`, page, sort], async () => {
    const resp = await Api.get(`/Location`, {
      params: {
        Offset: page,
        Limit: pageSize,
        SortColumn: sort,
      },
    });
    return getRespContent<LocationResp[]>(resp);
  });
}

export function useLocationSelect() {
  return useQuery([`LocationListSelect`], async () => {
    const resp = await Api.get(`/Location`);
    return getResp<LocationResp[]>(resp);
  });
}

export function useLocationSelectLocator(locatorIds: string[] = []) {
  return useQuery(
    [`LocationListSelect`, locatorIds],
    async () => {
      const resp = await Api.get(`/Location`, {
        params: {
          LocatorIds: locatorIds,
        },
        paramsSerializer: (params) => {
          const serializedParams = params;
          serializedParams.LocatorIds =
            '&LocatorIds=' +
            params.LocatorIds.reduce(
              (prev: string, next: string) => prev + '&LocatorIds=' + next
            );
          return serializedParams.LocatorIds;
        },
      });
      return getResp<LocationResp[]>(resp);
    },
    { enabled: locatorIds.length > 0 }
  );
}

export function saveLocation(props: Location) {
  if (props.id) {
    return putLocation(props);
  }
  return postLocation(props);
}

export function postLocation(props: Location) {
  return Api.post('/Location', props);
}

export function putLocation(props: Location) {
  return Api.put(`/Location/${props.id}`, props);
}

export function deleteLocation(id: string, contractId?: string) {
  return Api.delete(`/Location/${id}/contractId/${contractId}`);
}

export function getLocationReport(sort: string = 'title') {
  Api.get(`/Location/report`, {
    params: {
      SortColumn: sort,
    },
    responseType: 'blob',
  }).then((response) => {
    openFile(response);
  });
}
