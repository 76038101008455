import {
  Flex,
  Grid,
  GridItem,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import {
  FaBuffer,
  FaChartLine,
  FaCheck,
  FaPeopleArrows,
  FaSync,
  FaTimes,
} from 'react-icons/fa';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import IconButtonComponent from '../../atoms/ButtonComponent/IconButton';
import TagComponent from '../../atoms/TagComponent/TagComponent';
import ModalStructureComponent from '../ModalStructureComponent/ModalStructureComponent';
import TableComponent from '../TableComponent/TableComponent';
import {
  CammpaignContractsResp,
  CampaignStatus,
  CampaignType,
  NegotiationStatus,
  putCampaignCancel,
  putCampaignCancelAllNegotiations,
  putCampaignContractNegociationStatus,
  putCampaignFinish,
  useCampaignContractDetails,
  useCampaignDetails,
} from '../../../../services/CampaignsService';
import { parseDateAndHour } from '../../../../utils/functions/utility';
import { showToast } from '../../../../utils/showToast';
import {
  PaginationComponent,
  usePagination,
} from '../PaginationComponent/PaginationComponent';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertToMonetaryValue } from '../../../../utils/functions/masks';
import { LabelInfoDetails } from '../../../../pages/MyProfilePage';
import { ContractAdjustmentModalComponent } from '../../organisms/ContractAdjustmentModalComponent/ContractAdjustmentModalComponent';
import { ContractRenovationModalComponent } from '../../organisms/ContractRenovationModalComponent/ContractRenovationModalComponent';
import { ContractTerminationModalComponent } from '../../organisms/ContractTerminationModalComponent/ContractTerminationModalComponent';
import { useContractDetails } from '../../../../services/ContractService';
import AvatarLabelComponent from '../../atoms/AvatarLabelComponent/AvatarLabelComponent';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';

export function CampaignDetailsModal(props: {
  id: string;
  showModal: boolean;
  reloadData: () => void;
  setShowModal: () => void;
}) {
  const colSpanOnBoarding = useBreakpointValue({ base: 12, sm: 8 });
  const toast = useToast();
  const navigate = useNavigate();
  const { selectedPage, setSelectedPage } = usePagination();
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const campaign = useCampaignDetails(props.id);
  const campaignDetails = campaign.data;
  const campaignContracts = useCampaignContractDetails(
    props.id,
    selectedPage,
    4
  );
  const [showModalRenovation, setShowModalRenovation] = useState(false);
  const [showModalAdjustment, setShowModalAdjustment] = useState(false);
  const [showModalTermination, setShowModalTermination] = useState(false);
  const [selectedContractId, setSelectedContractId] = useState('');
  const [showModalEditNegotiationStatus, setShowModalEditNegotiationStatus] =
    useState(false);
  const [newNegotiationStatusContract, setNewNegotiationStatus] =
    useState<NegotiationStatus>();
  const [index, setIndex] = useState(0);

  const [loadingEditStatus, setLoadingEditStatus] = useState(false);
  const contract = useContractDetails(selectedContractId);
  const [loadingCancelAllNegotiation, setLoadingCancelAllNegotiation] =
    useState(false);
  // useEffect(()=>{
  //   campaignContracts.refetch()
  // },[showModalAdjustment])
  function RenderButtonBytype(props: {
    campaingnId: string;
    type: CampaignType | undefined;
    campaign: CammpaignContractsResp;
    index: number;
    disabled?: boolean | undefined;
  }) {
    if (props.type === CampaignType.Readjustment) {
      return (
        <Flex justifyContent={'center'}>
          <IconButtonComponent
            disabled={props.disabled}
            marginX={1}
            variant={'outline'}
            onSubmit={() => {
              // navigate(`/contract-history/${props.campaign.contractId}`);
              window.open(
                `/contract-history/${props.campaign.contractId}`,
                '_blank'
              );
            }}
            ariaLabel="play"
            icon={<FaBuffer />}
            toolTipText="histórico do contrato"
          />

          <IconButtonComponent
            disabled={props.disabled}
            marginX={1}
            variant={'outline'}
            colorScheme="red"
            onSubmit={() => {
              setSelectedContractId(props.campaign.contractId);
              setIndex(props.index);
              setShowModalTermination(true);
            }}
            ariaLabel="Distrato"
            icon={<FaTimes />}
            toolTipText="Distrato"
          />

          <IconButtonComponent
            disabled={props.disabled}
            marginX={1}
            variant={'outline'}
            onSubmit={() => {
              setSelectedContractId(props.campaign.contractId);
              setShowModalAdjustment(true);
            }}
            ariaLabel="Reajuste"
            icon={<FaChartLine />}
            toolTipText="Reajuste"
          />
          <IconButtonComponent
            disabled={props.disabled}
            marginX={1}
            variant={'outline'}
            onSubmit={() => {
              setSelectedContractId(props.campaign.contractId);
              setIndex(props.index);
              setShowModalEditNegotiationStatus(true);
            }}
            ariaLabel="Alterar status da negociação do contrato"
            icon={<FaSync />}
            toolTipText="Alterar status da negociação do contrato"
          />
        </Flex>
      );
    }
    if (props.type === CampaignType.Renewal) {
      return (
        <Flex justifyContent={'center'}>
          <IconButtonComponent
            disabled={props.disabled}
            marginX={1}
            variant={'outline'}
            onSubmit={() => {
              // navigate(`/contract-history/${props.campaign.contractId}`);
              window.open(
                `/contract-history/${props.campaign.contractId}`,
                '_blank'
              );
            }}
            ariaLabel="play"
            icon={<FaBuffer />}
            toolTipText="histórico do contrato"
          />
          <IconButtonComponent
            disabled={props.disabled}
            marginX={1}
            variant={'outline'}
            colorScheme="red"
            onSubmit={() => {
              setSelectedContractId(props.campaign.contractId);
              setShowModalTermination(true);
            }}
            ariaLabel="Distrato"
            icon={<FaTimes />}
            toolTipText="Distrato"
          />
          <IconButtonComponent
            disabled={props.disabled}
            marginX={1}
            variant={'outline'}
            onSubmit={() => {
              setSelectedContractId(props.campaign.contractId);
              setShowModalRenovation(true);
            }}
            ariaLabel="Renovação"
            icon={<FaCheck />}
            toolTipText="Renovação"
          />
          <IconButtonComponent
            disabled={props.disabled}
            marginX={1}
            variant={'outline'}
            onSubmit={() => {
              setSelectedContractId(props.campaign.contractId);
              setIndex(props.index);
              setShowModalEditNegotiationStatus(true);
            }}
            ariaLabel="Alterar status da negociação do contrato"
            icon={<FaSync />}
            toolTipText="Alterar status da negociação do contrato"
          />
        </Flex>
      );
    } else
      return (
        <Flex justifyContent={'center'}>
          <IconButtonComponent
            disabled={props.disabled}
            marginX={1}
            variant={'outline'}
            onSubmit={() => {
              // navigate(`/contract-history/${props.campaign.contractId}`);
              window.open(
                `/contract-history/${props.campaign.contractId}`,
                '_blank'
              );
            }}
            ariaLabel="play"
            icon={<FaBuffer />}
            toolTipText="histórico do contrato"
          />
          <IconButtonComponent
            disabled={props.disabled}
            marginX={1}
            variant={'outline'}
            onSubmit={() => {
              setSelectedContractId(props.campaign.contractId);
              navigate(`/new-contract/${props.campaign.contractId}`);
            }}
            ariaLabel="Aditivo"
            icon={<FaPeopleArrows />}
            toolTipText="Aditivo"
          />

          <IconButtonComponent
            disabled={props.disabled}
            marginX={1}
            variant={'outline'}
            onSubmit={() => {
              setSelectedContractId(props.campaign.contractId);
              setShowModalRenovation(true);
            }}
            ariaLabel="Renovação"
            icon={<FaCheck />}
            toolTipText="Renovação"
          />

          <IconButtonComponent
            disabled={props.disabled}
            marginX={1}
            variant={'outline'}
            colorScheme="red"
            onSubmit={() => {
              setSelectedContractId(props.campaign.contractId);
              setShowModalTermination(true);
            }}
            ariaLabel="Distrato"
            icon={<FaTimes />}
            toolTipText="Distrato"
          />

          <IconButtonComponent
            disabled={props.disabled}
            marginX={1}
            variant={'outline'}
            onSubmit={() => {
              setSelectedContractId(props.campaign.contractId);
              setShowModalAdjustment(true);
            }}
            ariaLabel="Reajuste"
            icon={<FaChartLine />}
            toolTipText="Reajuste"
          />
          <IconButtonComponent
            disabled={props.disabled}
            marginX={1}
            variant={'outline'}
            onSubmit={() => {
              setSelectedContractId(props.campaign.contractId);
              setIndex(props.index);
              setShowModalEditNegotiationStatus(true);
            }}
            ariaLabel="Alterar status da negociação do contrato"
            icon={<FaSync />}
            toolTipText="Alterar status da negociação do contratos"
          />
        </Flex>
      );
  }
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => {
        props.setShowModal();
      }}
      title={`Detalhes da campanha`}
      maxW="98%"
      size="6xl"
    >
      <>
        <Flex flexDir={'column'}>
          <Flex>
            <LabelInfoDetails
              label="Campanha"
              details={`id ${campaignDetails?.serialNumber}` || ''}
            />
            <LabelInfoDetails
              label="Nome da campanha"
              details={`${campaignDetails?.name}` || ''}
            />
            <LabelInfoDetails
              label="Tipo de campanha"
              details={String(campaignDetails?.typeDescription) || ''}
            />
            <LabelInfoDetails
              label="Valor Somado"
              details={`${convertToMonetaryValue(
                campaignDetails?.totalValue ?? 0
              )}`}
            />
            <LabelInfoDetails
              label="Status"
              children={getStatusByType(campaignDetails?.status || 0)}
            />
            <LabelInfoDetails
              label="Responsável"
              details={campaignDetails?.responsibleName || ''}
            />
            <LabelInfoDetails
              label="Contratos"
              details={String(campaignDetails?.count) || ''}
            />
          </Flex>
          <Flex mb={2} justifyContent={'flex-end'}>
            <ButtonComponent
              margin={'0 10px 0 0'}
              variant={'outline'}
              isLoading={loadingCancelAllNegotiation}
              disabled={loadingCancelAllNegotiation}
              onSubmit={() => {
                setLoadingCancelAllNegotiation(true);
                putCampaignCancelAllNegotiations(campaignDetails?.id)
                  .then(() => {
                    showToast({
                      toast,
                      status: 'success',
                      title: 'Sucesso',
                      description:
                        'Todas as negociações pendentes foram canceladas.',
                    });
                  })
                  .finally(() => {
                    setLoadingCancelAllNegotiation(false);
                    campaignContracts.refetch();
                  });
              }}
              colorScheme={'yellow'}
            >
              Cancelar negociações pendentes
            </ButtonComponent>
            <ButtonComponent
              margin={'0 10px 0 0'}
              variant={'outline'}
              onSubmit={() => {
                putCampaignCancel(campaignDetails?.id).then((res) => {
                  showToast({
                    toast,
                    status: 'success',
                    title: 'Sucesso',
                    description: 'Concluido com sucesso',
                  });
                  props.reloadData();
                  props.setShowModal();
                });
              }}
              colorScheme={'red'}
            >
              Cancelar
            </ButtonComponent>
            <ButtonComponent
              variant={'outline'}
              margin={'0 10px 0 0'}
              onSubmit={() => {
                putCampaignFinish(campaignDetails?.id).then((res) => {
                  showToast({
                    toast,
                    status: 'success',
                    title: 'Sucesso',
                    description: 'Concluido com sucesso',
                  });
                  props.reloadData();
                  props.setShowModal();
                });
              }}
            >
              Concluir
            </ButtonComponent>
          </Flex>

          <TableComponent
            ItemsHeader={[
              { item: 'Número do Contrato' },
              { item: 'Locador' },
              { item: 'Data' },
              { item: 'Situação de negociação' },
              { item: 'Valor' },
              { item: 'Ação' },
            ]}
            isLoading={campaignContracts.isLoading}
            centered={true}
            data={
              campaignContracts.data?.data.map((e, i) => ({
                items: [
                  e.serialNumber,
                  <Flex justify={'center'}>
                    <AvatarLabelComponent label={e.locatorName} />
                  </Flex>,
                  parseDateAndHour(e.activationDate),
                  <TagComponent
                    size="md"
                    text={e.negotiationStatusDescription}
                    colorScheme={getColorNegotiationStatus(e.negotiationStatus)}
                  />,
                  convertToMonetaryValue(e.value),
                  <RenderButtonBytype
                    disabled={e.negotiationStatus === NegotiationStatus.Closed}
                    campaingnId={props.id}
                    index={i}
                    type={campaignDetails?.type}
                    campaign={e}
                  />,
                ],
              })) || []
            }
          />
          <PaginationComponent
            onSelectedPageChanged={onSelectedPageChanged}
            selectedPage={selectedPage}
            arrayLength={campaignContracts.data?.metadata.dataSize || 0}
            maxPageItens={4}
          ></PaginationComponent>
        </Flex>
        <Grid margin={0} templateColumns="repeat(12, 2fr)">
          <GridItem
            display={'flex'}
            flexDirection={'column'}
            colSpan={colSpanOnBoarding}
            padding={'20px'}
            paddingTop={'0px'}
          ></GridItem>
        </Grid>

        <ContractRenovationModalComponent
          campaingId={props.id}
          selectedContract={contract.data}
          showModal={showModalRenovation}
          setShowModal={() => {
            setShowModalRenovation(false);
          }}
          reloadData={() => {
            campaignContracts.refetch();
            contract.refetch();
          }}
        />
        <ContractAdjustmentModalComponent
          campaingId={props.id}
          selectedContract={contract.data}
          showModal={showModalAdjustment}
          setShowModal={() => {
            setShowModalAdjustment(false);
          }}
          reloadData={() => {
            campaignContracts.refetch();
            contract.refetch();
          }}
        />
        <ContractTerminationModalComponent
          selectedContract={contract.data}
          showModal={showModalTermination}
          setShowModal={() => {
            setShowModalTermination(false);
          }}
          reloadData={() => {
            campaignContracts.refetch();
            contract.refetch();
          }}
        />
        <ModalStructureComponent
          isOpen={showModalEditNegotiationStatus}
          onClose={() => setShowModalEditNegotiationStatus(false)}
          title={'Alterar status da negociação do contrato'}
          isCentered={true}
          footer={
            <Flex p={4} justifyContent={'end'}>
              <ButtonComponent
                variant={'outline'}
                margin={'0 10px 0 0'}
                onSubmit={() => {
                  setShowModalEditNegotiationStatus(false);
                }}
              >
                Descartar
              </ButtonComponent>
              <ButtonComponent
                isLoading={loadingEditStatus}
                disabled={loadingEditStatus}
                onSubmit={() => {
                  putCampaignContractNegociationStatus(
                    campaign.data?.id || '',
                    selectedContractId,
                    newNegotiationStatusContract ?? 0
                  )
                    .then(() => {
                      setLoadingEditStatus(true);
                      showToast({
                        toast: toast,
                        status: 'success',
                        title: 'Sucesso',
                        description: 'Status alterado com sucesso !',
                      });
                      setShowModalEditNegotiationStatus(false);
                      campaignContracts.refetch();
                    })
                    .finally(() => {
                      setLoadingEditStatus(false);
                    });
                }}
              >
                Alterar
              </ButtonComponent>
            </Flex>
          }
          children={
            <>
              <InputSelectComponent
                defaultValue={campaignContracts?.data?.data[
                  index
                ]?.negotiationStatus.toString()}
                onChange={(e) => {
                  setNewNegotiationStatus(e.target.value);
                }}
                options={[
                  {
                    id: NegotiationStatus.NotStarted.toString(),
                    name: 'Não iniciado',
                  },
                  {
                    id: NegotiationStatus.InProgress.toString(),
                    name: 'Em andamento',
                  },
                  {
                    id: NegotiationStatus.Canceled.toString(),
                    name: 'Cancelado/Excluído da campanha',
                  },
                  {
                    id: NegotiationStatus.Closed.toString(),
                    name: 'Encerrada',
                  },
                ]}
                placeholder="Selecione o Status"
                label="Status da negociação"
              />
            </>
          }
          size={'sm'}
        />
      </>
    </ModalStructureComponent>
  );
}

export function getColorNegotiationStatus(status: NegotiationStatus) {
  switch (status) {
    case NegotiationStatus.NotStarted:
      return 'gray';
    case NegotiationStatus.InProgress:
      return 'blue';
    case NegotiationStatus.Canceled:
      return 'yellow';
    case NegotiationStatus.Closed:
      return 'red';
  }
}
export function getStatusByType(type: CampaignStatus) {
  switch (type) {
    case CampaignStatus.Ativo:
      return <TagComponent size={'md'} colorScheme={'blue'} text="Ativo" />;
    case CampaignStatus.Cancelado:
      return <TagComponent size={'md'} colorScheme={'red'} text="Cancelado" />;
    default:
      return (
        <TagComponent size={'md'} colorScheme={'green'} text="Finalizado" />
      );
  }
}
