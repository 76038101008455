import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { Flex, useToast } from '@chakra-ui/react';
import { useContractDetails } from '../../../services/ContractService';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import { convertToMonetaryValue } from '../../../utils/functions/masks';
import {
  putEffectstatemenId,
  useTransactionContractId,
  useTransactionContractIdTotalizer,
} from '../../../services/TransactionService';
import AvatarLabelComponent from '../../UI/atoms/AvatarLabelComponent/AvatarLabelComponent';
import { formatDate } from '../../../utils/functions/formatDate';
import { notInfo } from '../../../pages/MyProfilePage';
import { Totalizer } from '../../UI/molecules/Totalizer/Totalizer';
import TagComponent from '../../UI/atoms/TagComponent/TagComponent';
import ModalStructureComponent from '../../UI/molecules/ModalStructureComponent/ModalStructureComponent';
import { FaExclamationCircle } from 'react-icons/fa';
import TextComponent from '../../UI/atoms/TextComponent/TextComponent';
import { useState } from 'react';
import { showToast } from '../../../utils/showToast';

export default function TransactionTemplateComponent() {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const toast = useToast();
  const [showModalLowPayment, setShowModalLowPayment] = useState(false);
  const [isLoadingTrasaction, setIsLoadingTrasaction] = useState(false);
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [trasactionId, setTrasactionId] = useState('');
  const contractInfo = useContractDetails(params.id || '');
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }

  const listTrasactionId = useTransactionContractId(
    params.id || '',
    selectedPage,
    pageSize
  );
  const itens = listTrasactionId.data?.data || [];

  const transactionValues = useTransactionContractIdTotalizer(
    params.id || '',
    selectedPage,
    pageSize
  );

  return (
    <>
      <Flex mb={2} justify={'space-between'}>
        <HeaderTextComponent>
          Transações{' '}
          {params.id && `contrato: ${contractInfo.data?.serialNumber}`}
        </HeaderTextComponent>
        <ButtonComponent
          onSubmit={() => navigate(`/detached-payment/${params.id}`)}
        >
          Pagamentos extras
        </ButtonComponent>
      </Flex>
      <TableComponent
        ItemsHeader={[
          { item: 'Locador' },
          { item: 'Parcela' },
          { item: 'Valor' },
          { item: 'Efetuado' },
          { item: 'Previsão de pagamento' },
          { item: 'Data de pagamento' },
          { item: 'Ação' },
        ]}
        isLoading={listTrasactionId.isLoading}
        centered={true}
        emptyState={listTrasactionId.data?.data.length === 0}
        data={
          listTrasactionId.data?.data.map((e) => ({
            items: [
              <AvatarLabelComponent label={e.locatorName} />,
              e.sequence,
              convertToMonetaryValue(e.value),
              <TagComponent
                size="sm"
                colorScheme={e.effected ? 'green' : 'red'}
                text={e.effected ? 'Sim' : 'Não'}
              />,
              notInfo(formatDate(e.reference), 'não efetuado'),
              notInfo(formatDate(e.effectedAt), 'não efetuado'),
              <Flex>
                <ButtonComponent
                  colorScheme="green"
                  onSubmit={() => {
                    setTrasactionId(e.id);
                    setShowModalLowPayment(true);
                  }}
                >
                  Baixa de pagamento
                </ButtonComponent>
              </Flex>,
            ],
          })) || []
        }
      />
      <Totalizer
        total={transactionValues.data?.data.totalValue ?? 0}
        currentPage={transactionValues.data?.data.currentPageValue ?? 0}
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={itens.length || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <ModalStructureComponent
        isOpen={showModalLowPayment}
        onClose={() => {
          setShowModalLowPayment(false);
        }}
        size={'md'}
        title="Deletar"
        children={
          <>
            <Flex
              mb={5}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <FaExclamationCircle size={50} />
              <TextComponent fontWeight={'bold'} fontSize={'18px'}>
                Atenção
              </TextComponent>
              <TextComponent fontWeight={'light'} fontSize={'14px'}>
                Deseja realmente informar a baixa desse pagamento, essa ação é
                irreversível
              </TextComponent>
            </Flex>
          </>
        }
        footer={
          <Flex mb={6} mr={6} justifyContent={'end'}>
            <ButtonComponent
              variant={'outline'}
              margin={'0px 10px 0px 0px'}
              onSubmit={() => {
                setShowModalLowPayment(false);
              }}
            >
              Cancelar
            </ButtonComponent>
            <ButtonComponent
              isLoading={isLoadingTrasaction}
              onSubmit={() => {
                setIsLoadingTrasaction(true);
                putEffectstatemenId(trasactionId)
                  .then(() => {
                    showToast({
                      toast,
                      status: 'success',
                      title: 'Sucesso',
                      description: 'Baixa de pagamento efetuada com sucesso!',
                    });
                  })
                  .finally(() => {
                    setIsLoadingTrasaction(false);
                    setShowModalLowPayment(false);
                    listTrasactionId.refetch();
                  });
              }}
            >
              Sim
            </ButtonComponent>
          </Flex>
        }
      />
    </>
  );
}
