import { QueryClient, useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';
import { StatusContract } from './ContractService';
import { TotalizerResp } from './TransactionService';

export enum CampaignStatus {
  Ativo = 0,
  Finalizado = 1,
  Cancelado = 2,
}
export enum NegotiationStatus {
  NotStarted = 0,
  InProgress = 1,
  Canceled = 2,
  Closed = 3,
}
export enum CampaignType {
  Readjustment = 0,
  Renewal = 1,
}
export type CampaignsResp = {
  id: string;
  name: string;
  scheduledClosingDate: string;
  createdAt: Date;
  serialNumber: string;
  status: CampaignStatus;
  responsibleId: string;
  responsibleName: string;
  totalValue: number;
  count: number;
  type: CampaignType | undefined;
  typeDescription: string;
  contracts: [
    {
      contractId: string;
      status: StatusContract;
      statusDescription: string;
      activationDate: Date;
      expireDate: Date;
      value: number;
      serialNumber: string;
      locatorName: string;
    }
  ];
  statusDescription: string;
};

export type CammpaignContractsResp = {
  contractId: string;
  status: StatusContract;
  statusDescription: string;
  activationDate: Date;
  expireDate: Date;
  value: number;
  serialNumber: string;
  locatorName: string;
  hasAttendance: boolean;
  attendanceId: string;
  type: number;
  typeDescription: string;
  negotiationStatus: NegotiationStatus;
  negotiationStatusDescription: string;
};

export function useCampaign(
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'ContractSerialNumber'
) {
  return useQuery(
    [`Campaign`, page, pageSize, search, searchField],
    async () => {
      const resp = await Api.get(`/Campaign`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
        },
      });
      return getRespContent<CampaignsResp[]>(resp);
    }
  );
}
export function useCampaignTotalizer(
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'ContractSerialNumber'
) {
  return useQuery(
    [`CampaignTotalizer`, page, pageSize, search, searchField],
    async () => {
      const resp = await Api.get(`/campaign/totalizer`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
        },
      });
      return getRespContent<TotalizerResp>(resp);
    }
  );
}

export function useCampaignDetails(id: string) {
  return useQuery(
    [`CampaignDetails`, id],
    async () => {
      const resp = await Api.get(`/Campaign/${id}`);
      return getResp<CampaignsResp>(resp);
    },
    { enabled: !!id }
  );
}

export function useCampaignContractDetails(
  id: string,
  page: number,
  pageSize: number | null
) {
  return useQuery(
    [`CampaignContractDetails`, id, page],
    async () => {
      const resp = await Api.get(`Campaign/Contracts/${id}`, {
        params: {
          Offset: page,
          Limit: pageSize,
        },
      });
      return getRespContent<CammpaignContractsResp[]>(resp);
    },
    { enabled: !!id }
  );
}
type CampaignHistoriesResp = {
  type: number;
  description: string;
  id: string;
  createdAt: string;
  systemGenerated: boolean;
  typeDescription: string;
  campaignId: string;
  personId: string;
  personName: string;
};

export function useCampaignHistories(
  campaignId: string,
  page: number,
  pageSize: number | null
) {
  return useQuery(
    [`CampaignContractDetails`, campaignId, page, pageSize],
    async () => {
      const resp = await Api.get(`campaign/${campaignId}/histories`, {
        params: {
          Offset: page,
          Limit: pageSize,
        },
      });
      return getRespContent<CampaignHistoriesResp[]>(resp);
    },
    { enabled: !!campaignId }
  );
}

export function saveCampaign(
  name: string,
  scheduledClosingDate: string,
  type: CampaignType | undefined,
  contractIds: string[],
  id?: string
) {
  if (id) {
    return putCampaign(name, scheduledClosingDate, type, contractIds, id);
  }
  return postCampaing(name, scheduledClosingDate, type, contractIds);
}

export function postCampaing(
  name: string,
  scheduledClosingDate: string,
  type: CampaignType | undefined,
  contractIds: string[]
) {
  return Api.post(`/Campaign`, {
    name,
    scheduledClosingDate,
    type,
    contractIds,
  });
}

export function putCampaign(
  name: string,
  scheduledClosingDate: string,
  type: CampaignType | undefined,
  contractIds: string[],
  id: string | undefined
) {
  return Api.put(`/Campaign/${id}`, {
    name,
    scheduledClosingDate,
    type,
    contractIds,
  });
}

export function putCampaignCancel(id: string | undefined) {
  return Api.put(`/Campaign/${id}/cancel`);
}

export function putCampaignFinish(id: string | undefined) {
  return Api.put(`/Campaign/${id}/finish`);
}

export function putCampaignCancelAllNegotiations(id: string | undefined) {
  return Api.put(`/Campaign/${id}/cancel-all-negotiations`);
}
export function putCampaignContractNegociationStatus(
  campaignId: string,
  contractId: string,
  status: NegotiationStatus
) {
  return Api.put(
    `/Campaign/${campaignId}/contract-negotiation-status/${contractId}/${status}`
  );
}
export const PutCampaignOpenAttendance = async (
  campaignId: string | undefined,
  contractId: string | undefined
) => {
  const response = await Api.put(
    `/Campaign/${campaignId}/open-attendance/${contractId}`
  );
  return getRespContent<{ attendanceId: string }>(response);
};

export function invalidateCampaign(
  attendanceId: string,
  queryClient: QueryClient
) {
  queryClient.invalidateQueries({
    queryKey: [`CampaignDetails`, attendanceId],
  });
}
