import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { Flex, useToast } from '@chakra-ui/react';
import React from 'react';
import { useDebounce } from '../../../utils/functions/debounce';
import { parseDateAndHour } from '../../../utils/functions/utility';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import { showToast } from '../../../utils/showToast';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ContractHistoryResp,
  ContractHistoryType,
  deleteContractHistory,
  useContractHistorByContract,
  useContractHistory,
} from '../../../services/ContractHistoryService';
import { CreateContractHistoryModalComponent } from '../../UI/organisms/CreateContractHistoryModalComponent/CreateContractHistoryModalComponent';
import { convertToMonetaryValue } from '../../../utils/functions/masks';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import { formatDate } from '../../../utils/functions/formatDate';

export default function ContractHistoryTemplateComponent() {
  const params = useParams<{ id: string }>();
  const toast = useToast();
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [search] = useDebounce('');
  const [searchField] = React.useState('description');
  const [showModal, setShowModal] = React.useState(false);
  const [selectedHistory, setSelectedHistory] = React.useState<
    ContractHistoryResp | undefined
  >();
  const listContractHistory = useContractHistory(
    !params.id,
    selectedPage,
    pageSize,
    search,
    searchField
  );

  const listContractHistoryByContract = useContractHistorByContract(
    !!params.id,
    params.id,
    selectedPage,
    pageSize,
    search,
    searchField
  );

  const navigate = useNavigate();

  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }

  return (
    <>
      <Flex mb={2} justifyContent={'space-between'}>
        <HeaderTextComponent>Histórico de Contratos</HeaderTextComponent>
        <Flex>
          <ButtonComponent
            margin={'0 10px 0 0'}
            disabled={!params.id}
            onSubmit={() => {
              navigate(`/negotiation-history/${params.id}`);
            }}
          >
            Negociações criadas
          </ButtonComponent>
          <ButtonComponent
            margin={'0 10px 0 0'}
            disabled={!params.id}
            onSubmit={() => {
              setSelectedHistory(undefined);
              setShowModal(true);
            }}
          >
            Novo
          </ButtonComponent>
        </Flex>

        {/* <SearchSelectComponent
            onChangeText={(input) => setSearch(input.target.value)}
            inputValue={searchInput}
            onClean={() => setSearch('')}
            onChange={(item) => {
              setSearchField(item.target.value);
            }}
            options={[{ id: 'description', name: 'Descrição' }]}
          /> */}
      </Flex>

      <TableComponent
        ItemsHeader={[
          { item: 'Contrato' },
          { item: 'Data de alteração' },
          { item: 'Descrição' },
          { item: 'Usuário' },
          { item: 'Valor Anterior' },
          { item: 'Novo Valor' },
          { item: 'Ações' },
        ]}
        isLoading={
          !listContractHistory.data && !listContractHistoryByContract.data
        }
        centered={true}
        emptyState={
          (listContractHistory.data || listContractHistoryByContract.data)
            ?.metadata.dataSize === 0
        }
        data={
          (
            listContractHistory.data || listContractHistoryByContract.data
          )?.data.map((e, i) => ({
            items: [
              e.contractSerialNumber,
              parseDateAndHour(e.createdAt),
              e.description,
              e.personName ?? 'Sem usuário',
              getInfoOldandNewValue(e.type, e.oldValue),
              getInfoOldandNewValue(e.type, e.newValue),

              <>
                <ButtonComponent
                  disabled={e.systemGenerated}
                  onSubmit={() => {
                    setSelectedHistory(e);
                    setShowModal(true);
                  }}
                >
                  Editar
                </ButtonComponent>
                <ButtonComponent
                  colorScheme={'red'}
                  margin={'0 0 0 10px'}
                  disabled={e.systemGenerated}
                  onSubmit={() => {
                    deleteContractHistory(e.id).then((res) => {
                      showToast({
                        toast,
                        status: 'success',
                        title: 'Sucesso',
                        description:
                          'Histórico do contrato deletado com sucesso',
                      });
                      listContractHistory.data
                        ? listContractHistory.refetch()
                        : listContractHistoryByContract.refetch();
                    });
                  }}
                >
                  Deletar
                </ButtonComponent>
              </>,
            ],
          })) || []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={
          (listContractHistory.data || listContractHistoryByContract.data)
            ?.metadata.dataSize || 0
        }
        maxPageItens={pageSize}
      ></PaginationComponent>
      <CreateContractHistoryModalComponent
        contractId={params.id || ''}
        selectedContract={selectedHistory}
        showModal={showModal}
        setShowModal={() => setShowModal(false)}
        reloadData={() =>
          listContractHistory.data
            ? listContractHistory.refetch()
            : listContractHistoryByContract.refetch()
        }
      />
    </>
  );
}
function getInfoOldandNewValue(
  type: ContractHistoryType,
  value: string | number
) {
  if (type === ContractHistoryType.DATE) {
    return formatDate(value);
  }
  if (type === ContractHistoryType.VALUE) {
    return convertToMonetaryValue(Number(value));
  } else {
    return value;
  }
}
