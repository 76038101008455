import {
  Flex,
  GridItem,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FaBuffer } from 'react-icons/fa';
import {
  ContractResp,
  StatusContract,
  usePendingFilds,
} from '../../../../services/ContractService';
import { monthDiff } from '../../../../utils/functions/utility';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import { ContractStatusTag } from '../../../templates/ContractTemplateComponent/ContractTemplateComponent';
import AvatarLabelComponent from '../../atoms/AvatarLabelComponent/AvatarLabelComponent';
import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';
import ContainerComponent from '../../atoms/ContainerComponent/ContainerComponent';
import { useUserData } from '../../../../services/LoginService';
import { convertToMonetaryValue } from '../../../../utils/functions/masks';
import { formatDate } from '../../../../utils/functions/formatDate';
import React from 'react';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import { LabelInfoDetails, notInfo } from '../../../../pages/MyProfilePage';
import TableComponent from '../../molecules/TableComponent/TableComponent';
import { useNavigate } from 'react-router-dom';

type ContractDetailsInfoComponentProps = {
  contract?: ContractResp;
  onClick: () => void;
  onSubmitHistoryButton?: () => void;
  onSubmitTransactionsButton?: () => void;
  onSubmitAttendanceButton?: () => void;
  onSubmitSigningButton?: () => void;
  onSubmitGeneratePDFButton?: () => void;
};

export function ContractDetailsInfoComponent(
  props: ContractDetailsInfoComponentProps
) {
  const colSpanOnBoarding = useBreakpointValue({ base: 12, sm: 8 });
  const user = useUserData();
  const [showModalIPTU, setShowModalIPTU] = React.useState(false);
  const [showModalDates, setShowModalDates] = React.useState(false);
  const [showModalRegional, setShowModalRegional] = React.useState(false);
  const [showModalFilds, setShowModalFilds] = React.useState(false);
  const navigate = useNavigate();

  return (
    <GridItem
      display={'flex'}
      flexDirection={'column'}
      colSpan={colSpanOnBoarding}
      padding={'20px'}
      paddingTop={'0px'}
      height={{ base: '100%', sm: '100vh' }}
      justifyContent={!props.contract ? 'center' : undefined}
      alignItems={!props.contract ? 'center' : undefined}
    >
      {!props.contract ? (
        <Spinner size={'xl'} color="brand.500"></Spinner>
      ) : (
        <>
          <Flex width={'100%'} justifyContent={'space-between'}>
            <HeaderTextComponent onClick={props.onClick} goBack>
              Contrato
            </HeaderTextComponent>
          </Flex>
          <ContainerComponent
            background={'white'}
            width={'100%'}
            borderRadius={'10px'}
            padding={'18px'}
            boxShadow={'lg'}
          >
            <Flex mb={4} justifyContent={'space-between'}>
              <TextComponent as={'b'} fontSize={'3xl'}>
                Partes
              </TextComponent>
            </Flex>
            <Flex justifyContent={'space-between'}>
              <LabelInfoDetails
                label="Número do contrato"
                details={props.contract?.serialNumber}
              />
              <Flex alignItems={'flex-start'} w={300}>
                <AvatarLabelComponent
                  label={notInfo(
                    props.contract?.intermediary?.name,
                    'Não cadastrado'
                  )}
                  subLabel={'Intermediário'}
                  src={props.contract?.intermediary?.profilePicUrl}
                />
              </Flex>
              {props.contract?.relatedEntity && (
                <Flex alignItems={'flex-start'} w={300}>
                  <AvatarLabelComponent
                    label={notInfo(
                      props.contract?.relatedEntity?.name,
                      'Não cadastrado'
                    )}
                    subLabel={'Entidade Relacionada'}
                  />
                </Flex>
              )}

              {props.contract?.locators.map((locator, i) => (
                <Flex flexDirection={'column'} alignItems={'center'} w={300}>
                  <AvatarLabelComponent
                    key={locator.locatorId}
                    label={locator.name}
                    subLabel={`Locador | Cota: ${locator.percentage}%`}
                    src={locator.profilePicUrl}
                  />
                  <Flex>
                    <Text
                      noOfLines={1}
                      fontSize={12}
                      size={'sm'}
                      color={'gray'}
                    >
                      {' '}
                      Procurador: {locator.attorney?.name}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
            <Flex justifyContent={'space-between'}>
              <LabelInfoDetails
                label="Índice"
                details={props.contract?.indexDescription}
              />
              <LabelInfoDetails
                label="Valor do contrato"
                details={convertToMonetaryValue(props.contract?.value)}
              />
              <LabelInfoDetails
                label="M²"
                details={props.contract?.area.toString()}
              />
            </Flex>
            <Flex justifyContent={'space-between'}>
              <LabelInfoDetails
                label="Tipo de engenharia"
                details={props.contract?.engineeringType?.description}
              />
              <LabelInfoDetails
                label="Tipo de torre"
                details={props.contract?.towerType?.description}
              />
              <LabelInfoDetails
                label="Tipo de equipamento"
                details={props.contract?.equipment?.description}
              />
            </Flex>
            <Flex justifyContent={'space-between'}>
              <LabelInfoDetails
                label="Tipo de Tecnologia"
                details={props.contract?.technology?.description}
              />
              <LabelInfoDetails
                label="AR"
                details={props.contract?.hasCampaign ? 'Sim' : 'Não'}
              />
              <Flex alignItems={'flex-start'} w={300}>
                <AvatarLabelComponent
                  label={user?.entity?.name}
                  subLabel={'Locatário'}
                  src={user?.entity?.entityImage}
                />
              </Flex>
            </Flex>

            <Flex justifyContent={'space-between'}>
              <LabelInfoDetails
                label="Número de renovações"
                details={props.contract?.numberOfRenewals?.toString() ?? '-'}
              />
              <LabelInfoDetails
                label="Renovação automática"
                details={props.contract?.automaticRenovation ? 'Sim' : 'Não'}
              />
              <LabelInfoDetails
                label="Reajuste automático"
                details={props.contract?.automaticReadjustment ? 'Sim' : 'Não'}
              />
            </Flex>

            <Flex justifyContent={'space-between'}>
              <LabelInfoDetails
                label="Origem"
                details={props.contract?.origin}
              />
              <LabelInfoDetails label="Dados" details={props.contract?.data} />
              <LabelInfoDetails
                label="Comentários"
                details={props.contract?.comments}
              />
            </Flex>

            <Flex flexDir={'column'}>
              <Flex mb={4}>
                <TextComponent as={'b'} fontSize={'3xl'}>
                  Informações gerais
                </TextComponent>
              </Flex>

              <Flex w={'100%'} justifyContent={'space-between'}>
                <ButtonComponent
                  variant={'ghost'}
                  onSubmit={() => props.onSubmitAttendanceButton?.()}
                >
                  Atendimento
                </ButtonComponent>
                <ButtonComponent
                  margin={'0 10px 0 0'}
                  variant={'ghost'}
                  onSubmit={() => {
                    setShowModalIPTU(true);
                  }}
                >
                  Informações de IPTU
                </ButtonComponent>
                <ButtonComponent
                  margin={'0 10px 0 0'}
                  variant={'ghost'}
                  onSubmit={() => {
                    setShowModalDates(true);
                  }}
                >
                  Informações de datas
                </ButtonComponent>
                <ButtonComponent
                  margin={'0 10px 0 0'}
                  variant={'ghost'}
                  onSubmit={() => {
                    setShowModalRegional(true);
                  }}
                >
                  Informações da regional
                </ButtonComponent>
                <ButtonComponent
                  variant={'ghost'}
                  onSubmit={() => {
                    navigate(`/contract-change/${props.contract?.id}`);
                  }}
                >
                  Pedidos de alteração
                </ButtonComponent>
              </Flex>
            </Flex>

            <Flex mt={12} justifyContent={'space-between'}>
              <Flex mb={4}>
                <TextComponent mr={4} as={'b'} fontSize={'3xl'}>
                  Vigência
                </TextComponent>

                <ContractStatusTag
                  type={
                    props.contract.contractStatus ||
                    StatusContract.EmCadastramento
                  }
                  typeDescription={
                    props.contract.contractStatusDescription || ''
                  }
                />
              </Flex>
              <ButtonComponent
                variant={'ghost'}
                onSubmit={() => props.onSubmitHistoryButton?.()}
                leftIcon={<FaBuffer />}
              >
                Histórico
              </ButtonComponent>
            </Flex>
            <Flex justifyContent={'space-between'}>
              <LabelInfoDetails
                label="Assinado em"
                details={
                  props.contract.contractStatus !== StatusContract.Assinado
                    ? 'não assinado'
                    : formatDate(props.contract?.activationDate)
                }
              />
              <LabelInfoDetails
                label="Valido até"
                details={formatDate(props.contract?.signatureDate)}
              />
              <LabelInfoDetails
                label="Prazo para vencimento"
                details={monthDiff(
                  new Date(props.contract.activationDate || ''),
                  new Date(props.contract.expireDate || '')
                )}
              />
              <LabelInfoDetails
                label="Renovação Automática"
                details={
                  props.contract?.automaticRenovation ? 'Ativo' : 'Inativo'
                }
              />
            </Flex>

            <Flex justifyContent={'space-between'}>
              <TextComponent as={'b'} fontSize={'3xl'}>
                Transações
              </TextComponent>
              <ButtonComponent
                variant={'ghost'}
                onSubmit={() => props.onSubmitTransactionsButton?.()}
                leftIcon={<FaBuffer />}
              >
                Todas as Transações
              </ButtonComponent>
            </Flex>
            <Flex
              width={'100%'}
              justifyContent={'space-between'}
              marginTop={'25px'}
              marginBottom={'40px'}
            >
              <Stack spacing={'10px'}>
                <TextComponent fontWeight={'bold'}>Último mês</TextComponent>
                <TextComponent fontSize={'lg'} fontWeight={'bold'}>
                  {convertToMonetaryValue(
                    props.contract.transactions.lastMonth
                  )}
                </TextComponent>
              </Stack>
              <Stack spacing={'10px'}>
                <TextComponent fontWeight={'bold'}>Último ano</TextComponent>
                <TextComponent fontSize={'lg'} fontWeight={'bold'}>
                  {convertToMonetaryValue(props.contract.transactions.lastYear)}
                </TextComponent>
              </Stack>
              <Stack spacing={'10px'}>
                <TextComponent fontWeight={'bold'}>Total</TextComponent>
                <TextComponent fontSize={'lg'} fontWeight={'bold'}>
                  {convertToMonetaryValue(
                    props.contract.transactions.totalValue
                  )}
                </TextComponent>
                <TextComponent color={'gray.500'}>
                  {props.contract.transactions.lastTransactionUpdate
                    ? `Última transação em ${formatDate(
                        props.contract.transactions.lastTransactionUpdate
                      )}`
                    : 'Sem transações'}
                </TextComponent>
              </Stack>
            </Flex>
            <Flex justifyContent={'center'} width={'100%'}>
              {!props.contract.hasPDFContract ? (
                <ButtonComponent
                  margin={'10px  0 0 0'}
                  width={'50%'}
                  onSubmit={() => props.onSubmitGeneratePDFButton?.()}
                >
                  Gerar documento em pdf
                </ButtonComponent>
              ) : (
                <>
                  {props.contract.hasPendingFields && (
                    <ButtonComponent
                      variant={'outline'}
                      colorScheme="red"
                      margin={'10px  10px 0 0'}
                      width={'50%'}
                      onSubmit={() => setShowModalFilds(true)}
                    >
                      Dados pendentes para assinatura
                    </ButtonComponent>
                  )}

                  <ButtonComponent
                    variant={'outline'}
                    margin={'10px  10px 0 0'}
                    width={'50%'}
                    disabled={props.contract.hasPendingFields}
                    onSubmit={() => props.onSubmitSigningButton?.()}
                  >
                    Enviar para assinatura de contratos
                  </ButtonComponent>
                </>
              )}
            </Flex>
          </ContainerComponent>
          <ModalStructureComponent
            isOpen={showModalIPTU}
            onClose={() => setShowModalIPTU(false)}
            title={`Informações de IPTU`}
            maxW="900px"
            size="xl"
          >
            <Flex mb={5} justifyContent={'space-between'}>
              <Stack spacing={'10px'}>
                <TextComponent fontWeight={'bold'}>
                  Matrícula IPTU
                </TextComponent>
                <TextComponent color={'gray.500'}>
                  {props.contract.iptuRegistration}
                </TextComponent>
              </Stack>
              <Stack spacing={'10px'}>
                <TextComponent fontWeight={'bold'}>
                  Pagamento de IPTU
                </TextComponent>
                <TextComponent color={'gray.500'}>
                  {props.contract.iptuPayment ? 'Sim' : 'Não'}
                </TextComponent>
              </Stack>
              <Stack spacing={'10px'}>
                <TextComponent fontWeight={'bold'}>Valor IPTU</TextComponent>
                <TextComponent color={'gray.500'}>
                  {convertToMonetaryValue(props.contract.iptuValue)}
                </TextComponent>
              </Stack>
            </Flex>
            <Flex mb={5} justifyContent={'center'}>
              <ButtonComponent
                onSubmit={() => {
                  setShowModalIPTU(false);
                }}
              >
                Fechar
              </ButtonComponent>
            </Flex>
          </ModalStructureComponent>
          <ModalStructureComponent
            isOpen={showModalDates}
            onClose={() => setShowModalDates(false)}
            title={`Informações de datas`}
            maxW="900px"
            size="xl"
          >
            <Flex flexDirection={'column'} mb={5}>
              <Flex mb={4} justifyContent={'space-between'}>
                <LabelInfoDetails
                  label="Data de ativação"
                  details={formatDate(props.contract.activationDate)}
                />
                <LabelInfoDetails
                  label="Data de início"
                  details={formatDate(props.contract.startDate)}
                />
                <LabelInfoDetails
                  label="Data de vencimento"
                  details={formatDate(props.contract.expireDate)}
                />
              </Flex>
              <Flex justifyContent={'space-between'}>
                <LabelInfoDetails
                  label="Data base de reajuste"
                  details={formatDate(props.contract.baseReadjustmentDate)}
                />

                <LabelInfoDetails
                  label="Data primeiro pagamento"
                  details={formatDate(props.contract.firstPaymentDate)}
                />
                <LabelInfoDetails
                  label="Periodicidade de pagamento"
                  details={notInfo(props.contract.frequencyDescription)}
                />
              </Flex>
              <Flex>
                <LabelInfoDetails
                  label="Data de instalação"
                  details={formatDate(props.contract.installationDate)}
                />
                <LabelInfoDetails
                  label="Data de assinatura"
                  details={formatDate(props.contract.signatureDate)}
                />
                <LabelInfoDetails
                  label="Pag. Subsequente ou Adiantado"
                  details={
                    props.contract.advancePayment
                      ? 'Adiantado'
                      : 'Mês subsequente'
                  }
                />
              </Flex>
            </Flex>
            <Flex mb={5} justifyContent={'center'}>
              <ButtonComponent
                onSubmit={() => {
                  setShowModalDates(false);
                }}
              >
                Fechar
              </ButtonComponent>
            </Flex>
          </ModalStructureComponent>
          <ModalStructureComponent
            isOpen={showModalRegional}
            onClose={() => setShowModalRegional(false)}
            title={`Informações da regional`}
            maxW="900px"
            size="xl"
          >
            <Flex mb={5} justifyContent={'space-between'}>
              <Stack spacing={'10px'}>
                <TextComponent fontWeight={'bold'}>ID Regional</TextComponent>
                <TextComponent color={'gray.500'}>
                  {props.contract.regionalId}
                </TextComponent>
              </Stack>
              <Stack spacing={'10px'}>
                <TextComponent fontWeight={'bold'}>ID Cluster</TextComponent>
                <TextComponent color={'gray.500'}>
                  {props.contract.clusterId}
                </TextComponent>
              </Stack>
            </Flex>
            <Flex mb={5} justifyContent={'center'}>
              <ButtonComponent
                onSubmit={() => {
                  setShowModalRegional(false);
                }}
              >
                Fechar
              </ButtonComponent>
            </Flex>
          </ModalStructureComponent>
          <ModalStructureComponent
            isOpen={showModalFilds}
            size={'xl'}
            title="Campos pendentes"
            onClose={() => {
              setShowModalFilds(false);
            }}
            children={<TablePendingFilds id={props.contract.id} />}
          />
        </>
      )}
    </GridItem>
  );
}
export function TablePendingFilds(props: { id: string }) {
  const ListPendingFilds = usePendingFilds(props.id);
  return (
    <TableComponent
      isLoading={ListPendingFilds.isLoading}
      emptyState={ListPendingFilds.data?.data.length === 0}
      data={
        ListPendingFilds?.data?.data.map((e) => ({
          items: [e],
        })) || []
      }
      ItemsHeader={[{ item: 'Campos' }]}
    />
  );
}
