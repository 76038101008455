import { Flex } from '@chakra-ui/react';
import ModalStructureComponent from '../molecules/ModalStructureComponent/ModalStructureComponent';
import { Text } from '../../../stories/atoms/TextComponent.stories';
import {
  CreateNegotiation,
  EditNegotiation,
  NegotiationHistoryResp,
  NegotiationHistoryStatus,
  invalidateNegotiationHistory,
  useNegotiationCurrentDataPreview,
} from '../../../services/NegotiationHistory';
import { formatDate } from '../../../utils/functions/formatDate';
import {
  clearMoneyMask,
  convertToMonetaryValue,
  moneyMask,
} from '../../../utils/functions/masks';
import { IndexToString } from '../../templates/NegotiationHistoryTemplateComponent/NegotiationHistoryTemplateComponent';
import InputComponent from '../atoms/InputComponent/InputComponent';
import { useEffect, useState } from 'react';
import InputSelectComponent from '../atoms/InputSelectComponent/InputSelectComponent';
import ButtonComponent from '../atoms/ButtonComponent/ButtonComponent';
import { ReadjustmentIndex } from '../../../services/ReadjustmentIndexService';
import { useQueryClient } from '@tanstack/react-query';
import HeaderTextComponent from '../atoms/HeaderTextComponent/HeaderTextComponent';
import { useContractDetails } from '../../../services/ContractService';

export default function CreateNegotiationHistoryModal(props: {
  negotiationHistorySelectedId: string;
  negotiationHistorySelectedData?: NegotiationHistoryResp;
  showModal: boolean;
  setShowModal: () => void;
  contractId: string;
  reloadData?: () => void;
}) {
  const queryClient = useQueryClient();
  const contract = useContractDetails(props.contractId);
  const [deadlineInMonthsInput, setDeadlineInMonthsInput] = useState('');
  const [totalValueInput, setTotalValueInput] = useState('');
  const [readjustmentIndexInput, setReadjustmentIndexInput] =
    useState<ReadjustmentIndex>();
  const [startingReferenceInput, setStartingReferenceInput] = useState('');
  const [endingReferenceInput, setEndingReferenceInput] = useState('');
  const [descriptionInput, setDescriptionInput] = useState('');
  const [statusInput, setStatusInput] = useState<NegotiationHistoryStatus>();
  const [loadingSave, setLoadingSave] = useState(false);
  const disabledSave =
    !deadlineInMonthsInput ||
    !totalValueInput ||
    !readjustmentIndexInput ||
    !startingReferenceInput ||
    !endingReferenceInput;

  const readjustmentIndexOptions = [
    {
      id: ReadjustmentIndex.IPCA.toString(),
      name: 'IPCA',
    },
    {
      id: ReadjustmentIndex.IPCAE.toString(),
      name: 'IPCAE',
    },
    {
      id: ReadjustmentIndex.IPCDI.toString(),
      name: 'IPCDI',
    },
    {
      id: ReadjustmentIndex.INPC.toString(),
      name: 'INPC',
    },
    {
      id: ReadjustmentIndex.IGPM.toString(),
      name: 'IGPM',
    },
    {
      id: ReadjustmentIndex.IGPDI.toString(),
      name: 'IGPDI',
    },
    {
      id: ReadjustmentIndex.SELIC.toString(),
      name: 'SELIC',
    },
  ];
  const statusOptions = [
    {
      id: NegotiationHistoryStatus.Negotiation.toString(),
      name: 'Em Andamento',
    },
    {
      id: NegotiationHistoryStatus.Open.toString(),
      name: 'Aberto',
    },
    {
      id: NegotiationHistoryStatus.Finished.toString(),
      name: 'Encerrado',
    },
  ];
  const defaultValue = props.negotiationHistorySelectedData;
  function formatYearMonthDay(dateInput?: string): string | undefined {
    if (!dateInput) {
      return undefined;
    }

    const date = new Date(dateInput);

    if (isNaN(date.getTime())) {
      throw new Error('Invalid date input');
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
  function clearFields() {
    setDeadlineInMonthsInput('');
    setTotalValueInput('');
    setStartingReferenceInput('');
    setEndingReferenceInput('');
    setReadjustmentIndexInput(undefined);
    setDescriptionInput('');
    setStatusInput(undefined);
  }
  function addMonths(months: number, initialDate: string): string {
    const date = new Date(initialDate);
    console.log(date);
    date.setMonth(date.getMonth() + months);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    console.log({ date });

    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    if (props.negotiationHistorySelectedId) {
      setDeadlineInMonthsInput(defaultValue?.deadlineInMonths.toString() || '');
      setTotalValueInput(convertToMonetaryValue(defaultValue?.totalValue ?? 0));
      setReadjustmentIndexInput(defaultValue?.readjustmentIndex || undefined);
      setStartingReferenceInput(
        formatYearMonthDay(defaultValue?.startingReference.toString()) || ''
      );
      setEndingReferenceInput(
        formatYearMonthDay(defaultValue?.endingReference.toString()) || ''
      );
      setDescriptionInput(defaultValue?.description || '');
      setStatusInput(defaultValue?.status);
    } else {
      clearFields();
    }
  }, [defaultValue, props.negotiationHistorySelectedId]);

  const listNegotiationHistoryCurrentDataPreview =
    useNegotiationCurrentDataPreview(disabledSave, props.contractId, {
      deadlineInMonths: Number(deadlineInMonthsInput),
      endingReference: endingReferenceInput,
      readjustmentIndex: readjustmentIndexInput,
      startingReference: startingReferenceInput,
      totalValue: Number(clearMoneyMask(totalValueInput)),
    }).data?.data;
  useEffect(() => {
    setEndingReferenceInput(
      addMonths(Number(deadlineInMonthsInput), startingReferenceInput)
    );
  }, [deadlineInMonthsInput, startingReferenceInput]);
  useEffect(() => {});

  function saveNegotiation() {
    setLoadingSave(true);
    if (!props.negotiationHistorySelectedId) {
      CreateNegotiation(props.contractId, {
        deadlineInMonths: Number(deadlineInMonthsInput),
        totalValue: Number(clearMoneyMask(totalValueInput)),
        startingReference: startingReferenceInput,
        endingReference: endingReferenceInput,
        readjustmentIndex: Number(readjustmentIndexInput),
        description: descriptionInput,
        status: statusInput,
      })
        .then((res) => {
          invalidateNegotiationHistory(props.contractId, queryClient);
          props.setShowModal();
        })
        .finally(() => {
          setLoadingSave(false);
        });
    } else {
      EditNegotiation(props.negotiationHistorySelectedId, {
        deadlineInMonths: Number(deadlineInMonthsInput),
        totalValue: Number(clearMoneyMask(totalValueInput)),
        startingReference: startingReferenceInput,
        endingReference: endingReferenceInput,
        readjustmentIndex: Number(readjustmentIndexInput),
        description: descriptionInput,
        status: statusInput,
      })
        .then((res) => {
          invalidateNegotiationHistory(props.contractId, queryClient);
          props.setShowModal();
        })
        .finally(() => {
          setLoadingSave(false);
        });
    }
  }
  const percentageValue = Number(
    listNegotiationHistoryCurrentDataPreview?.readjustmentIndexPercentage
  );
  return (
    <ModalStructureComponent
      footer={
        <>
          <Flex p={4}>
            <ButtonComponent
              margin={'0 10px 0 0'}
              variant={'outline'}
              onSubmit={() => {
                props.setShowModal();
                clearFields();
              }}
            >
              Descartar
            </ButtonComponent>

            <ButtonComponent
              disabled={disabledSave || !statusInput || loadingSave}
              isLoading={loadingSave}
              onSubmit={() => {
                saveNegotiation();
              }}
            >
              Salvar
            </ButtonComponent>
          </Flex>
        </>
      }
      isOpen={props.showModal}
      onClose={() => {
        props.setShowModal();
        clearFields();
      }}
      title={
        props.negotiationHistorySelectedId
          ? 'Detalhes da Negociação'
          : 'Nova Negociação'
      }
      maxW="1200px"
      size="lg"
    >
      <>
        <HeaderTextComponent mb={2} subTitle fontSize={20}>
          Dados do contrato
        </HeaderTextComponent>
        <Flex mb={4}>
          <InputComponent
            disabled
            label={`Data de inicio`}
            type="date"
            value={contract.data?.startDate.toString().substring(0, 10)}
            onChange={(input) => {}}
          />
          <InputComponent
            margin={'0 10px 0 10px'}
            disabled={true}
            label={`Data final`}
            type="date"
            value={contract.data?.expireDate.toString().substring(0, 10)}
            onChange={(input) => {}}
          />
          <InputComponent
            disabled
            label={`Valor total`}
            placeholder="digite o valor total"
            type="text"
            value={convertToMonetaryValue(contract.data?.value ?? 0)}
            onChange={(input) => {}}
          />
        </Flex>
        <Flex mb={12}>
          <InputSelectComponent
            disabled
            label={`Índice de reajuste`}
            placeholder="selecione o indice"
            value={contract.data?.index.toString()}
            options={readjustmentIndexOptions}
            onChange={(input) => {}}
          />
        </Flex>
        <HeaderTextComponent mb={2} subTitle fontSize={20}>
          Preencha os dados para criar um nova negociação
        </HeaderTextComponent>
        <Flex mb={4}>
          <InputComponent
            label={`Prazo em meses`}
            placeholder="digite o prazo"
            type="number"
            value={deadlineInMonthsInput}
            onChange={(input) => {
              setDeadlineInMonthsInput(input.target.value);
            }}
          />
          <InputComponent
            margin={'0 10px 0 10px'}
            label={`Data de inicio`}
            type="date"
            value={startingReferenceInput}
            onChange={(input) => {
              setStartingReferenceInput(input.target.value);
            }}
          />
          <InputComponent
            disabled={true}
            label={`Data final`}
            type="date"
            value={endingReferenceInput}
            onChange={(input) => {
              setEndingReferenceInput(input.target.value);
            }}
          />
        </Flex>
        <Flex mb={4}>
          <InputComponent
            margin={'0 10px 0 0px'}
            label={`Valor total`}
            placeholder="digite o valor total"
            type="text"
            value={totalValueInput}
            onChange={(input) => {
              setTotalValueInput(moneyMask(input.target.value));
            }}
          />
          <InputSelectComponent
            label={`Índice de reajuste`}
            placeholder="selecione o indice"
            value={String(readjustmentIndexInput)}
            options={readjustmentIndexOptions}
            onChange={(input) => {
              setReadjustmentIndexInput(Number(input.target.value));
            }}
          />
          <InputSelectComponent
            m={'0 0 0 10px'}
            label={`Status da negociação`}
            placeholder="selecione o status"
            value={String(statusInput)}
            options={statusOptions}
            onChange={(input) => {
              setStatusInput(Number(input.target.value));
            }}
          />
        </Flex>
        <Flex mb={8}>
          <InputComponent
            margin={'0 10px 0 0px'}
            label={`Descrição`}
            placeholder="digite a descrição "
            type="textArea"
            value={descriptionInput}
            onChange={(input) => {
              setDescriptionInput(input.target.value);
            }}
          />
        </Flex>

        <Flex display={'flex'} alignItems={'center'} direction={'column'}>
          <Flex mb={4} direction={'row'}>
            <CardDetails
              title={`PRAZO CONTRATO - MESES`}
              value={`${String(
                listNegotiationHistoryCurrentDataPreview?.deadlineOfContractInMonths ||
                  ''
              )} ${
                listNegotiationHistoryCurrentDataPreview?.deadlineOfContractInMonths
                  ? 'meses'
                  : '-'
              }  `}
            />
            <CardDetails
              title="DATA NOVA / INICIO - FIM"
              value={`${formatDate(
                listNegotiationHistoryCurrentDataPreview?.startingReference
              )} - ${formatDate(
                listNegotiationHistoryCurrentDataPreview?.endingReference
              )}`}
            />
            <CardDetails
              title="VALOR TOTAL"
              value={convertToMonetaryValue(
                listNegotiationHistoryCurrentDataPreview?.value ?? 0
              )}
            />
            <CardDetails
              title="INDICE"
              value={IndexToString(
                listNegotiationHistoryCurrentDataPreview?.readjustmentIndexOption
              )}
            />
          </Flex>
          <Flex direction={'row'}>
            <CardDetails
              title="M2"
              value={String(
                listNegotiationHistoryCurrentDataPreview?.area || '-'
              )}
            />
            <CardDetails
              title="VARIAÇÃO DE VALOR EM %"
              value={`${!percentageValue ? '0' : percentageValue.toFixed(2)}%`}
            />
            <CardDetails
              title="VALOR POR M2"
              value={convertToMonetaryValue(
                listNegotiationHistoryCurrentDataPreview?.valuePerArea ?? 0
              )}
            />
            <CardDetails
              title="VALOR MENSAL"
              value={convertToMonetaryValue(
                listNegotiationHistoryCurrentDataPreview?.monthValue ?? 0
              )}
            />
          </Flex>
        </Flex>
      </>

      {/* <Flex display={'flex'} alignItems={'center'} direction={'column'}>
        <Flex mb={5} direction={'row'}>
          <CardDetails
            title={`VALOR FECHADO`}
            value={convertToMonetaryValue(
              createNegotiationResp?.finalValue ?? 0
            )}
          />
          <CardDetails
            title="VARIAÇÃO DE VALOR EM R$"
            value={convertToMonetaryValue(
              createNegotiationResp?.valueVariation ?? 0
            )}
          />
          <CardDetails
            title="VARIAÇÃO DE VALOR EM %"
            value={`${String(
              createNegotiationResp?.valueVariationPercentage
            )}%`}
          />
          <CardDetails
            title="VARIAÇÃO DE VALOR EM R$"
            titleLineTwo="(12 MESES)"
            value={convertToMonetaryValue(
              createNegotiationResp?.yearValueVariation ?? 0
            )}
          />
        </Flex>
        <Flex direction={'row'}>
          <CardDetails
            title="VARIAÇÃO TOTAL DO CONTRATO"
            value={convertToMonetaryValue(
              createNegotiationResp?.finalValue ?? 0
            )}
          />
          <CardDetails
            title="VALOR POR M2"
            value={convertToMonetaryValue(
              createNegotiationResp?.valuePerArea ?? 0
            )}
          />
          <CardDetails
            title="INDICE"
            value={`${IndexToString(
              listNegotiationHistoryData?.readjustmentIndexOption
            )}`}
          />
        </Flex>
      </Flex> */}
    </ModalStructureComponent>
  );
}

function CardDetails(props: {
  width?: number | string;
  title: string;
  value: string;
  titleLineTwo?: string;
}) {
  return (
    <Flex marginRight={1} marginLeft={1} direction={'column'}>
      <Flex
        display={'flex'}
        align={'center'}
        justifyContent={'center'}
        width={props.width ?? '200px'}
        height={'40px'}
        backgroundColor={'brand.500'}
        borderTopRadius={8}
        direction={'column'}
      >
        <Text fontWeight={'bold'} fontSize={12} color={'#FFF'}>
          {props.title}
        </Text>
        {props.titleLineTwo && (
          <Text fontWeight={'bold'} fontSize={12} color={'#FFF'}>
            {props.titleLineTwo}
          </Text>
        )}
      </Flex>
      <Flex
        borderBottomLeftRadius={8}
        borderBottomRightRadius={8}
        borderWidth={'3px'}
        borderTop={'none'}
        height={'40px'}
        width={props.width ?? '200px'}
        display={'flex'}
        align={'center'}
        justifyContent={'center'}
      >
        <Text>{props.value}</Text>
      </Flex>
    </Flex>
  );
}
