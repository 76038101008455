import React from 'react';
import { Flex } from '@chakra-ui/react';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import {
  ContractResp,
  useAllContractFiltered,
  useContractFiltered,
  useContractFilteredSearch,
} from '../../../services/ContractService';
import AvatarLabelComponent from '../../UI/atoms/AvatarLabelComponent/AvatarLabelComponent';
import { getFirstIfAny } from '../../../utils/functions/utility';
import { StatusColorByTypeContract } from '../ContractTemplateComponent/ContractTemplateComponent';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import { FaInfo } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useEntitiesSelectUser } from '../../../services/EntitiesService';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { useUserData } from '../../../services/LoginService';
import InputCheckComponent from '../../UI/atoms/InputCheckComponent/InputCheckComponent';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import { formatDate } from '../../../utils/functions/formatDate';
import { FilterContractComponent } from '../../UI/organisms/FilterContractComponent/FilterContractComponent';
import { useLocalStorage } from '../../../services/LocalStorage';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';

export default function BatchChangesTemplateComponent() {
  const navigate = useNavigate();

  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const user = useUserData();
  const entities = useEntitiesSelectUser().data?.filter(
    (item) => item.id === user?.entity?.id
  );
  const [selectedContract, setSelectedContract] = React.useState<string[]>([]);
  const [showFilter, setShowFilter] = React.useState(false);
  const [filterOptions, setFilterOptions] =
    useLocalStorage<useContractFilteredSearch>(
      `${user?.userId}-batch-changes`,
      {
        frequency: null,
        automaticRenovation: null,
        expirationAlert: null,
        value: null,
        status: null,
        active: null,
        myContracts: null,
      }
    );

  const listContract = useContractFiltered(
    selectedPage,
    pageSize,
    'createdAt',
    'desc',
    filterOptions
  );
  const allContract = useAllContractFiltered(
    'createdAt',
    'desc',
    filterOptions
  );

  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  return (
    <>
      <Flex flexDir={'column'}>
        <HeaderTextComponent goBack>
          Criar alteração em lote
        </HeaderTextComponent>
        <Flex mb={4} justifyContent={'space-between'}>
          <HeaderTextComponent subTitle>
            Selecione os contratos para desbloquear a opção de alterar lote
          </HeaderTextComponent>
          <Flex>
            <ButtonComponent
              disabled={selectedContract.length <= 0}
              margin={'0 10px 0 0'}
              onSubmit={() => navigate(`/batch-stepper/${selectedContract}`)}
            >
              Alterar Lote
            </ButtonComponent>
            <FilterContractComponent
              setShowFilter={setShowFilter}
              showFilter={showFilter}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
            />
          </Flex>
        </Flex>
      </Flex>

      <TableComponent
        ItemsHeader={[
          {
            item: (
              <>
                Selecionar
                <InputCheckComponent
                  borderColor="black"
                  m={'0 0 0 10px'}
                  isChecked={allContract.data?.every((item) =>
                    selectedContract.includes(item.id)
                  )}
                  onChange={() => {
                    if (
                      !allContract.data?.every((item) =>
                        selectedContract.includes(item.id)
                      )
                    )
                      allContract.data?.forEach((e) => {
                        if (!selectedContract.some((item) => item === e.id))
                          setSelectedContract((contract) => [
                            ...contract,
                            e.id,
                          ]);
                      });
                    else setSelectedContract([]);
                  }}
                ></InputCheckComponent>
              </>
            ),
          },
          { item: 'Número' },
          { item: 'Locador' },
          { item: 'Locatário' },
          { item: 'Assinado em' },
          { item: 'Vencimento' },
          { item: 'Status' },
          { item: 'Ação' },
        ]}
        isLoading={listContract.isLoading}
        centered={true}
        emptyState={listContract.data?.metadata.dataSize === 0}
        data={
          listContract.data?.data.map((e: ContractResp, i: number) => ({
            items: [
              <InputCheckComponent
                borderColor="black"
                key={i}
                isChecked={selectedContract.some((item) => item === e.id)}
                onChange={() => {
                  if (!selectedContract.some((item) => item === e.id))
                    setSelectedContract([...selectedContract, e.id]);
                  else {
                    setSelectedContract(
                      selectedContract.filter((item) => item !== e.id)
                    );
                  }
                }}
              ></InputCheckComponent>,
              e.serialNumber,
              <AvatarLabelComponent
                key={i}
                label={getFirstIfAny(e.locators)?.name}
                subLabel={'Locador'}
                src={getFirstIfAny(e.locators)?.profilePicUrl}
              />,
              <AvatarLabelComponent
                key={i}
                label={getFirstIfAny(entities)?.name}
                subLabel={'Locatário'}
                src={getFirstIfAny(entities)?.entityImage}
              />,
              formatDate(e.activationDate),
              formatDate(e.expireDate),
              StatusColorByTypeContract({ type: e.contractStatus }),
              <>
                <IconButtonComponent
                  icon={<FaInfo />}
                  ariaLabel="info"
                  onSubmit={() => {
                    navigate(`/contract-details/${e.id}`);
                  }}
                />
              </>,
            ],
          })) || []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listContract.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
    </>
  );
}
