import {
  Center,
  Flex,
  Grid,
  GridItem,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { LocatorPhysicalPerson } from '../../../../services/LocatorService';
import useStateList, {
  useAddressCep,
  useCityList,
  useCountryList,
} from '../../../../services/StateServices';
import {
  cepMask,
  cpfMask,
  phoneMask,
  cleanNumberMask,
  cnpjMask,
} from '../../../../utils/functions/masks';
import { getFirstIfAny } from '../../../../utils/functions/utility';
import { showToast } from '../../../../utils/showToast';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import DropzoneModel from '../uploadModel/DropZoneModel';
import { useDebounce } from '../../../../utils/functions/debounce';
import { saveIntermediary } from '../../../../services/IntermediaryService';
import InputAutoCompleteComponent from '../../atoms/InputAutoCompleteComponent/InputAutoCompleteComponent';
import { useBankAgencyList } from '../../../../services/BankAgencyService';
import { saveReceiver } from '../../../../services/ReceiverService';
import { PersonFunction } from '../../../../services/PhysicalPersonService';

export default function CreateIntermediaryReceiverModalComponent(props: {
  isReceiver?: boolean;
  showModal: boolean;
  setShowModal: () => void;
  reloadData?: () => void;
  onSuccess?: (id: string) => void;
}) {
  const toast = useToast();
  const listBankAgencies = useBankAgencyList();
  const defaultForm: LocatorPhysicalPerson = React.useMemo(
    () => ({
      document: '',
      name: '',
      displayName: '',
      profilePicUrl: '',
      date: '',
      emails: [],
      function: PersonFunction.Intermediary,
      phones: [
        {
          number: '',
          type: 3,
          typeDescription: 'mobile',
        },
      ],
      socialMedias: [],
      addresses: [
        {
          owner: '',
          ownerId: '',
          street: '',
          district: '',
          complement: '',
          zipcode: '',
          type: 1,
          cityId: '',
          coordenateId: '',
          number: '',
        },
      ],
      rg: '',
      spouseId: '',
      accounts: props.isReceiver
        ? [
            {
              agency: '',
              account: '',
              corporateName: '',
              corporateDocument: '',
              bankId: '',
              name: '',
            },
          ]
        : [],
      firstName: '',
      lastName: '',
      gender: 0,
      detail: '',
      companyId: '',

      employee: null,
    }),
    [props.isReceiver]
  );
  const [profileUrlPhysical, setProfileUrlPhysical] = React.useState('');
  const colSpan = useBreakpointValue({ base: 12, sm: 3 });
  const [form, setForm] = React.useState<LocatorPhysicalPerson>({
    ...defaultForm,
  });
  const { data: countries } = useCountryList();
  const { data: states } = useStateList(
    getFirstIfAny(form.addresses)?.countryId ?? ''
  );
  const { data: cities } = useCityList(
    getFirstIfAny(form.addresses)?.stateId ?? ''
  );

  const [, searchCep, setSearchCep] = useDebounce('');
  const { data: dataCep } = useAddressCep(searchCep);
  React.useEffect(() => {
    if (dataCep) {
      setForm((physical) => ({
        ...physical,
        addresses: [
          {
            ...physical.addresses[0],
            street: dataCep.street,
            district: dataCep.district,
            countryId: dataCep.countryId,
            stateId: dataCep.stateId,
            cityId: dataCep.cityId,
          },
        ],
      }));
    }
  }, [dataCep]);

  function getSaveObject(): LocatorPhysicalPerson {
    return {
      ...form,
      name: `${form.firstName} ${form.lastName}`,
      document: cleanNumberMask(form.document),
      profilePicUrl: profileUrlPhysical,
      phones: [
        {
          ...(getFirstIfAny(form.phones) ?? defaultForm.phones[0]),
          number: cleanNumberMask(getFirstIfAny(form.phones)?.number),
        },
      ],
      addresses: [
        {
          ...(getFirstIfAny(form.addresses) ?? defaultForm.addresses[0]),
          zipcode: cleanNumberMask(getFirstIfAny(form.addresses)?.zipcode),
        },
      ],
    };
  }
  function save(saveObject: LocatorPhysicalPerson) {
    if (props.isReceiver) {
      return saveReceiver(saveObject);
    }
    return saveIntermediary(saveObject);
  }
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => {
        props.setShowModal();
      }}
      title={`Novo ${props.isReceiver ? 'Recebedor' : 'Intermediário'}`}
      maxW="1200px"
      maxH="85%"
      overflowX={'auto'}
      size="xl"
    >
      <HeaderTextComponent subTitle>
        Informações de pessoa física
      </HeaderTextComponent>
      <Grid
        templateColumns="repeat(12, 2fr)"
        marginTop={10}
        marginBottom={10}
        gap={6}
      >
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.firstName}
            onChange={(input) =>
              setForm({
                ...form,
                firstName: input.target.value,
              })
            }
            label="Nome"
            placeholder="Digite o nome"
          />
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.displayName}
            onChange={(input) =>
              setForm({
                ...form,
                displayName: input.target.value,
              })
            }
            label="Apelido"
            placeholder="Digite o apelido"
          />
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.date}
            onChange={(input) =>
              setForm({
                ...form,
                date: input.target.value,
              })
            }
            type="date"
            label="Data de Nascimento"
            placeholder="00/00/00000"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.lastName}
            onChange={(input) =>
              setForm({
                ...form,
                lastName: input.target.value,
              })
            }
            label="Sobrenome"
            placeholder="Digite o sobrenome"
          />
          <InputSelectComponent
            m={'0 0 10px 0'}
            defaultValue={String(form.gender)}
            options={[
              { id: '1', name: 'Masculino' },
              { id: '2', name: 'Feminino' },
            ]}
            onChange={(input) =>
              setForm({
                ...form,
                gender: Number(input.target.value),
              })
            }
            label="Gênero"
            placeholder="Selecione o seu gênero"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.document}
            onChange={(input) =>
              setForm({
                ...form,
                document: cpfMask(input.target.value),
                accounts: props.isReceiver
                  ? [
                      {
                        ...form.accounts[0],
                        corporateDocument: cpfMask(input.target.value),
                      },
                    ]
                  : form.accounts,
              })
            }
            label="CPF"
            placeholder="000.000.000-00"
          />
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.phones[0].number}
            onChange={(input) =>
              setForm({
                ...form,
                phones: [
                  {
                    ...form.phones[0],
                    number: phoneMask(input.target.value),
                  },
                ],
              })
            }
            label="Celular"
            placeholder="(XX) 9 XXXX-XXXX"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <Center>
            <DropzoneModel
              fileUrl={profileUrlPhysical}
              setProfilePicUrl={setProfileUrlPhysical}
              type="profile"
            />
          </Center>
        </GridItem>
      </Grid>
      <HeaderTextComponent subTitle>
        Informações de endereço
      </HeaderTextComponent>
      <Grid templateColumns="repeat(12, 1fr)" marginTop={10} gap={6}>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.addresses[0].zipcode}
            onChange={(input) => {
              setSearchCep(cleanNumberMask(input.target.value));
              setForm({
                ...form,
                addresses: [
                  {
                    ...form.addresses[0],
                    zipcode: cepMask(input.target.value),
                  },
                ],
              });
            }}
            label="CEP"
            placeholder="00.000-000"
          />
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(12, 2fr)" marginBottom={10} gap={6}>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.addresses[0].street}
            onChange={(input) =>
              setForm({
                ...form,
                addresses: [
                  {
                    ...form.addresses[0],
                    street: input.target.value,
                  },
                ],
              })
            }
            label="Logradouro"
            placeholder="Digite o Logradouro"
          />
          <InputSelectComponent
            defaultValue={form.addresses[0].countryId}
            onChange={(input) =>
              setForm({
                ...form,
                addresses: [
                  {
                    ...form.addresses[0],
                    countryId: input.target.value,
                  },
                ],
              })
            }
            options={countries}
            placeholder="Selecione o País"
            label="País"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.addresses[0].complement}
            onChange={(input) =>
              setForm({
                ...form,
                addresses: [
                  {
                    ...form.addresses[0],
                    complement: input.target.value,
                  },
                ],
              })
            }
            label="Número"
            placeholder="Digite o número"
          />
          <InputSelectComponent
            defaultValue={form.addresses[0].stateId}
            onChange={(input) =>
              setForm({
                ...form,
                addresses: [
                  {
                    ...form.addresses[0],
                    stateId: input.target.value,
                  },
                ],
              })
            }
            options={states}
            placeholder="Selecione o estado"
            label="Estado"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <InputComponent
            margin={'0 0 10px 0'}
            value={form.addresses[0].district}
            onChange={(input) =>
              setForm({
                ...form,
                addresses: [
                  {
                    ...form.addresses[0],
                    district: input.target.value,
                  },
                ],
              })
            }
            label="Bairro"
            placeholder="Digite o bairro"
          />
          <InputSelectComponent
            defaultValue={form.addresses[0].cityId}
            options={cities}
            onChange={(input) =>
              setForm({
                ...form,
                addresses: [
                  {
                    ...form.addresses[0],
                    cityId: input.target.value,
                  },
                ],
              })
            }
            label="Cidade"
            placeholder="Selecione uma cidade"
          />
        </GridItem>
      </Grid>
      {props.isReceiver && (
        <>
          <HeaderTextComponent subTitle>
            Informações Bancárias
          </HeaderTextComponent>
          <Grid templateColumns="repeat(12, 1fr)" marginTop={10} gap={6}>
            <GridItem colSpan={colSpan}>
              <InputAutoCompleteComponent
                value={getFirstIfAny(form.accounts)?.name}
                options={listBankAgencies.data?.map((item) => ({
                  id: `${item.bankAgencyId}||${item.name}`,
                  name: item.name,
                }))}
                onChangeText={(input) =>
                  setForm({
                    ...form,
                    accounts: [
                      {
                        ...form.accounts[0],
                        name: input.target.value,
                      },
                    ],
                  })
                }
                onChangeOption={(input) => {
                  const values = input.split('||');
                  setForm({
                    ...form,
                    accounts: [
                      {
                        ...form.accounts[0],
                        bankId: values[0],
                        name: values[1],
                      },
                    ],
                  });
                }}
                label="Banco"
                placeholder="Selecione um Banco"
              />
            </GridItem>
            <GridItem colSpan={colSpan}>
              <InputComponent
                margin={'0 0 10px 0'}
                maxLength={9}
                type="number"
                value={getFirstIfAny(form.accounts)?.agency}
                onChange={(input) =>
                  setForm({
                    ...form,
                    accounts: [
                      { ...form.accounts[0], agency: input.target.value },
                    ],
                  })
                }
                label="Agência"
                placeholder="0000"
              />
            </GridItem>
            <GridItem colSpan={colSpan}>
              <InputComponent
                margin={'0 0 10px 0'}
                maxLength={9}
                value={getFirstIfAny(form.accounts)?.account}
                onChange={(input) =>
                  setForm({
                    ...form,
                    accounts: [
                      { ...form.accounts[0], account: input.target.value },
                    ],
                  })
                }
                label="Conta com Dígito"
                placeholder="00000000"
              />
            </GridItem>
          </Grid>
          <Grid templateColumns="repeat(12, 1fr)" marginBottom={10} gap={6}>
            <GridItem colSpan={colSpan}>
              <InputComponent
                margin={'0 0 10px 0'}
                value={getFirstIfAny(form.accounts)?.corporateName}
                onChange={(input) =>
                  setForm({
                    ...form,
                    accounts: [
                      {
                        ...form.accounts[0],
                        corporateName: input.target.value,
                      },
                    ],
                  })
                }
                label="Nome"
                placeholder="Digite o nome"
              />
            </GridItem>
            <GridItem colSpan={colSpan}>
              <InputComponent
                margin={'0 0 10px 0'}
                value={getFirstIfAny(form.accounts)?.corporateDocument}
                onChange={(input) =>
                  setForm({
                    ...form,
                    accounts: [
                      {
                        ...form.accounts[0],
                        corporateDocument:
                          input.target.value.length > 14
                            ? cnpjMask(input.target.value)
                            : cpfMask(input.target.value),
                      },
                    ],
                  })
                }
                label={'CPF'}
                placeholder="000.000.000-00"
              />
            </GridItem>
          </Grid>
        </>
      )}
      <Flex mb={5} justifyContent={'space-between'}>
        <ButtonComponent
          variant={'outline'}
          onSubmit={() => {
            setForm(defaultForm);
            props.setShowModal();
          }}
        >
          Descartar
        </ButtonComponent>
        <ButtonComponent
          onSubmit={() => {
            save(getSaveObject()).then((res) => {
              showToast({
                toast,
                status: 'success',
                title: 'Sucesso',
                description: 'Salvo com sucesso',
              });
              setForm(defaultForm);
              props.reloadData?.();
              props.onSuccess?.(res.data.content.data.id);
              props.setShowModal();
            });
          }}
        >
          {`Criar ${props.isReceiver ? 'Recebedor' : 'Intermediário'}`}
        </ButtonComponent>
      </Flex>
    </ModalStructureComponent>
  );
}
