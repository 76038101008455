import {
  Box,
  Flex,
  GridItem,
  Spinner,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FaCheck, FaPeopleArrows, FaUserPlus } from 'react-icons/fa';
import {
  AttendanceStatus,
  useAttendaceDetails,
} from '../../../../services/Attendance';
import { parseDateAndHour } from '../../../../utils/functions/utility';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import ScrollableFeedComponent from '../../atoms/ScrollableFeedComponent/ScrollableFeedComponent';
import TextComponent from '../../atoms/TextComponent/TextComponent';
import {
  DocumentComponent,
  EmptyStateDocumentComponent,
} from '../../molecules/DocumentComponent/DocumentComponent';
import { ResponsibleDetails } from '../../molecules/ResponsibleDetails/ResponsibleDetails';
import { CloseIcon } from '@chakra-ui/icons';

type ChatDetailsComponentProps = {
  AttendanceId: string;
  onSubmitTransferButton: () => void;
  onSubmitToAssumeButton: () => void;
  onSubmitConcludeButton: () => void;
  onSubmitCancelButton: () => void;
  onSubmitRejectRegisterButton: () => void;
  onSubmitAcceptRegisterButton: () => void;
};

export function ChatDetailsComponent(props: ChatDetailsComponentProps) {
  const colSpanStepper = useBreakpointValue({ base: 12, sm: 4 });
  const attendance = useAttendaceDetails(props.AttendanceId);
  const attendanceDetails = attendance.data;

  return (
    <GridItem colSpan={colSpanStepper}>
      <Flex
        direction={'column'}
        height={{ base: '100%', sm: '100vh' }}
        backgroundColor={'brand.500'}
        padding={5}
        justifyContent={attendance.isLoading ? 'center' : undefined}
        alignItems={attendance.isLoading ? 'center' : undefined}
      >
        {attendance.isLoading ? (
          <Spinner size={'xl'} color="white"></Spinner>
        ) : (
          <>
            <Box marginBottom={15}>
              <TextComponent as={'b'} color={'white'} fontSize={'2xl'}>
                Detalhes
              </TextComponent>
              <ResponsibleDetails
                style={{ marginTop: 15 }}
                hour={parseDateAndHour(attendanceDetails?.date)}
                closedAt={parseDateAndHour(attendanceDetails?.closedAt)}
                lastUpdateAt={parseDateAndHour(attendanceDetails?.lastUpdateAt)}
                subject={attendanceDetails?.subject}
                status={attendanceDetails?.attendanceStatus || 0}
                ticket={attendanceDetails?.serialNumber}
                label={attendanceDetails?.responsibleName || 'Não Definido'}
                subLabel="Atendente"
              />
            </Box>
            {attendanceDetails?.attendanceStatus !==
              AttendanceStatus.finished && (
              <Box>
                <TextComponent as={'b'} color={'white'} fontSize={'2xl'}>
                  Ações
                </TextComponent>
                <Stack
                  direction={'row'}
                  justifyContent={'space-around'}
                  marginY={15}
                >
                  {!attendanceDetails?.currentUserIsRequester && (
                    <>
                      <ButtonComponent
                        colorScheme={'whiteAlpha'}
                        backgroundColor="#fff"
                        textColor={'#3182CE'}
                        onSubmit={() => props.onSubmitTransferButton()}
                        leftIcon={<FaPeopleArrows />}
                        isLoading={attendance.isLoading}
                      >
                        Transferir
                      </ButtonComponent>
                      {!attendanceDetails?.responsibleName && (
                        <ButtonComponent
                          colorScheme={'whiteAlpha'}
                          backgroundColor="#fff"
                          textColor={'#3182CE'}
                          onSubmit={() => props.onSubmitToAssumeButton()}
                          leftIcon={<FaUserPlus />}
                        >
                          Assumir
                        </ButtonComponent>
                      )}
                    </>
                  )}
                  {attendanceDetails?.attendanceStatus !==
                    AttendanceStatus.finished &&
                    !(
                      attendanceDetails?.responsibleName &&
                      attendanceDetails.subject.includes('cadastro')
                    ) && (
                      <>
                        <ButtonComponent
                          colorScheme={'whiteAlpha'}
                          backgroundColor="#fff"
                          textColor={'#3182CE'}
                          onSubmit={() => props.onSubmitConcludeButton()}
                          leftIcon={<FaCheck />}
                        >
                          Finalizar
                        </ButtonComponent>

                        <ButtonComponent
                          colorScheme={'whiteAlpha'}
                          backgroundColor="#fff"
                          textColor={'#3182CE'}
                          onSubmit={() => props.onSubmitCancelButton()}
                          leftIcon={<CloseIcon />}
                        >
                          Cancelar
                        </ButtonComponent>
                      </>
                    )}
                </Stack>
              </Box>
            )}
            <Box>
              <>
                <TextComponent as={'b'} color={'white'} fontSize={'2xl'}>
                  Documentos na Conversa
                </TextComponent>
                <Flex direction={'column'} height={310}>
                  <ScrollableFeedComponent>
                    {attendanceDetails?.attachments.length ? (
                      attendanceDetails.attachments.map((e, i) => (
                        <DocumentComponent
                          key={i}
                          onClick={() => window.open(`${e.url}`, '_blank')}
                          DocumentName={e.displayName}
                          documentTypeDescription={e.type}
                        />
                      ))
                    ) : (
                      <EmptyStateDocumentComponent />
                    )}
                  </ScrollableFeedComponent>
                </Flex>
              </>
            </Box>
          </>
        )}
      </Flex>
    </GridItem>
  );
}
