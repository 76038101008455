import { Flex, useToast } from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClauseResp, TypeClause } from '../../../services/ClauseService';
import {
  Contract,
  saveContract,
  StatusContract,
  useContractDetails,
  Witnesses,
} from '../../../services/ContractService';
import { LocatorResp } from '../../../services/LocatorService';
import { parseDate } from '../../../utils/functions/utility';
import { showToast } from '../../../utils/showToast';
import OnboardingStructureComponent from '../../UI/molecules/OnboardingStructureComponent/OnboardingStructureComponent';
import ContractStepperLocatorComponent from '../../UI/organisms/ContractStepperLocatorComponent/ContractStepperLocatorComponent';
import ContractStepperLocationComponent from '../../UI/organisms/ContractStepperLocationComponent/ContractStepperLocationComponent';
import ContractStepperFormComponent from '../../UI/organisms/ContractStepperFormComponent/ContractStepperFormComponent';
import ContractStepperClauseComponent from '../../UI/organisms/ContractStepperClauseComponent/ContractStepperClauseComponent';
import { RelatedEntity } from '../../../services/RelatedEntityService';
import ContractStepperConsiderationComponent from '../../UI/organisms/ContractStepperConsiderationComponent/ContractStepperConsiderationComponent';
import { ConsiderationResp } from '../../../services/ConsiderationService';
import {
  clearMoneyMask,
  convertToMonetaryValue,
} from '../../../utils/functions/masks';

export default function ContractStepperTemplateComponent() {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const defaultForm = React.useMemo(
    () => ({
      id: '',
      activationDate: '0001-01-01 00:00:00.0000000',
      expireDate: '0001-01-01 00:00:00.0000000',
      baseReadjustmentDate: '0001-01-01 00:00:00.0000000',
      startDate: '0001-01-01 00:00:00.0000000',
      dueDate: '0001-01-01 00:00:00.0000000',
      installationDate: '0001-01-01 00:00:00.0000000',
      firstPaymentDate: '0001-01-01 00:00:00.0000000',
      frequency: 4,
      advancePayment: null,
      value: 0,
      area: 0,
      iptuRegistration: '',
      iptuPayment: null,
      iptuValue: 0,
      automaticRenovation: null,
      automaticReadjustment: null,
      expirationAlert: null,
      origin: '',
      data: '',
      comments: '',
      regionalId: '',
      relatedEntityId: '',
      clusterId: '',
      towerTypeId: '',
      technologyId: '',
      equipmentId: '',
      engineeringTypeId: '',
      intermediaryId: '',
      manualIdentifier: '',
      readjustmentIndexOption: 0,
      locators: [],
      locationIds: [],
      clauseIds: [],
      considerationIds: [],
      witnesses: [],
      numberOfRenewals: 0,
    }),
    []
  );
  const steps = [
    'Locadores',
    'Locatário/ Local',
    'Outros Dados',
    'Cláusulas',
    'Considerações',
  ];
  const title = 'Gestão de Contratos';
  const toast = useToast();
  const subtitle = 'Siga o nosso passo a passo para criar um novo contrato';
  const [step, setStep] = React.useState(0);
  const [selectedLocators, setSelectedLocators] = React.useState<LocatorResp[]>(
    []
  );
  const [selectedRelatedEntity, setSelectedRelatedEntity] =
    React.useState<RelatedEntity>();
  const [selectedLocations, setSelectedLocations] = React.useState<string[]>(
    []
  );
  const [selectedIntermediary, setSelectedIntermediary] =
    React.useState<LocatorResp>();
  const [selectedClauseGeneral, setSelectedClauseGeneral] = React.useState<
    (ClauseResp | undefined)[]
  >([]);
  const [selectedClauseRenovation, setSelectedClauseRenovation] =
    React.useState<(ClauseResp | undefined)[]>([]);
  const [selectedClauseClosing, setSelectedClauseClosing] = React.useState<
    (ClauseResp | undefined)[]
  >([]);
  const [selectedConsiderations, setSelectedConsiderations] = React.useState<
    (ConsiderationResp | undefined)[]
  >([]);

  const [form, setForm] = React.useState<Contract>({ ...defaultForm });
  const [witnessesOneForm, setWitnessesOneForm] = React.useState<Witnesses[]>({
    ...defaultForm.witnesses,
  });
  const [witnessesTwoForm, setWitnessesTwoForm] = React.useState<Witnesses[]>({
    ...defaultForm.witnesses,
  });
  const contract = useContractDetails(params.id ?? '');

  React.useEffect(() => {
    if (contract.data && params.id) {
      setForm({
        id: contract.data.id,
        relatedEntityId: contract.data.relatedEntityId,
        activationDate: parseDate(contract.data.activationDate),
        expireDate: parseDate(contract.data.expireDate),
        baseReadjustmentDate: parseDate(contract.data.baseReadjustmentDate),
        startDate: parseDate(contract.data.startDate),
        installationDate: parseDate(contract.data.installationDate),
        firstPaymentDate: parseDate(contract.data.firstPaymentDate),
        contractStatus: contract.data.contractStatus,
        frequency: contract.data.frequency,
        advancePayment: contract.data.advancePayment,
        value: convertToMonetaryValue(contract.data.value),
        area: contract.data.area,
        iptuRegistration: contract.data.iptuRegistration,
        iptuPayment: contract.data.iptuPayment,
        numberOfRenewals: contract.data.numberOfRenewals,
        iptuValue: convertToMonetaryValue(contract.data.iptuValue),
        automaticRenovation: contract.data.automaticRenovation,
        automaticReadjustment: contract.data.automaticReadjustment,
        expirationAlert: contract.data.expirationAlert,
        origin: contract.data.origin,
        data: contract.data.data,
        comments: contract.data.comments,
        regionalId: contract.data.regionalId,
        clusterId: contract.data.clusterId,
        readjustmentIndexOption: contract.data?.index || 1,
        towerTypeId: contract.data.towerTypeId,
        technologyId: contract.data.technologyId,
        equipmentId: contract.data.equipmentId,
        engineeringTypeId: contract.data.engineeringTypeId,
        intermediaryId: contract.data.intermediary?.id,
        manualIdentifier: contract.data?.manualIdentifier,
        locators: contract.data.locators.map((item) => ({
          locatorId: item.locatorId,
          percentage: Number(item.quota),
          receiver: item.receiver,
          attorney: item.attorney,
          receiverId: item.receiver?.id,
          attorneyId: item.attorney?.personId,
        })),
        locationIds: contract.data.locations.map((item) => item.id),
        clauseIds: contract.data.clauses.map((item) => item.id),
        considerationIds: contract.data.considerations.map((item) => item.id),
      });
      setWitnessesOneForm([
        {
          name: contract.data.witnesses[0]
            ? contract.data.witnesses[0].name
            : '',
          document: contract.data.witnesses[0]
            ? contract.data.witnesses[0].document
            : '',
          email: contract.data.witnesses[0]
            ? contract.data.witnesses[0].email
            : '',
          id: contract.data.witnesses[0] ? contract.data.witnesses[0].id : '',
        },
      ]);
      setWitnessesTwoForm([
        {
          name: contract.data.witnesses[1]
            ? contract.data.witnesses[1].name
            : '',
          document: contract.data.witnesses[1]
            ? contract.data.witnesses[1].document
            : '',
          email: contract.data.witnesses[1]
            ? contract.data.witnesses[1].email
            : '',
          id: contract.data.witnesses[1] ? contract.data.witnesses[1].id : '',
        },
      ]);
      setSelectedLocators(
        contract.data.locators.map((item) => ({
          ...item,
          quota: String(item.percentage),
          phones: [
            {
              number: item.phone || '',
              type: 3,
              typeDescription: 'mobile',
            },
          ],
        }))
      );
      setSelectedLocations(contract.data.locations.map((item) => item.id));
      setSelectedIntermediary(contract.data.intermediary);
      setSelectedRelatedEntity(contract.data.relatedEntity);

      setSelectedClauseGeneral(
        contract.data.clauses.filter((item) => item.type === TypeClause.General)
      );
      setSelectedClauseRenovation(
        contract.data.clauses.filter(
          (item) => item.type === TypeClause.Renovation
        )
      );
      setSelectedClauseClosing(
        contract.data.clauses.filter((item) => item.type === TypeClause.Closing)
      );
      setSelectedConsiderations(contract.data.considerations);
    } else setForm({ ...defaultForm });
  }, [contract.data, defaultForm, params]);
  const [loading, setLoading] = React.useState(false);

  return (
    <>
      <OnboardingStructureComponent
        titles={[title, title, title, title, title]}
        subtitles={[subtitle, subtitle, subtitle, subtitle, subtitle]}
        steps={steps}
        currentStep={step}
        step={step}
        setStep={setStep}
        nextStepDisabled={
          (step === 0 &&
            (selectedLocators.length <= 0 ||
              selectedLocators.reduce(
                (init, cur) => init + Number(cur.quota),
                0
              )) !== 100) ||
          (step === 2 && form.baseReadjustmentDate < form.startDate) ||
          (step === 2 && form.expireDate < form.startDate)
        }
        isLoading={loading}
        finishButtonLabel="Concluir"
        onSubmit={() => {
          setLoading(true);
          const saveForm: Contract = {
            ...form,
            numberOfRenewals: Number(form.numberOfRenewals),
            relatedEntityId: selectedRelatedEntity?.id ?? '',
            value: Number(clearMoneyMask(form.value?.toString() ?? '')),
            iptuValue: Number(clearMoneyMask(form.iptuValue?.toString() ?? '')),
            intermediaryId: selectedIntermediary?.id ?? '',
            locators: selectedLocators.map((item) => ({
              locatorId: item.locatorId,
              percentage: Number(item.quota),
              receiverId: item.receiver?.id,
              attorneyId: item.attorney?.personId,
            })),
            locationIds: selectedLocations,
            clauseIds: [
              ...selectedClauseGeneral.map((item) => item?.id ?? ''),
              ...selectedClauseRenovation.map((item) => item?.id ?? ''),
              ...selectedClauseClosing.map((item) => item?.id ?? ''),
            ],
            considerationIds: [
              ...selectedConsiderations.map((item) => item?.id ?? ''),
            ],
            witnesses: [
              witnessesOneForm[0]
                ? witnessesOneForm[0]
                : { name: '', document: '', email: '', id: '' },
              witnessesTwoForm[0]
                ? witnessesTwoForm[0]
                : { name: '', document: '', email: '', id: '' },
            ],
          };
          saveContract(saveForm)
            .then((res) => {
              showToast({
                toast,
                status: 'success',
                title: 'Sucesso',
                description: saveForm.id
                  ? contract.data?.contractStatus === StatusContract.Assinado
                    ? 'Pedido de alteração realizado com sucesso.'
                    : 'Edição realizada com sucesso'
                  : 'Salvo com sucesso',
              });
              navigate(
                saveForm.id ? `/contract-details/${saveForm.id}` : '/contract'
              );
            })
            .finally(() => setLoading(false));
        }}
      >
        <Flex mt={10}>
          <Steps selected={step}>
            <ContractStepperLocatorComponent
              selectedLocators={selectedLocators}
              setSelectedLocators={setSelectedLocators}
              selectedRelatedEntity={selectedRelatedEntity}
              setSelectedRelatedEntity={setSelectedRelatedEntity}
            />
            <ContractStepperLocationComponent
              contractId={params.id ?? ''}
              selectedLocators={selectedLocators}
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
              selectedIntermediary={selectedIntermediary}
              setSelectedIntermediary={setSelectedIntermediary}
            />
            <ContractStepperFormComponent form={form} setForm={setForm} />
            <ContractStepperClauseComponent
              selectedClauseGeneral={selectedClauseGeneral}
              setSelectedClauseGeneral={setSelectedClauseGeneral}
              selectedClauseRenovation={selectedClauseRenovation}
              setSelectedClauseRenovation={setSelectedClauseRenovation}
              selectedClauseClosing={selectedClauseClosing}
              setSelectedClauseClosing={setSelectedClauseClosing}
            />
            <ContractStepperConsiderationComponent
              formOne={witnessesOneForm}
              setFormOne={setWitnessesOneForm}
              formTwo={witnessesTwoForm}
              setFormTwo={setWitnessesTwoForm}
              selectedConsiderations={selectedConsiderations}
              setSelectedConsiderations={setSelectedConsiderations}
            />
          </Steps>
        </Flex>
      </OnboardingStructureComponent>
    </>
  );
}
const Steps = (props: {
  children: React.ReactElement[];
  selected: number;
}): React.ReactElement => {
  return props.children[props.selected];
};
