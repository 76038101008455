import { Flex, Stack, Text, useToast } from '@chakra-ui/react';
import React from 'react';
import {
  contractRenovation,
  ContractResp,
} from '../../../../services/ContractService';
import { parseDate } from '../../../../utils/functions/utility';
import { showToast } from '../../../../utils/showToast';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import {
  NegotiationStatus,
  putCampaignContractNegociationStatus,
} from '../../../../services/CampaignsService';

export function ContractRenovationModalComponent(props: {
  showModal: boolean;
  campaingId?: string;
  selectedContract?: ContractResp;
  setShowModal: () => void;
  reloadData?: () => void;
}) {
  const toast = useToast();
  const defaultForm = React.useMemo(
    () => ({
      id: '',
      oldExpirationDate: '',
      newExpirationDate: '',
    }),
    []
  );
  const [form, setForm] = React.useState(defaultForm);
  React.useEffect(() => {
    if (props.selectedContract)
      setForm({
        id: props.selectedContract.id,
        oldExpirationDate: parseDate(props.selectedContract.expireDate),
        newExpirationDate: '',
      });
    else setForm(defaultForm);
  }, [props.selectedContract, defaultForm]);
  const [errorDateMessage, setErrorDateMessage] = React.useState('');

  function validateDate(oldExpirationDate: string, newExpirationDate: string) {
    if (newExpirationDate < oldExpirationDate) {
      setErrorDateMessage(
        'Nova Data final do contrato deve ser maior que a Data final atual'
      );
    } else {
      setErrorDateMessage('');
    }
  }
  return (
    <ModalStructureComponent
      footer={
        <Flex p={4}>
          <ButtonComponent
            margin={'0 10px 0 0'}
            variant={'outline'}
            onSubmit={() => {
              setForm({
                id: props.selectedContract?.id || '',
                oldExpirationDate: parseDate(
                  props.selectedContract?.expireDate
                ),
                newExpirationDate: '',
              });
              props.setShowModal();
            }}
          >
            Descartar
          </ButtonComponent>
          <ButtonComponent
            disabled={errorDateMessage ? true : false}
            onSubmit={() => {
              contractRenovation(form).then((res) => {
                props.setShowModal();
                props.reloadData?.();
                showToast({
                  toast,
                  status: 'success',
                  title: 'Sucesso',
                  description: 'Pedido de alteração realizado com sucesso.',
                });
                if (props.campaingId) {
                  return putCampaignContractNegociationStatus(
                    props.campaingId ?? '',
                    props.selectedContract?.id ?? '',
                    NegotiationStatus.InProgress
                  ).then(() => {
                    showToast({
                      toast,
                      status: 'success',
                      title: 'Sucesso',
                      description:
                        'O status da negociação agora está em andamento',
                    });
                    props.reloadData?.();
                  });
                }
              });
            }}
          >
            Salvar
          </ButtonComponent>
        </Flex>
      }
      isOpen={props.showModal}
      onClose={() => props.setShowModal()}
      title={'Renovação'}
      maxW="1200px"
      size="lg"
    >
      <Text fontSize={'sm'} fontWeight={'600'} marginBottom={7}>
        Selecione o novo período de vigência do contrato
      </Text>
      <Stack mb={5} direction={'row'} spacing={'4%'}>
        <InputComponent
          label="Data final atual"
          type="date"
          disabled={true}
          value={form.oldExpirationDate}
          onChange={(input) => {
            setForm({ ...form, oldExpirationDate: input.target.value });
          }}
        />
        <InputComponent
          label="Nova data final do contrato"
          type="date"
          value={form.newExpirationDate}
          error={errorDateMessage}
          onChange={(input) => {
            setForm({ ...form, newExpirationDate: input.target.value });
            validateDate(form.oldExpirationDate, input.target.value);
          }}
        />
      </Stack>
    </ModalStructureComponent>
  );
}
