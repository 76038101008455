import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import {
  PaginationComponent,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { Flex, Stack, useToast } from '@chakra-ui/react';
import AvatarLabelComponent from '../../UI/atoms/AvatarLabelComponent/AvatarLabelComponent';
import React from 'react';
import SearchSelectComponent from '../../UI/molecules/SearchSelectComponent/SearchSelectComponent';
import {
  LocatorPersonType,
  LocatorResp,
} from '../../../services/LocatorService';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import CreatePersonModalComponent from '../../UI/organisms/CreateLocatorModalComponent/CreateLocatorModalComponent';
import { personDocumentMask } from '../../../utils/functions/masks';
import {
  PersonFunction,
  SortDirection,
} from '../../../services/PhysicalPersonService';
import { TableColumns } from '../../UI/organisms/SelectTableFieldsModalComponent/SelectTableFieldsModalComponent';
import { TabsFilterComponent } from '../../UI/organisms/TabsFilterComponent/TabsFilterComponent';
import {
  PersonResp,
  getPersonReport,
  getResendConfirmationEmail,
  usePerson,
} from '../../../services/PersonService';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import { ChatIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { FaExclamationCircle, FaPaperPlane } from 'react-icons/fa';
import ModalStructureComponent from '../../UI/molecules/ModalStructureComponent/ModalStructureComponent';
import TagComponent from '../../UI/atoms/TagComponent/TagComponent';
import TextComponent from '../../UI/atoms/TextComponent/TextComponent';
import { showToast } from '../../../utils/showToast';
import {
  useDebouncedQuerySearch,
} from '../../../utils/functions/useDebouncedQuerySearch';

const defaultPersonColumns: TableColumns = [
  {
    item: 'nome/razão social',
  },
  {
    item: 'apelido',
  },
  {
    item: 'documento',
  },
  {
    item: 'quantidade de contratos',
  },
  {
    item: 'email',
  },
  {
    item: 'ativo',
  },
  {
    item: 'ações',
  },
];

type LocatorTemplateProps = {
  function?: PersonFunction;
};

export default function PersonTemplateComponent(props: LocatorTemplateProps) {
  const navigate = useNavigate();

  const {
    search,
    searchField,
    searchInput,
    setSearch,
    setSearchField,
    pagination: { pageSize, selectedPage, setSelectedPage },
  } = useDebouncedQuerySearch('Name');
  const toast = useToast();
  const [, setSelectedLocator] = React.useState<LocatorResp | undefined>();
  const [showModal, setShowModal] = React.useState(false);
  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const [sortDirection, setSortDirection] =
    React.useState<SortDirection>('desc');
  const [sort, setSort] = React.useState<string>('createdAt');
  const [loadingResendEmail, setLoadinResendEmail] = React.useState(false);
  const [indexResendButton, setIndexResendButton] = React.useState(0);

  const [selectedPerson, setSelectedPerson] = React.useState<PersonResp>();
  const [personType, setPersonType] = React.useState(
    props.function ?? PersonFunction.common
  );

  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }

  const tabName = [
    {
      title: 'Usuários do sistema',
      onClick: () => {
        setPersonType(PersonFunction.common);
      },
    },
    {
      title: 'Locadores',
      onClick: () => {
        setPersonType(PersonFunction.Locator);
      },
    },
    {
      title: 'Intermediadores',
      onClick: () => {
        setPersonType(PersonFunction.Intermediary);
      },
    },
    {
      title: 'Recebedores',
      onClick: () => {
        setPersonType(PersonFunction.Receiver);
      },
    },
  ];
  const listPerson = usePerson(
    selectedPage,
    pageSize,
    search,
    searchField,
    sort,
    sortDirection,
    personType
  );

  return (
    <>
      <Stack
        alignItems={'flex-end'}
        justifyContent={'space-between'}
        direction="row"
        mb={2}
      >
        <Flex flexDir={'column'}>
          <Flex marginBottom={4}>
            <HeaderTextComponent goBack>
              {props.function === undefined ? 'Pessoas' : 'Usuários'}
            </HeaderTextComponent>
          </Flex>
          {props.function === undefined && (
            <TabsFilterComponent tabList={tabName} />
          )}
        </Flex>

        <Flex>
          <ButtonComponent
            margin={'0 10px 0 0'}
            onSubmit={() => {
              getPersonReport(search, searchField, sort, sortDirection);
            }}
          >
            Exportar
          </ButtonComponent>
          <ButtonComponent
            margin={'0 10px 0 0'}
            onSubmit={() => {
              setSelectedLocator(undefined);
              setSelectedPerson(undefined);
              setShowModal(true);
            }}
          >
            Novo Cadastro
          </ButtonComponent>
          <SearchSelectComponent
            onChangeText={(input) => setSearch(input.target.value)}
            inputValue={searchInput}
            selectDefaultValue={searchField}
            onClean={() => setSearch('')}
            onChange={(item) => {
              setSearchField(item.target.value);
            }}
            options={[
              { id: 'name', name: 'Nome' },
              { id: 'email', name: 'Email' },
              { id: 'document', name: 'Documento' },
            ]}
          />
        </Flex>
      </Stack>
      <TableComponent
        ItemsHeader={defaultPersonColumns}
        sorting={{ sortColumn: sort, direction: sortDirection }}
        onSortingChanged={(sort, dir) => {
          setSort(sort);
          setSortDirection(dir);
        }}
        isLoading={listPerson.isLoading}
        centered={true}
        emptyState={listPerson.data?.data.length === 0}
        data={
          listPerson.data?.data.map((e, i) => ({
            items: [
              <AvatarLabelComponent label={e.name} key={i} />,
              e.displayName,
              personDocumentMask(e.document, e.type),
              e.contractCount,
              e.emails[0] ?? 'sem email cadastrado',
              <TagComponent
                colorScheme={e.isUserActive ? 'green' : 'red'}
                text={e.isUserActive ? 'Ativo' : 'Inativo'}
                size="sm"
              />,

              <>
                <IconButtonComponent
                  toolTipText="Reenviar email"
                  disabled={loadingResendEmail && i === indexResendButton}
                  isLoading={loadingResendEmail && i === indexResendButton}
                  ariaLabel="send button"
                  onSubmit={() => {
                    setIndexResendButton(i);
                    setLoadinResendEmail(true);
                    getResendConfirmationEmail(e.physicalOrJuridicalPersonId)
                      .then(() => {
                        showToast({
                          toast,
                          status: 'success',
                          title: 'Sucesso',
                          description:
                            'Sucesso. Procedimento realizado com sucesso',
                        });
                      })
                      .finally(() => setLoadinResendEmail(false));
                  }}
                  icon={<FaPaperPlane />}
                />

                <ButtonComponent
                  key={i}
                  margin={'0 8px 0 8px'}
                  disabled={!e?.physicalOrJuridicalPersonId}
                  onSubmit={() => {
                    setSelectedPerson(e);
                    setShowModal(true);
                  }}
                >
                  Editar
                </ButtonComponent>
                <IconButtonComponent
                  toolTipText="Mensagens"
                  colorScheme="green"
                  ariaLabel="Messsage"
                  onSubmit={() => {
                    setSelectedPerson(e);
                    const personTypeUrlPath =
                      e.type === LocatorPersonType.Juridical
                        ? 'juridical'
                        : 'physical';
                    navigate(
                      `/person/message/${e.personId}/${personTypeUrlPath}/${e.physicalOrJuridicalPersonId}`
                    );
                  }}
                  icon={<ChatIcon />}
                />
                {/* <IconButtonComponent
                  toolTipText={
                    e.type === LocatorPersonType.Physical
                      ? 'Deletar Pessoa'
                      : 'Pessoa não pode ser deletada'
                  }
                  colorScheme="red"
                  ariaLabel="Delete"
                  disabled={
                    e.type === LocatorPersonType.Physical ? false : true
                  }
                  onSubmit={() => {
                    setShowModalDelete(true);
                  }}
                  icon={<FaTrash />}
                /> */}
              </>,
            ],
          })) || []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listPerson.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <CreatePersonModalComponent
        showModal={showModal}
        selectedPerson={selectedPerson}
        setShowModal={() => {
          setShowModal(false);
          setSelectedLocator(undefined);
        }}
        reloadData={() => listPerson.refetch()}
      />
      <ModalStructureComponent
        isOpen={showModalDelete}
        onClose={() => {
          setShowModalDelete(false);
        }}
        size={'md'}
        title="Deletar"
        children={
          <>
            <Flex
              mb={5}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <FaExclamationCircle size={50} />
              <TextComponent fontWeight={'bold'} fontSize={'18px'}>
                Atenção
              </TextComponent>
              <TextComponent fontWeight={'light'} fontSize={'14px'}>
                Tem certeza que deseja deletar essa pessoa ?
              </TextComponent>
            </Flex>
          </>
        }
        footer={
          <Flex mb={6} mr={6} justifyContent={'end'}>
            <ButtonComponent
              variant={'outline'}
              margin={'0px 10px 0px 0px'}
              onSubmit={() => {
                setShowModalDelete(false);
              }}
            >
              Cancelar
            </ButtonComponent>
            <ButtonComponent
              // disabled={hasValidationError || loading}
              // isLoading={loading}
              onSubmit={() => {}}
            >
              Deletar
            </ButtonComponent>
          </Flex>
        }
      />
    </>
  );
}
