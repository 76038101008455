import HeaderTextComponent from '../components/UI/atoms/HeaderTextComponent/HeaderTextComponent';
import { Flex, Stack, useToast } from '@chakra-ui/react';
import InputComponent from '../components/UI/atoms/InputComponent/InputComponent';
import ButtonComponent from '../components/UI/atoms/ButtonComponent/ButtonComponent';
import DropZoneListComponent, {
  FileInfo,
} from '../components/UI/molecules/DropZoneListComponent/DropZoneListComponent';
import React from 'react';
import { showToast } from '../utils/showToast';
import {
  MessageStatus,
  postPersonMessagesMany,
  usePersonMessagesAll,
} from '../services/MessagesService';
import { PersonMessagesTableComponent } from './PersonMessagePage';
import ModalStructureComponent from '../components/UI/molecules/ModalStructureComponent/ModalStructureComponent';
import { usePagination } from '../components/UI/molecules/PaginationComponent/PaginationComponent';
import { PersonResp } from '../services/PersonService';
import { SelectPersonModalComponent } from '../components/UI/organisms/SelectPersonModalComponent/SelectPersonModalComponent';
import SearchSelectComponent from '../components/UI/molecules/SearchSelectComponent/SearchSelectComponent';
import { useDebounce } from '../utils/functions/debounce';

export default function MessagingPage() {
  const toast = useToast();
  const [searchInput, search, setSearch] = useDebounce('');
  const [searchField, setSearchField] = React.useState('name');
  const [loadingMessageResend, setLoadingMessageResend] = React.useState(false);
  const [indexMessage, setIndexMessage] = React.useState(0);
  const [showModalLocator, setShowModalLocator] = React.useState(false);
  const [showModalMessage, setShowModalMessage] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState(false);
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [status, setMessageStatus] = React.useState('');
  const personMessagesList = usePersonMessagesAll(
    selectedPage,
    pageSize,
    search,
    searchField,
    status
  );

  const [message, setMessage] = React.useState<{
    subject: string;
    message: string;
    receiver: PersonResp[];
    attachmentIds: string[];
  }>({ subject: '', message: '', receiver: [], attachmentIds: [] });
  const [fileInfos, setFileInfos] = React.useState<FileInfo[]>([]);
  const fileIds = fileInfos.map((fileInfo) => fileInfo.id);

  return (
    <>
      <HeaderTextComponent goBack>{'Mensageria'}</HeaderTextComponent>
      <Flex mb={2} justifyContent={'flex-end'}>
        <ButtonComponent
          margin={'0 10px 0 0'}
          onSubmit={() => setShowModalMessage(true)}
        >
          Nova Mensagem
        </ButtonComponent>
        <SearchSelectComponent
          onChangeText={(input) => setSearch(input.target.value)}
          inputValue={searchInput}
          onClean={() => setSearch('')}
          onChange={(item) => {
            setSearchField(item.target.value);
          }}
          options={[
            {
              id: 'Status',
              name: 'Status',
              type: 'select',
              options: [
                { id: '', name: 'Todos' },
                { id: MessageStatus.Delivered.toString(), name: 'Entregue' },
                {
                  id: MessageStatus.DeliveredWithoutEmailNotification.toString(),
                  name: 'Entregue sem notificação por e-mail',
                },
                {
                  id: MessageStatus.DeliveredWithoutNotificationApp.toString(),
                  name: 'Entregue sem notificação pelo aplicativo',
                },
                {
                  id: MessageStatus.EmailAndAppNotificationNotDelivered.toString(),
                  name: 'Notificação por e-mail e aplicativo não entregue',
                },
                {
                  id: MessageStatus.Read.toString(),
                  name: 'Lida',
                },
              ],
              onChageSelect: (e) => setMessageStatus(e.target.value),
            },
          ]}
        />
      </Flex>
      <Flex>
        <PersonMessagesTableComponent
          pagination={{ pageSize, selectedPage, setSelectedPage }}
          personMessagesList={personMessagesList}
          indexMessage={indexMessage}
          setIndexMessage={setIndexMessage}
          loadingMessageResend={loadingMessageResend}
          setLoadingMessageResend={setLoadingMessageResend}
        />
      </Flex>
      <ModalStructureComponent
        isOpen={showModalMessage}
        onClose={() => {
          setShowModalMessage(false);
          setMessage({
            subject: '',
            message: '',
            receiver: [],
            attachmentIds: [],
          });
        }}
        size={'6xl'}
        title="Nova Mensagem"
        children={
          <>
            <Flex flexDir={'column'}>
              <Stack spacing={5} width={'100%'}>
                <HeaderTextComponent subTitle>Mensageria</HeaderTextComponent>

                <Flex alignItems={'flex-end'}>
                  <InputComponent
                    label="Destinatários"
                    placeholder="Escolha os destinatários"
                    value={message.receiver.map((item) => item.name).toString()}
                  />
                  <ButtonComponent
                    width={'300px'}
                    onSubmit={() => setShowModalLocator(true)}
                  >
                    Selecione os Destinatários
                  </ButtonComponent>
                </Flex>
                <InputComponent
                  label="Assunto"
                  placeholder="Digite o assunto"
                  value={message.subject}
                  onChange={(input) =>
                    setMessage({ ...message, subject: input.target.value })
                  }
                />
                <InputComponent
                  type="textArea"
                  label="Mensagem"
                  placeholder="Digite a mensagem"
                  value={message.message}
                  onChange={(input) =>
                    setMessage({ ...message, message: input.target.value })
                  }
                />
                <DropZoneListComponent
                  fileInfos={fileInfos}
                  setFileInfos={setFileInfos}
                />
              </Stack>
            </Flex>
            <SelectPersonModalComponent
              onAcceptModal={(result) => {
                setMessage({ ...message, receiver: result });
              }}
              showModal={showModalLocator}
              setShowModal={() => setShowModalLocator(false)}
            />
          </>
        }
        footer={
          <>
            <Flex p={8} justifyContent={'end'}>
              <ButtonComponent
                margin={'0 20px 0 20px'}
                colorScheme="blue"
                variant="solid"
                isLoading={loadingMessage}
                disabled={loadingMessage}
                onSubmit={() => {
                  setLoadingMessage(true);
                  postPersonMessagesMany({
                    description: message.message,
                    subject: message.subject,
                    personIds: message.receiver.map((item) => item.personId),
                    attachmentIds: fileIds,
                  })
                    .then((res) => {
                      showToast({
                        toast,
                        status: 'success',
                        title: 'Sucesso',
                        description:
                          'A mensagen foi enviada com sucesso para as pessoas selecionada',
                      });
                      setMessage({
                        subject: '',
                        message: '',
                        receiver: [],
                        attachmentIds: [],
                      });
                      setShowModalMessage(false);
                    })
                    .finally(() => {
                      setLoadingMessage(false);
                      setFileInfos([]);
                    });
                }}
              >
                Enviar
              </ButtonComponent>
            </Flex>
          </>
        }
      />
    </>
  );
}
