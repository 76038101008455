import { Flex, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClauseResp } from '../../../services/ClauseService';
import { Contract } from '../../../services/ContractService';
import { showToast } from '../../../utils/showToast';
import OnboardingStructureComponent from '../../UI/molecules/OnboardingStructureComponent/OnboardingStructureComponent';
import ContractStepperFormComponent from '../../UI/organisms/ContractStepperFormComponent/ContractStepperFormComponent';
import ContractStepperClauseComponent from '../../UI/organisms/ContractStepperClauseComponent/ContractStepperClauseComponent';
import { formatDateToForm } from '../../../utils/functions/formatDate';
import { postBatchChanges } from '../../../services/BatchChangesService';

export default function BatchChangesStepperTemplateComponent() {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const contractIds = params.id?.split(',');
  const defaultForm = {
    activationDate: formatDateToForm(null),
    expireDate: formatDateToForm(null),
    baseReadjustmentDate: formatDateToForm(null),
    startDate: formatDateToForm(null),
    dueDate: formatDateToForm(null),
    installationDate: formatDateToForm(null),
    firstPaymentDate: formatDateToForm(null),
    contractStatus: null,
    frequency: null,
    advancePayment: null,
    area: null,
    iptuRegistration: null,
    iptuPayment: null,
    iptuTextValue: null,
    // iptuValue: 0,
    // value: 0,
    textValue: null,
    automaticRenovation: null,
    automaticReadjustment: null,
    expirationAlert: null,
    origin: null,
    data: null,
    comments: null,
    regionalId: null,
    clusterId: null,
    towerTypeId: null,
    technologyId: null,
    equipmentId: null,
    engineeringTypeId: null,
    intermediaryId: null,
    manualIdentifier: null,
    readjustmentIndexOption: null,
    clauseIds: null,
    considerationIds: null,
  };
  const steps = ['Outros Dados', 'Cláusulas'];
  const title = 'Alterar Contratos em Lote';
  const toast = useToast();
  const subtitle = 'Siga o nosso passo a passo para alterar os contratos';
  const [step, setStep] = React.useState(0);
  const [selectedClauseGeneral, setSelectedClauseGeneral] = React.useState<
    (ClauseResp | undefined)[]
  >([]);
  const [selectedClauseRenovation, setSelectedClauseRenovation] =
    React.useState<(ClauseResp | undefined)[]>([]);
  const [selectedClauseClosing, setSelectedClauseClosing] = React.useState<
    (ClauseResp | undefined)[]
  >([]);

  const [form, setForm] = React.useState<Contract>({ ...defaultForm });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <OnboardingStructureComponent
        titles={[title, title, title, title]}
        subtitles={[subtitle, subtitle, subtitle, subtitle]}
        steps={steps}
        currentStep={step}
        step={step}
        setStep={setStep}
        nextStepDisabled={false}
        finishButtonLabel="Concluir"
        isLoading={isLoading}
        onSubmit={() => {
          setIsLoading(true);
          const saveForm = {
            changes: {
              ...form,
              // value: Number(clearMoneyMask(String(form.value))),
              // iptuValue: Number(clearMoneyMask(String(form.iptuValue))),
              clauseIds: [
                ...selectedClauseGeneral.map((item) => item?.id || ''),
                ...selectedClauseRenovation.map((item) => item?.id || ''),
                ...selectedClauseClosing.map((item) => item?.id || ''),
              ],
            },
            contractIds: contractIds || [],
            description: '',
          };
          postBatchChanges(saveForm)
            .then((res) => {
              showToast({
                toast,
                status: 'success',
                title: 'Sucesso',
                description: 'Mudanças salvas com sucesso',
              });
              navigate('/batch-changes-list');
            })
            .finally(() => setIsLoading(false));
        }}
      >
        <Flex mt={6} justifyContent={'start'}>
          <Steps selected={step}>
            <ContractStepperFormComponent
              type="bastChange"
              form={form}
              setForm={setForm}
            />
            <ContractStepperClauseComponent
              selectedClauseGeneral={selectedClauseGeneral}
              setSelectedClauseGeneral={setSelectedClauseGeneral}
              selectedClauseRenovation={selectedClauseRenovation}
              setSelectedClauseRenovation={setSelectedClauseRenovation}
              selectedClauseClosing={selectedClauseClosing}
              setSelectedClauseClosing={setSelectedClauseClosing}
            />
          </Steps>
        </Flex>
      </OnboardingStructureComponent>
    </>
  );
}
const Steps = (props: {
  children: React.ReactElement[];
  selected: number;
}): React.ReactElement => {
  return props.children[props.selected];
};
