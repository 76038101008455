import { ENABLE_MIDDLEWARE } from './../environment';
import { useQuery } from '@tanstack/react-query';
import Api, { getResp, Middleware } from './Api';
import { LocatorResp } from './LocatorService';

export type EntitiesSelect = {
  id: string;
  name: string;
  entityImage: string;
};
export type EntitiesByIdResp = {
  id?: string;
  name: string;
  document: string;
  entityImage?: string;
  contract?: {
    id: string;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
    connection?: {
      ip: string;
      databaseName: string;
      username: string;
      password: string;
    };
  };
  franchiseId?: string;
  modulePermissions?: {
    permissionName: string;
    isAllowed: boolean;
  }[];
  responsible?: {
    id: string;
    username: string;
    secondaryPhoneNumber: string;
    phoneNumber: string;
    document: string;
    email: string;
    permissions: [
      {
        permissionName: string;
        permissionEntity: string;
        permissionFranchise: string;
        isGlobal: boolean;
      }
    ];
    entities?: string[];
    franchises?: string[];
    fullname: string;
    address: {
      street: string;
      district: string;
      complement: string;
      zipcode: string;
      number: string;
      type: number;
      cityId: string;
      cityName: string;
      stateId: string;
      stateName: string;
    };
    defaultPermissions?: [
      {
        permissionType: number;
        userId: string;
        franchises: string[];
        entities: string[];
        isGlobal: true;
      }
    ];
    createdAt?: Date | string;
  };
  address?: {
    id?: string;
    street: string;
    district: string;
    complement: string;
    zipcode: string;
    number: string;
    type: number;
    typeDescription?: string;
    cityId: string;
    stateId: string;
    countryId?: string;
    cityName: string;
    stateName: string;
    countryName?: string;
  };
};
export function useEntitiesSelectUser() {
  return useQuery(
    [`entitySelectList`],
    async () => {
      const resp = await Middleware.get(`/entities/Select/CurrentUser`);
      return getResp<EntitiesSelect[]>(resp);
    },
    {
      enabled: ENABLE_MIDDLEWARE,
    }
  );
}
export function useEntitiesById(tenantId?: string) {
  return useQuery(
    [`entitysById`],
    async () => {
      const resp = await Middleware.get(`/entities/${tenantId}`);
      return getResp<EntitiesByIdResp>(resp);
    },
    {
      enabled: ENABLE_MIDDLEWARE,
    }
  );
}

type MiddlewareEntitiesResp = {
  name: string;
  document: string;
  entityImage: string;
  contract?: {
    id: string;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
  };
  attorneyId?: string;
  franchiseId?: string;
  modulePermissions?: string[];
  address?: {
    street: string;
    district: string;
    complement: string;
    zipcode: string;
    number: string;
    type: number;
    cityId: string;
    cityName: string;
    stateId: string;
    stateName: string;
  };
  contacts: {
    email: string;
    phone: string;
    whatsapp: string;
    link: string;
  };
};
export function putMiddlewareEntities(data: MiddlewareEntitiesResp) {
  return Api.put(`/MiddlewareEntities`, data);
}

export function useEntityAttorney() {
  return useQuery(
    [`entityAttorney`],
    async () => {
      const resp = await Api.get(`/entity/attorney`);
      return getResp<LocatorResp>(resp);
    },
    {
      enabled: ENABLE_MIDDLEWARE,
    }
  );
}
