import { useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';
import { SortDirection } from './PhysicalPersonService';
import { StatusContract, useContractFilteredSearch } from './ContractService';
export enum StatusContractRoutines {
  pending = 0,
  approved = 1,
  disapproved = 2,
}
export type AffectedRoutineContract = {
  contractId: string;
  contractStatus: StatusContract;
  index: number;
  indexDescription: string;
  numberOfRenewalsRemaining: number;
  serialNumber: string;
  value: number;
  expireDate: string;
  baseReadjustmentDate: string;
  lastUpdatedAt: string;
};

export type ContractRoutinesResp = {
  id: string;
  createdAt: string;
  createdById: string;
  createdByName: string;
  newAutomaticRenovationValue: boolean;
  renewalTime: string;
  numberOfRenewals: number;
  type: number;
  typeDescription: string;
  readjustmentIndexOption: number;
  readjustmentIndexOptionDescription: string;
  wasReversed: boolean;
  wasApproved: boolean;
  reverseMessage: string;
  reversedById: string;
  reversedByName: string;
  affectedContracts: AffectedRoutineContract[];
  status: StatusContractRoutines | undefined;
  statusDescription: string;
};

export type ContractRoutinesContractsResp = {
  id: string;
  serialNumber: string;
  value: number;
  automaticRenovation: boolean;
  expirationAlert: boolean;
  activationDate: string;
  expireDate: string;
  createdAt: string;
  contractStatus: number;
  contractStatusDescription: string;
  readjustmentIndexOption: number;
  readjustmentIndexOptionDescription: string;
};

export function useContractRoutines(
  page: number,
  pageSize: number | null,
  status: StatusContractRoutines | undefined
) {
  return useQuery([`ContractRoutinesList`, page, status], async () => {
    const resp = await Api.get(`/ContractRoutines`, {
      params: {
        Offset: page,
        Limit: pageSize,
        Status: status,
      },
    });
    return getRespContent<ContractRoutinesResp[]>(resp);
  });
}

export function useContractRoutinesContracts(
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'QuickSearch',
  sort: string = 'date',
  direction: SortDirection = 'asc'
) {
  return useQuery(
    [
      `ContractRoutinesContractsList`,
      page,
      search,
      searchField,
      sort,
      direction,
    ],
    async () => {
      const resp = await Api.get(`/ContractRoutines/contracts`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
        },
      });
      return getRespContent<ContractRoutinesContractsResp[]>(resp);
    }
  );
}

export function useContractRoutinesContractsFiltered(
  page: number,
  pageSize: number | null,
  startingReference: string,
  endingReference: string,
  sort: string = 'createdAt',
  direction: SortDirection = 'desc',
  search?: useContractFilteredSearch
) {
  return useQuery(
    [
      `ContractRoutinesContractsListFilter`,
      page,
      sort,
      direction,
      startingReference,
      endingReference,
      search?.serialNumber,
      search?.locator,
      search?.locationId,
      search?.activationDate,
      search?.expireDate,
      search?.readjustmentIndexId,
      search?.towerTypeId,
      search?.equipmentId,
      search?.frequency,
      search?.technologyId,
      search?.engineeringTypeId,
      search?.automaticRenovation,
      search?.expirationAlert,
      search?.value,
      search?.origin,
      search?.data,
      search?.comments,
      search?.status,
      search?.active,
      search?.myContracts,
      search?.clauseId,
    ],
    async () => {
      const resp = await Api.get(`/ContractRoutines/contracts`, {
        params: {
          Offset: page,
          Limit: pageSize,
          SortColumn: sort,
          SortDirection: direction,
          startingReference,
          endingReference,
          serialNumber: search?.serialNumber,
          locator: search?.locator,
          locationId: search?.locationId,
          activationDate: search?.activationDate,
          expireDate: search?.expireDate,
          readjustmentIndexId: search?.readjustmentIndexId,
          towerTypeId: search?.towerTypeId,
          equipmentId: search?.equipmentId,
          frequency: search?.frequency,
          technologyId: search?.technologyId,
          engineeringTypeId: search?.engineeringTypeId,
          automaticRenovation: search?.automaticRenovation,
          expirationAlert: search?.expirationAlert,
          value: search?.value,
          origin: search?.origin,
          data: search?.data,
          comments: search?.comments,
          contractStatus: search?.status,
          active: search?.active,
          myContracts: search?.myContracts,
          clauseId: search?.clauseId,
        },
      });
      return getRespContent<ContractRoutinesContractsResp[]>(resp);
    }
  );
}

export function useContractRoutinesContractsFilteredAll(
  startingReference: string,
  endingReference: string,
  search?: useContractFilteredSearch
) {
  return useQuery(
    [
      `ContractRoutinesContractsListFilterAll`,
      startingReference,
      endingReference,
      search?.serialNumber,
      search?.locator,
      search?.locationId,
      search?.activationDate,
      search?.expireDate,
      search?.readjustmentIndexId,
      search?.towerTypeId,
      search?.equipmentId,
      search?.frequency,
      search?.technologyId,
      search?.engineeringTypeId,
      search?.automaticRenovation,
      search?.expirationAlert,
      search?.value,
      search?.origin,
      search?.data,
      search?.comments,
      search?.status,
      search?.active,
      search?.myContracts,
      search?.clauseId,
    ],
    async () => {
      const resp = await Api.get(
        `/ContractRoutines/select-all-filter-contracts`,
        {
          params: {
            startingReference,
            endingReference,
            serialNumber: search?.serialNumber,
            locator: search?.locator,
            locationId: search?.locationId,
            activationDate: search?.activationDate,
            expireDate: search?.expireDate,
            readjustmentIndexId: search?.readjustmentIndexId,
            towerTypeId: search?.towerTypeId,
            equipmentId: search?.equipmentId,
            frequency: search?.frequency,
            technologyId: search?.technologyId,
            engineeringTypeId: search?.engineeringTypeId,
            automaticRenovation: search?.automaticRenovation,
            expirationAlert: search?.expirationAlert,
            value: search?.value,
            origin: search?.origin,
            data: search?.data,
            comments: search?.comments,
            contractStatus: search?.status,
            active: search?.active,
            myContracts: search?.myContracts,
            clauseId: search?.clauseId,
          },
        }
      );
      return getResp<string[]>(resp);
    }
  );
}

export function putContractRoutinesApproveDisapprove(
  id: string,
  approve: boolean,
  message: string
) {
  if (approve) return putContractRoutinesApprove(id, message);
  return putContractRoutinesDisapprove(id, message);
}

export function putContractRoutinesApprove(id: string, message: string) {
  return Api.put(`/ContractRoutines/approve/${id}`, {
    message,
  });
}

export function putContractRoutinesDisapprove(id: string, message: string) {
  return Api.put(`/ContractRoutines/disapprove/${id}`, {
    message,
  });
}

export function postContractRoutines(contractIds: string[], type: string) {
  if (type === 'renovation') return postContractRoutinesRenovation(contractIds);
  if (type === 'readjustment')
    return postContractRoutinesReadjustment(contractIds);
  return postContractRoutinesTermination(contractIds);
}

export function postContractRoutinesRenovation(contractIds: string[]) {
  return Api.post(`/ContractRoutines/create-automatic-renovation`, {
    contractIds,
  });
}

export function postContractRoutinesTermination(contractIds: string[]) {
  return Api.post(`/ContractRoutines/create-automatic-termination`, {
    contractIds,
  });
}

export function postContractRoutinesReadjustment(contractIds: string[]) {
  return Api.post(`/ContractRoutines/create-automatic-readjustment`, {
    contractIds,
  });
}
