import { Flex, Stack, useToast } from '@chakra-ui/react';
import React from 'react';
import {
  contractAdjustment,
  ContractResp,
  getReadjustmentIndexContract,
  ReadjustmentType,
} from '../../../../services/ContractService';
import { showToast } from '../../../../utils/showToast';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import {
  cleanNumberMask,
  clearMoneyMask,
  convertToMonetaryValue,
  moneyMask,
} from '../../../../utils/functions/masks';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import { useReadjustmentIndexFind } from '../../../../services/ReadjustmentIndexService';

import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';
import RadioGroupComponent from '../../atoms/RadioGroupComponent/RadioGroupComponent';
import {
  NegotiationStatus,
  putCampaignContractNegociationStatus,
} from '../../../../services/CampaignsService';

export function ContractAdjustmentModalComponent(props: {
  showModal: boolean;
  campaingId?: string;
  selectedContract?: ContractResp;
  setShowModal: () => void;
  reloadData?: () => void;
}) {
  const toast = useToast();
  const defaultForm = React.useMemo(
    () => ({
      id: '',
      oldValue: '0',
      newValue: '0',
      description: '',
      competence: '',
      type: 0,
    }),
    []
  );
  const [form, setForm] = React.useState(defaultForm);
  const [loading, setLoading] = React.useState(false);
  const [radioType, setRadioType] = React.useState<ReadjustmentType>(
    ReadjustmentType.VALUE
  );
  const readjustmentIndex = useReadjustmentIndexFind(
    form.type,
    form.competence
  );

  function newValue(oldValue: string, percentage: number) {
    const oldNumber = Number(cleanNumberMask(oldValue));
    const percentageValue = (oldNumber * percentage) / 100;
    return moneyMask((oldNumber + percentageValue).toString().split('.')[0]);
  }

  React.useEffect(() => {
    if (readjustmentIndex.data)
      setForm((item) => ({
        ...item,
        newValue: newValue(
          item.oldValue,
          readjustmentIndex.data?.percentage ?? 0
        ),
      }));
  }, [readjustmentIndex.data]);
  React.useEffect(() => {
    if (props.selectedContract)
      setForm({
        id: props.selectedContract.id,
        oldValue: convertToMonetaryValue(
          Number(cleanNumberMask(String(props.selectedContract?.value)))
        ),
        newValue: moneyMask('0'),
        description: '',

        competence: '',
        type: 0,
      });
    else setForm(defaultForm);
  }, [props.selectedContract, defaultForm]);
  return (
    <ModalStructureComponent
      footer={
        <Flex p={4} justifyContent={'end'}>
          <ButtonComponent
            variant={'outline'}
            margin={'0 10px 0 0'}
            onSubmit={() => {
              setForm({
                id: props.selectedContract?.id || '',
                oldValue: convertToMonetaryValue(
                  Number(cleanNumberMask(String(props.selectedContract?.value)))
                ),
                newValue: moneyMask('0'),
                description: '',

                competence: '',
                type: 0,
              });
              props.setShowModal();
            }}
          >
            Descartar
          </ButtonComponent>

          <ButtonComponent
            isLoading={loading}
            disabled={loading}
            onSubmit={() => {
              setLoading(true);
              contractAdjustment({
                ...form,
                competence:
                  form.competence ||
                  props.selectedContract?.baseReadjustmentDate,
                newValue: Number(clearMoneyMask(form.newValue)),
                type: radioType,
                readjustmentIndex: form.type,
              })
                .then((res) => {
                  props.setShowModal();
                  props.reloadData?.();
                  showToast({
                    toast,
                    status: 'success',
                    title: 'Sucesso',
                    description: 'Pedido de alteração realizado com sucesso.',
                  });

                  if (props.campaingId) {
                    return putCampaignContractNegociationStatus(
                      props.campaingId ?? '',
                      props.selectedContract?.id ?? '',
                      NegotiationStatus.InProgress
                    ).then(() => {
                      showToast({
                        toast,
                        status: 'success',
                        title: 'Sucesso',
                        description:
                          'O status da negociação agora está em andamento',
                      });
                      props.reloadData?.();
                    });
                  }
                })
                .finally(() => setLoading(false));
            }}
          >
            Salvar
          </ButtonComponent>
        </Flex>
      }
      isOpen={props.showModal}
      onClose={() => {
        props.setShowModal();
        setForm({
          id: props.selectedContract?.id || '',
          oldValue: convertToMonetaryValue(
            Number(cleanNumberMask(String(props.selectedContract?.value)))
          ),
          newValue: moneyMask('0'),
          description: '',
          competence: '',
          type: 0,
        });
      }}
      title={'Reajuste'}
      maxW="1200px"
      size="lg"
    >
      <Flex flexDir={'column'}>
        <HeaderTextComponent fontSize={20} subTitle>
          Selecione o tipo de reajuste
        </HeaderTextComponent>
        <RadioGroupComponent
          onChange={(text) => setRadioType(Number(text))}
          value={radioType.toString()}
          margin={'26px 0 26px 0'}
          options={[
            {
              id: String(ReadjustmentType.VALUE),
              name: 'Valor',
            },
            {
              id: String(ReadjustmentType.INDEX),
              name: 'Índice',
            },
          ]}
          direction={'row'}
        />
      </Flex>
      {radioType === ReadjustmentType.INDEX && (
        <HeaderTextComponent mb={4} fontSize={14}>
          Por favor, escolha a data base e o índice de reajuste desejados para
          que o novo valor possa ser calculado automaticamente
        </HeaderTextComponent>
      )}

      <Stack mb={5} direction={'row'} spacing={'4%'}>
        <InputComponent
          disabled={true}
          label="Valor base"
          placeholder="R$ 55,00"
          value={moneyMask(form.oldValue)}
          onChange={(input) =>
            setForm({ ...form, oldValue: input.target.value })
          }
        />
        <InputComponent
          label="Data base reajuste"
          type="date"
          value={
            form.competence ||
            props.selectedContract?.baseReadjustmentDate
              .toLocaleString()
              .substring(0, 10)
          }
          onChange={(input) =>
            setForm({
              ...form,
              competence: input.target.value,
            })
          }
        />
        {radioType === ReadjustmentType.INDEX && (
          <InputSelectComponent
            label="Índice de Reajuste"
            placeholder="Selecione o índice"
            options={getReadjustmentIndexContract()}
            defaultValue={form.type.toString()}
            onChange={(input) =>
              setForm({
                ...form,
                type: Number(input.target.value),
              })
            }
          />
        )}

        <InputComponent
          label={`Novo valor ${
            radioType === ReadjustmentType.INDEX &&
            form.type &&
            readjustmentIndex.data
              ? `(${readjustmentIndex.data.percentage}%)`
              : ``
          }`}
          placeholder="R$ 55,00"
          disabled={radioType === ReadjustmentType.INDEX}
          value={form.newValue}
          onChange={(input) => {
            setForm({ ...form, newValue: moneyMask(input.target.value) });
          }}
        />
      </Stack>
      <Stack mb={5} direction={'row'} spacing={'4%'}>
        <InputComponent
          label="Descrição"
          type="textArea"
          value={form.description}
          onChange={(input) => {
            setForm({ ...form, description: input.target.value });
          }}
        />
      </Stack>
    </ModalStructureComponent>
  );
}
