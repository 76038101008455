import { MultiSelect } from 'chakra-multiselect';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from '@chakra-ui/react';

export type InputSelectProps = {
  label: string;
  disabled?: boolean;
  defaultValue?: string;
  value?: string;
  placeholder?: string;
  m?: string | number;
  w?: string | number;
  p?: string | number;
  onChange?: (input: any) => void;
  options?: { id: string; name: string }[];
  error?: string;
};

export default function InputSelectComponent(props: InputSelectProps) {
  return (
    <FormControl
      w={props.w}
      p={props.p}
      margin={props.m}
      isInvalid={!!props.error}
    >
      <FormLabel mb={0}>{props.label}</FormLabel>
      <Select
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        disabled={props.disabled}
      >
        {props.options?.map((option, i) => (
          <option
            selected={option.id === props.defaultValue}
            key={i}
            value={option.id}
          >
            {option.name}
          </option>
        ))}
      </Select>
      <FormErrorMessage mt={'0'}>{props.error}</FormErrorMessage>
    </FormControl>
  );
}

function getValueFromOptions(options: { id: string; name: string }) {
  return options.name + '||' + options.id;
}

function getIdFromValue(value: string) {
  const splitted = value.split('||');
  return splitted[splitted.length - 1];
}

function mapToOptions(options: { id: string; name: string }) {
  return {
    value: getValueFromOptions(options),
    label: options.name,
  };
}

export type SelectAutoCompleteProps = Omit<InputSelectProps, 'onChange'> &
  (
    | { single: true; values?: string; onChange?: (value: string) => void }
    | {
        single?: false;
        values?: string[];
        onChange?: (value: string[]) => void;
      }
  );

export function SelectAutoCompleteComponent(props: SelectAutoCompleteProps) {
  function handleChange(
    data: (string | number) | ({ label: string; value: string } | string)[]
  ) {
    if (!props.single && Array.isArray(data)) {
      const values = data.map((item) =>
        typeof item === 'string'
          ? getIdFromValue(item)
          : getIdFromValue(item.value.toString())
      );
      props.onChange?.(values);
    }

    if (typeof data === 'string' && props.single) {
      const id = getIdFromValue(data);

      props.onChange?.(id);
    }
  }

  const valueAsArray = Array.isArray(props.values)
    ? props.values
    : [props.values];

  const mappedValues = (
    valueAsArray
      ?.map((value) => props.options?.find((option) => option.id === value))
      .filter(Boolean) as { id: string; name: string }[]
  ).map(mapToOptions);
  return (
    <FormControl
      w={props.w}
      p={props.p}
      margin={props.m}
      isInvalid={!!props.error}
    >
      <FormLabel mb={0}>{props.label}</FormLabel>
      <MultiSelect
        single={props.single}
        value={props.single ? mappedValues[0]?.value ?? '' : mappedValues}
        placeholder={props.placeholder}
        onChange={handleChange as any}
        disabled={props.disabled}
        options={props.options?.map(mapToOptions)}
      />
    </FormControl>
  );
}
