import { Checkbox, Flex, Stack, useToast } from '@chakra-ui/react';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import { DescriptionLabelComponent } from '../DescriptionlabelComponent/DescriptionLabelComponent';
import TableComponent from '../TableComponent/TableComponent';
import {
  StatusContract,
  useContract,
} from '../../../../services/ContractService';
import {
  PaginationComponent,
  usePagination,
} from '../PaginationComponent/PaginationComponent';
import React, { useEffect, useState } from 'react';
import {
  parseDate,
  parseDateAndHour,
} from '../../../../utils/functions/utility';
import { StatusColorByTypeContract } from '../../../templates/ContractTemplateComponent/ContractTemplateComponent';
import {
  CampaignType,
  invalidateCampaign,
  saveCampaign,
  useCampaignDetails,
} from '../../../../services/CampaignsService';
import { showToast } from '../../../../utils/showToast';
import { useQueryClient } from '@tanstack/react-query';
import { useDebounce } from '../../../../utils/functions/debounce';
import SearchSelectComponent from '../SearchSelectComponent/SearchSelectComponent';
import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';

export enum campaignsStatus {
  pending = 0,
  inProgress = 1,
  canceled = 2,
}

export function CreateCampaignsModalComponent(props: {
  campaignId?: string;
  showModal: boolean;
  setShowModal: () => void;
  reloadData: () => void;
  isLoading: boolean;
}) {
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const toast = useToast();
  const [selectedContracts, setSelectedContracts] = React.useState<string[]>(
    []
  );

  const campaign = useCampaignDetails(props.campaignId || '');
  const queryClient = useQueryClient();
  const [searchInput, search, setSearch] = useDebounce('');
  const [searchField, setSearchField] = React.useState('SerialNumber');
  const [contractStatus, setContractStatus] = React.useState('');
  const [campaignName, setCampaingName] = useState('');
  const [campaignType, setCampaingType] = useState<CampaignType>();
  const [campaingscheduleClosingDate, setCampaingscheduleClosingDate] =
    useState('');

  const contractList = useContract(
    selectedPage,
    pageSize,
    search,
    searchField,
    'createdAt',
    'desc',
    null,
    null,
    '',
    '',
    '',
    '',
    contractStatus
  );

  useEffect(() => {
    console.log(campaign.data);
    if (campaign.data) {
      console.log(campaign.data);
      const CampaignContracts = campaign.data?.contracts;
      const ContractIds = CampaignContracts?.map((e) => e.contractId);
      setCampaingType(campaign.data?.type);
      setCampaingName(campaign.data?.name);
      setCampaingscheduleClosingDate(campaign.data.scheduledClosingDate);
      setSelectedContracts([...(ContractIds ?? [])]);
    }
  }, [campaign.data]);

  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  function handleCheckBoxChange(id: string) {
    if (!selectedContracts.some((item) => item === id))
      setSelectedContracts([...selectedContracts, id]);
    else {
      setSelectedContracts(selectedContracts.filter((item) => item !== id));
    }
  }
  const [isLoading, setIsLoading] = useState(false);
  console.log(parseDate(campaingscheduleClosingDate));
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => {
        props.setShowModal();
        setSelectedContracts([]);
        invalidateCampaign(props.campaignId || '', queryClient);
      }}
      title={
        props.campaignId
          ? 'Editar contratos da campanha'
          : `Adicionar contratos a campanha`
      }
      maxW="1200px"
      size="xl"
    >
      <>
        <Stack
          marginBottom={8}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <InputComponent
            value={campaignName}
            label="Nome da campanha"
            placeholder="Digite um nome para a campanha"
            onChange={(e) => setCampaingName(e.target.value)}
          />
          <InputSelectComponent
            defaultValue={campaignType?.toString()}
            onChange={(e) => {
              setCampaingType(e.target.value);
            }}
            options={[
              {
                id: CampaignType.Readjustment.toString(),
                name: 'Reajuste',
              },
              {
                id: CampaignType.Renewal.toString(),
                name: 'Renovação',
              },
            ]}
            placeholder="Selecione o tipo da campanha"
            label="Tipo da campanha"
          />
          <InputComponent
            value={
              parseDate(campaingscheduleClosingDate) === '0001-01-01'
                ? ''
                : parseDate(campaingscheduleClosingDate)
            }
            label="Data de encerramento agendada"
            placeholder="Selecione a data"
            type="date"
            onChange={(e) => setCampaingscheduleClosingDate(e.target.value)}
          />
        </Stack>
        <Flex>
          <Stack
            marginBottom={3}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Flex>
              <Flex mr={2}>
                <HeaderTextComponent subTitle fontSize={18}>
                  Filtrar por:
                </HeaderTextComponent>
              </Flex>
              <SearchSelectComponent
                onChangeText={(input) => setSearch(input.target.value)}
                inputValue={searchInput}
                onClean={() => setSearch('')}
                onChange={(item) => {
                  setSearchField(item.target.value);
                }}
                options={[
                  {
                    id: 'ContractStatus',
                    name: 'Status',
                    type: 'select',
                    options: [
                      { id: '', name: 'Todos' },
                      {
                        id: StatusContract.PendenteDeAssinatura.toString(),
                        name: 'Pendente de assinatura',
                      },

                      {
                        id: StatusContract.Assinado.toString(),
                        name: 'Assinado',
                      },

                      {
                        id: StatusContract.EmCadastramento.toString(),
                        name: 'Em cadastramento',
                      },
                      {
                        id: StatusContract.Cancelado.toString(),
                        name: 'Cancelado',
                      },
                    ],
                    onChageSelect: (e) => setContractStatus(e.target.value),
                  },
                ]}
              />
            </Flex>
          </Stack>
        </Flex>

        <TableComponent
          emptyState={contractList.data?.data.length === 0}
          ItemsHeader={[
            {
              item: 'Selecionar',
            },
            { item: 'Número do contrato' },
            { item: 'Operadora' },
            { item: 'Data de vigência' },
            { item: 'status' },
          ]}
          isLoading={contractList.isLoading}
          centered={true}
          data={
            contractList.data?.data.map((e) => ({
              items: [
                <Checkbox
                  isChecked={selectedContracts.some((item) => item === e.id)}
                  onChange={() => handleCheckBoxChange(e.id)}
                  borderColor={'black'}
                ></Checkbox>,
                <DescriptionLabelComponent
                  label={e.serialNumber}
                  subLabel={'Contrato'}
                />,
                e.createdByName,
                parseDateAndHour(e.activationDate),
                StatusColorByTypeContract({ type: e.contractStatus }),
              ],
            })) || []
          }
        />
        <PaginationComponent
          onSelectedPageChanged={onSelectedPageChanged}
          selectedPage={selectedPage}
          arrayLength={contractList.data?.metadata.dataSize || 0}
          maxPageItens={pageSize}
        ></PaginationComponent>
        <Stack alignItems={'flex-end'}>
          <ButtonComponent
            isLoading={isLoading}
            disabled={isLoading}
            onSubmit={() => {
              setIsLoading(true);
              saveCampaign(
                campaignName,
                campaingscheduleClosingDate,
                Number(campaignType),
                selectedContracts,
                props.campaignId
              )
                .then((res) => {
                  showToast({
                    toast,
                    status: 'success',
                    title: 'Sucesso',
                    description: 'Salvo com sucesso',
                  });
                  props.reloadData();
                  setSelectedContracts([]);
                  props.setShowModal();
                })
                .finally(() => setIsLoading(false));
            }}
          >
            {props.campaignId ? 'Editar' : 'Adicionar'}
          </ButtonComponent>
        </Stack>
      </>
    </ModalStructureComponent>
  );
}
